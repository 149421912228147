import React, { Dispatch, SetStateAction, useContext, useMemo } from 'react';
import DashboardContext from '../../../../../../contexts/DashboardContext';
import { useTranslation } from 'react-i18next';
import tradingAccountStore from '../../../../../../store/tradingAccountStore';
import { useMarketItemsMap } from '../../../../../components/MarketItemFormatter/useMarketItemsMap';
import { createColumnHelper } from '@tanstack/react-table';
import { OrderTableItem } from '../../../../../../utils/functions/marketItems/marketItemGroupMapFormatter';
import cn from 'classnames';
import styles from '../../../../Markets/MarketsGridNew/MarketsTable.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Change from '../components/Change';
import CurrentPrice from '../components/CurrentPrice';
import LimitProfitLossButton from '../components/LimitProfitLossButton';
import CloseIcon from '../components/CloseIcon';
import SettingTrigger from '../../../../Markets/MarketsGridNew/components/SettingTrigger';
import EditIcon from '../components/EditIcon';
import CalcDistanceToMarket from '../components/CalcDistanceToMarket';
import { GridType } from '../../../../../../utils/functions/enums';

const useOrderGridColumn = (setShowSettingsModal: Dispatch<SetStateAction<boolean>>) => {
	const dashboardContext = useContext(DashboardContext);

	const { t } = useTranslation();

	const quantityType = dashboardContext.quantityType;

	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const marketsItemMap = useMarketItemsMap();

	const columnHelper = createColumnHelper<OrderTableItem>();

	const columns = useMemo(
		() => [
			columnHelper.accessor('instrument', {
				id: 'instrument',
				header: () => <span>{t('en:INSTRUMENT')}</span>,
				cell: ({ row, getValue }) => (
					<div className={cn(styles.symbolCell)}>
						{row.getCanExpand() ? (
							row.getIsExpanded() ? (
								<FontAwesomeIcon onClick={row.getToggleExpandedHandler()} icon={['fas', 'angle-down']} size="1x" />
							) : (
								<FontAwesomeIcon onClick={row.getToggleExpandedHandler()} icon={['fas', 'angle-right']} size="1x" />
							)
						) : (
							<div style={{ marginLeft: '9px' }}></div>
						)}
						<span className={cn(styles.symbolText)}>{getValue()?.replace('_SB', '')}</span>
					</div>
				),
				enableHiding: false,
				minSize: 120,
			}),
			columnHelper.accessor((row) => row.side, {
				id: 'side',
				header: () => <span>{t('en:SIDE')}</span>,
				cell: (info) => <span className={cn(styles.verticalCenterAlign)}>{info.getValue()}</span>,
				minSize: 100,
			}),
			columnHelper.accessor('type', {
				id: 'type',
				header: () => <span>{t('en:Type')}</span>,
				cell: (info) => <span className={cn(styles.verticalCenterAlign)}>{info.renderValue()}</span>,
				minSize: 72,
			}),
			columnHelper.accessor('quantityType', {
				id: 'quantityType',
				header: () => (
					<span>{t(isSpreadBettingAccount ? 'en:POUND_PER_POINT' : `en:QT_${quantityType.toUpperCase()}`)}</span>
				),
				cell: (info) => (
					<span className={cn(styles.alignRight)}>
						{quantityType === 'Lots' ? info.row.original.Lots : info.row.original.Amount}
					</span>
				),
				minSize: 72,
			}),
			columnHelper.accessor('open', {
				id: 'open',
				header: () => <span>{t('en:OPEN_AT')}</span>,
				cell: (info) => <span className={cn(styles.alignRight)}>{info.renderValue()}</span>,
				minSize: 72,
			}),
			columnHelper.accessor('createdOn', {
				id: 'createdOn',
				header: () => <span>{t('wtr:CREATED_ON')}</span>,
				cell: (info) => <span className={cn(styles.verticalCenterAlign)}>{info.renderValue()}</span>,
				minSize: 72,
			}),
			columnHelper.accessor('current', {
				id: 'current',
				header: () => <span>{t('en:CURRENT')}</span>,
				cell: (info) => <CurrentPrice data={info} />,
				minSize: 72,
			}),
			columnHelper.accessor('distanceToMarket', {
				id: 'distanceToMarket',
				header: () => <span>{t('wtr:DISTANCE_TO_MARKET')}</span>,
				cell: (info) => <CalcDistanceToMarket data={info} />,
				minSize: 72,
			}),
			columnHelper.accessor('TakeProfit', {
				id: 'TakeProfit',
				header: () => <span>{t('en:TAKE_PROFIT')}</span>,
				cell: (info) => <LimitProfitLossButton info={info} type={'takeProfit'} gridType={GridType.Order} />,
				minSize: 90,
			}),
			columnHelper.accessor('StopLoss', {
				id: 'StopLoss',
				header: () => <span>{t('en:STOP_LOSS')}</span>,
				cell: (info) => <LimitProfitLossButton info={info} type={'stopLoss'} gridType={GridType.Order} />,
				minSize: 90,
			}),
			columnHelper.accessor('expiryOn', {
				id: 'expiryOn',
				header: () => <span>{t('en:EXPIRY_ON')}</span>,
				cell: (info) => <span className={cn(styles.verticalCenterAlign)}>{info.renderValue()}</span>,
				minSize: 72,
			}),
			columnHelper.accessor('time', {
				id: 'time',
				header: () => <span>{t('en:OPEN_TIME')}</span>,
				cell: (info) => <span className={cn(styles.alignRight)}>{info.renderValue()}</span>,
				minSize: 72,
			}),
			columnHelper.accessor('posId', {
				id: 'posId',
				header: () => <span>{t('en:ORDER_ID')}</span>,
				cell: (info) => <span className={cn(styles.alignRight)}>{info.renderValue()}</span>,
				minSize: 72,
			}),
			columnHelper.accessor('settings', {
				id: 'settings',
				header: () => <SettingTrigger handleShowColumnChooser={setShowSettingsModal} />,
				cell: (info) => (
					<div className={cn(styles.horizontalCenterAlign)} style={{ width: '34px' }}>
						<EditIcon info={info} />
					</div>
				),
				enableSorting: false,
				enableResizing: false,
				enableHiding: false,
				// size: 30,
				minSize: 50,
				maxSize: 50,
			}),
			columnHelper.accessor('close', {
				id: 'close',
				header: (info) => <CloseIcon info={info} header={true} gridType={GridType.Order} />,
				cell: (info) => (
					<div className={cn(styles.horizontalCenterAlign)}>
						<CloseIcon info={info} gridType={GridType.Order} />
					</div>
				),
				enableSorting: false,
				enableResizing: false,
				enableHiding: false,
				// size: 40,
				minSize: 40,
				maxSize: 50,
			}),
		],
		[Object.keys(marketsItemMap).length, quantityType, isSpreadBettingAccount]
	);

	return columns;
};

export default useOrderGridColumn;
