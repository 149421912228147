import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Nullable, { Optional } from '../../../../../../utils/functions/Nullable';
import moment from 'moment';
import {
	calcPercentChange,
	calcPercentChangeAtParams,
	convertAmountToLot,
	getCurrentPrice,
	getInstrumentDetails,
	getMarketItemPipSize,
} from '../../../../../../utils/functions/calculations';
import { PriceQuote, TradingPosition, TradingPositionState } from '../../../../../../gateways/RfpGateway/rfp.types';
import { getGeneralFormatDate } from '../../../../../../utils/functions/subscriptionUtils';
import { formatNumberWithCommas } from '../../../Watchlist/Instrument/formattedQuoteNumber';
import AppContext from '../../../../../../contexts/AppContext';
import RfpGatewayContext from '../../../../../../contexts/RfpGatewayContext';
import useSelectedTradingAccount from '../../../../../../utils/hooks/useSelectedTradingAccount';
import DashboardContext from '../../../../../../contexts/DashboardContext';
import { useTranslation } from 'react-i18next';
import useShortTranslation from '../../../../../../utils/hooks/useShortTranslation';
import positionsStore from '../../../../../../store/PositionsStore/positionsStore';
import { DEFAULT_FEED_ID } from '../../../../../../utils/functions/WatchlistUtils';
import quoteStore from '../../../../../../store/QuoteStore/quoteStore';

const usePositionTable = () => {
	const { t } = useTranslation();
	const tt = useShortTranslation('en:');

	const appContext = useContext(AppContext);
	const rfpGatewayContext = useContext(RfpGatewayContext);
	const dashboardContext = useContext(DashboardContext);

	const languageSettings = appContext.languageSettings;
	const detailedInformation = dashboardContext.detailedInformation;
	const tradingPositions = dashboardContext.getTradingPositions();

	const selectedTradingAccount = useSelectedTradingAccount()?.id;
	const activeTradingAccount = useSelectedTradingAccount();
	const setQuote = quoteStore.use.setQuote();
	const panelSize = positionsStore.use.panelSize();

	const priceFeed = useRef<any>();

	const addCommas = (current: number | string, decPrec?: number | null, isPipsChange?: boolean | null) => {
		decPrec = decPrec ? decPrec : current.toString().split('.')[1]?.length ?? 0;

		if (isPipsChange) decPrec = 2;

		return formatNumberWithCommas(current, decPrec, languageSettings);
	};

	const unsubscribeInstruments = () => {
		if (rfpGatewayContext && priceFeed.current) {
			rfpGatewayContext.unsubscribePriceQuote(priceFeed.current);
			priceFeed.current = undefined;
		}
	};

	const openPositionsBySymbol = useMemo(
		() =>
			tradingPositions.reduce((map, position) => {
				if (position.state === TradingPositionState.open && position.aId === selectedTradingAccount) {
					if (!map.has(position.code)) {
						map.set(position.code, []);
					}
					map.get(position.code)!.push(position);
				}
				return map;
			}, new Map<string, TradingPosition[]>()),
		[tradingPositions, selectedTradingAccount]
	);

	const tableData = useMemo(() => {
		const items: any[] = [];

		const codes: string[] = [];

		openPositionsBySymbol.forEach((item, symbol) => {
			codes.push(symbol);
		});

		if (rfpGatewayContext) {
			if (priceFeed.current) {
				unsubscribeInstruments();
			}

			priceFeed.current = rfpGatewayContext.subscribePriceQuote(DEFAULT_FEED_ID, codes, (priceQuote) => {
				setQuote(priceQuote);
			});
		}

		openPositionsBySymbol.forEach((positions, symbol) => {
			let sellAmount = 0;
			let buyAmount = 0;
			let quantity = 0;
			let summaryPrice = 0;
			let openDate: any = [];
			let oldestDate = Infinity;
			let newestDate = 0;
			positions.forEach((position) => {
				let openPrice = 0;
				if (position.side === 'BUY') {
					buyAmount += position.qty || 0;
				} else {
					sellAmount += position.qty || 0;
				}
				openPrice = (position.oP || 0) * (position.qty || 0);
				summaryPrice += openPrice;
				quantity += position.qty || 0;
				openDate.push(position.oT);
				if (moment(position.oT).valueOf() > newestDate) newestDate = moment(position.oT).valueOf();
				if (moment(position.oT).valueOf() < oldestDate) oldestDate = moment(position.oT).valueOf();
			});

			if (positions.length > 1 && detailedInformation) {
				const marketItem = rfpGatewayContext?.getMarketItem(symbol);
				const decPrec = marketItem?.decPrec;
				const currentPrice = getCurrentPrice(positions[0].f, symbol, rfpGatewayContext);
				const calAmount = buyAmount > 0 ? buyAmount - sellAmount : sellAmount;
				const squareAmount = calAmount === 0;
				const side = squareAmount ? 'SQUARE' : buyAmount > sellAmount ? 'BUY' : 'SELL';
				const current = side === 'SELL' ? currentPrice?.a.toFixed(decPrec) : currentPrice?.b.toFixed(decPrec);
				const open = (summaryPrice / quantity).toFixed(decPrec);
				const amount = calAmount < 0 ? calAmount * -1 : calAmount;
				let selectedInstrumentDetails = getInstrumentDetails(
					detailedInformation,
					activeTradingAccount ? [activeTradingAccount] : [],
					marketItem?.code as string
				);
				const instrumentRndLot = (selectedInstrumentDetails && selectedInstrumentDetails.rndLot) || 0;
				const lots = convertAmountToLot(amount, instrumentRndLot);
				const codeOrTicker =
					marketItem && marketItem.exchangeTicker && marketItem.exchangeTicker !== ''
						? marketItem.exchangeTicker
						: symbol;

				let percentChanges = calcPercentChangeAtParams(parseFloat(open), parseFloat(current!), side === 'BUY');
				const sumValue = {
					amount,
					lots,
					side,
					open,
					change: percentChanges,
					current: parseFloat(current!).toFixed(marketItem!.decPrec),
				};

				const obj = {
					position: undefined,
					id: symbol,
					headID: `${symbol}-1`,
					state: TradingPositionState.open,
					instrument: `${codeOrTicker} (${positions.length})`,
					side: sumValue.side.toUpperCase() === 'SQUARE' ? t('wtr:SQUARE') : tt(`${sumValue.side.toUpperCase()}`),
					Amount: addCommas(sumValue.amount ? Math.round(sumValue.amount * 100) / 100 : 0),
					Lots: addCommas(sumValue.lots ? Math.round(sumValue.lots * 100) / 100 : 0),
					onDate: '',
					'On Date Translated': '',
					open: addCommas(+parseFloat(sumValue.open).toFixed(decPrec).toString(), decPrec),
					change: sumValue.change,
					current: +parseFloat(sumValue.current).toFixed(decPrec),
					TakeProfit: 'Head',
					StopLoss: 'Head',
					netPL: '',
					grossPL: '',
					pipsChange: ((+sumValue.current! - +sumValue.open!) / getMarketItemPipSize(marketItem)).toFixed(2),
					Exposure: '',
					'Margin Usage': '',
					oP: sumValue.open,
					type: '',
					qty: '',
					code: symbol,
					qCcy: '',
					prc: '',
					comm: '',
					swap: '',
					isGroupRow: true,
					Previous: 0,
					oldestDate: oldestDate,
					newestDate: newestDate,
					decPrec: decPrec,
					dividend: '',
					comment: '',
					subRows: [],
				};

				items.push(obj);
			}
		});

		if (tradingPositions.length) {
			tradingPositions.forEach((position, index) => {
				const marketItem = position.marketItem;
				const positionLength = Nullable.of(openPositionsBySymbol.get(position.code))
					.map((value) => value.length)
					.orElse(0);

				if (
					position.state === TradingPositionState.open &&
					position.aId === selectedTradingAccount &&
					detailedInformation
				) {
					const decPrec = marketItem?.decPrec ?? 2;
					const currentPrice = position.currentPrice ?? NaN;
					const netPL = (position.netProfit ?? NaN).toFixed(2);
					const grossPL = (position.grossProfit ?? NaN).toFixed(2);
					const currentChange = calcPercentChange(position);

					let selectedInstrumentDetails = getInstrumentDetails(
						detailedInformation,
						activeTradingAccount ? [activeTradingAccount] : [],
						marketItem?.code as string
					);
					const instrumentRndLot = (selectedInstrumentDetails && selectedInstrumentDetails.rndLot) || 0;
					let lots: string | number = convertAmountToLot(position.qty || 0, instrumentRndLot);
					const codeOrTicker =
						marketItem && marketItem.exchangeTicker && marketItem.exchangeTicker !== ''
							? marketItem.exchangeTicker
							: position.code;

					const onDateTranslated = appContext.isJapanAccount
						? getGeneralFormatDate(position.oT, false, true)
						: moment(position.oT).format('D/MMM/YYYY').toString().toUpperCase();

					const parentItem = items.find((item) => item.code === position.code);

					const obj = {
						position: position,
						id: positionLength !== 1 ? index : position.code,
						posId: position.posId,
						headID: position.posId,
						aId: position.aId,
						state: position.state,
						instrument: codeOrTicker,
						side: tt(`${position.side}`),
						Amount: addCommas(position.qty ? Math.round(position.qty * 100) / 100 : 0),
						Lots: addCommas(lots ? Math.round(lots * 100) / 100 : 0),
						onDate: onDateTranslated,
						open: addCommas((+position.oP!).toFixed(decPrec).toString(), decPrec),
						change: currentChange,
						current: currentPrice,
						TakeProfit: parseFloat((position.tp as number)?.toString()).toFixed(decPrec),
						StopLoss: parseFloat((position.sl as number)?.toString()).toFixed(decPrec),
						netPL: netPL,
						grossPL: grossPL,
						pipsChange: (position.pips ?? NaN).toFixed(2),
						oP: position.oP?.toFixed(decPrec),
						qty: position.qty,
						code: position.code,
						comm: position.comm,
						swap: position.swap,
						sl: position.sl,
						tp: position.tp,
						isGroupRow: false,
						Previous: 0,
						time: moment(position.oT).format('HH:mm:ss').toLocaleUpperCase(),
						decPrec: decPrec,
						dividend: position.dividend,
						comment: position.comment,
					};

					if (parentItem) {
						parentItem.subRows.push(obj);
					} else {
						items.push(obj);
					}
				}
			});
		}

		items
			.filter((record) => record.isGroupRow)
			.forEach((record) => {
				if (record.subRows.length) {
					record.NetPL = record.subRows
						.filter((value: any) => value.code === record.code && !value.isGroupRow)
						.reduce((totalPl: number, gridRecord: any) => {
							return +totalPl + (isNaN((gridRecord.netPL || Number.NaN) as number) ? 0 : +gridRecord?.netPL!);
						}, 0);
					record.GrossPL = record.subRows
						.filter((value: any) => value.code === record.code && !value.isGroupRow)
						.reduce((totalPl: number, gridRecord: any) => {
							return +totalPl + (isNaN((gridRecord.grossPL || Number.NaN) as number) ? 0 : +gridRecord?.grossPL!);
						}, 0);
				}
			});

		return items;
	}, [tradingPositions, selectedTradingAccount, panelSize]);

	useEffect(() => {
		return () => {
			unsubscribeInstruments();
		};
	}, []);

	return tableData;
};

export default usePositionTable;
