import { useContext, useEffect, useState } from 'react';

import AppContext from '../../../contexts/AppContext';

import DashboardContext from '../../../contexts/DashboardContext';

import accountStatusStore, { StatusStore } from '../../../store/accountStatusStore';
import authStore from '../../../store/authStore';
import tradingAccountStore from '../../../store/tradingAccountStore';
import { ApplicationStatus, QuantityType, TradingAccountType } from '../../../utils/functions/enums';

import useObservable from '../../../utils/hooks/useObservable';
import useSelectedTradingAccount from '../../../utils/hooks/useSelectedTradingAccount';

import { AccountMarketType } from '../../../gateways/RfpGateway/rfp.types';
import useSaveUserPreferences from '../../../utils/mutations/useSaveUserPreferences';
import { useFillContextsFromPreferences } from '../../../utils/hooks/preferences/useFillContextsFromPreferences';
import { LastLoggedAct } from '../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';
import RfpGatewayContext from '../../../contexts/RfpGatewayContext';
import useForceRerender from '../../../utils/hooks/useForceRerender';
import { RFP } from '../../../gateways/RfpGateway/rfpConstants';

import withLiveAccount_inDemoMode from './withLiveAccount_inDemoMode';
import withLiveAccount_inLiveMode from './withLiveAccount_inLiveMode';
import withoutLiveAccount_inDemoMode from './withoutLiveAccount_inDemoMode';
import withoutLiveAccount_inLiveMode from './withoutLiveAccount_inLiveMode';

const AccountStatusHandler = () => {
	const [tierWatchlist, setTierWatchlist] = useState<string>('');

	const hasLiveAccount = accountStatusStore((state: StatusStore) => state.hasLiveAccount);
	const fillContextsFromPreferences = useFillContextsFromPreferences();
	const isDemoMode = authStore.use.isDemoMode();
	const isLiveMode = authStore.use.isLiveMode();
	const isAuthenticated = authStore.use.isAuthenticated();
	const isJapanAccount = authStore.use.isJapanAccount();
	const isFundedTrader = tradingAccountStore.use.isFundedTrader();
	const tradingMode = authStore.use.tradingMode().toLowerCase();
	const setPermissions = accountStatusStore.use.setPermissions();
	const status = accountStatusStore.use.status();
	const setInfoMessage = accountStatusStore.use.setInfoMessage();
	const setHasLiveAccount = accountStatusStore.use.setHasLiveAccount();
	const setHasDemoAccount = accountStatusStore.use.setHasDemoAccount();
	const setShowModal = accountStatusStore.use.setShowModal();
	const setAccounts = tradingAccountStore.use.setAccounts();
	const setSelected = tradingAccountStore.use.setSelected();

	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();

	const accountStats = tradingAccountStore.use.accountStats();

	const setAccountStats = tradingAccountStore.use.setAccountStats();
	const setSelectedAccountStats = tradingAccountStore.use.setSelectedAccountStats();
	const setAccountMarketType = tradingAccountStore.use.setAccountMarketType();

	const selectedTradingAccount = useSelectedTradingAccount();

	const dashboardContext = useContext(DashboardContext);
	const appContext = useContext(AppContext);
	const rfpGatewayContext = useContext(RfpGatewayContext);

	const forceRerender = useForceRerender();

	useObservable(dashboardContext.getPropertyChangeStream('mappedWatchlist'), forceRerender);

	const { mutate: savePreferences } = useSaveUserPreferences();

	// TODO: REFACTORING -> unify these next 2 useEffects
	useEffect(() => {
		if (!accountStats?.length || !selectedTradingAccount) return;
		const selectedAccountStats = accountStats.find((statEntry) => {
			return statEntry.account?.accountNumber === selectedTradingAccount.accountNumber;
		});

		if (!selectedAccountStats) return;

		setSelectedAccountStats(selectedAccountStats);

		// selectedTradingAccount is here because it's too had to rework it now
		// but generally this should be coming from the store
		// dashboardContext.selectedTradingAccount = selectedTradingAccount.id!;
	}, [selectedTradingAccount, accountStats]);

	useEffect(() => {
		if (
			!appContext.isChildWindow &&
			appContext.userPreferences &&
			dashboardContext.tradingAccount?.length &&
			rfpGatewayContext
		) {
			dashboardContext.tradingAccount.forEach((account) => {
				if (appContext.userPreferences?.user_prefs?.platform?.accountTypes) {
					appContext.userPreferences.user_prefs.platform.accountTypes[account?.providerAccountId] =
						account.accountMarketType || AccountMarketType.CFD;
				}
			});

			if (
				selectedTradingAccount &&
				appContext.userPreferences.user_prefs.platform.lastLoggedAct[tradingMode as keyof LastLoggedAct]?.length === 0
			) {
				appContext.userPreferences.user_prefs.platform.lastLoggedAct[tradingMode as keyof LastLoggedAct] =
					selectedTradingAccount.providerAccountId;
			}

			const lastLogged =
				appContext.userPreferences.user_prefs.platform.lastLoggedAct[tradingMode as keyof LastLoggedAct];

			fillContextsFromPreferences(appContext.userPreferences, isFundedTrader);
			savePreferences();

			// TODO: replace the tradingAccount array with the one from accounts in tradingAccountStore
			const found = dashboardContext.tradingAccount.findIndex(
				(account) => account.accountNumber?.toString() === lastLogged
			);
			if (found !== -1) {
				rfpGatewayContext.send(RFP.tradingAccountLogin, {
					action: 'Login',
					tradingAccountId: dashboardContext.tradingAccount[found].id,
				});

				const accountMarketType = dashboardContext.tradingAccount[found]?.accountMarketType;
				const accountType = dashboardContext.tradingAccount[found]?.accountType;

				setAccountMarketType({
					isJapanSpread: accountMarketType === AccountMarketType.JapanSpread,
					isJapanSubscription: accountMarketType === AccountMarketType.Japan && accountType === TradingAccountType.LIVE,
					isSpreadBetting: accountMarketType === AccountMarketType.SpreadBetting,
				});
				setSelected(found);
			}
		}
		return;
	}, [
		appContext.userPreferences,
		dashboardContext.tradingAccount,
		selectedTradingAccount,
		isFundedTrader,
		selectedTradingAccount?.accountMarketType,
	]);

	useEffect(() => {
		if (isSpreadBettingAccount && appContext.userPreferences) {
			dashboardContext.quantityType = 'Lots';
		}
	}, [isSpreadBettingAccount, appContext.userPreferences]);

	useObservable(appContext.getPropertyChangeStream('subscriptionInfo'), (change) => {
		// first condition is important - change the watchlist only when the initial subscriptionInfo is loaded
		// we read it regularly and if the user has changed the selected WL we override it
		if (!change.oldValue && change.newValue && change.newValue.tier) {
			const tierOptions = {
				Tier0: 'Tier 1',
				Tier1: 'Tier 1',
				Tier2: 'Tier 2',
				Tier3: 'Tier 3',
			};
			// delay selecting the watchlist to the useEffect below to ensure tier watchlist exists
			// preserve the value in a local state before putting it in the store
			setTierWatchlist(tierOptions[change.newValue.tier]);
		}
	});

	useEffect(() => {
		// wait for Tier watchlist selection and preferences to be loaded
		if (!tierWatchlist || !dashboardContext.mappedWatchlist) {
			return;
		}
	}, [dashboardContext.mappedWatchlist, tierWatchlist]);

	useObservable(appContext.getPropertyChangeStream('accountStats'), (change) => {
		if (change.newValue?.length) {
			// TODO: Double check why we need this in 2 different attributes in the same store,
			// accounts data is repeated
			setAccounts(appContext.accountStats.map(({ account }) => account));
			if (rfpGatewayContext) {
				const accountIDs = change.newValue.map(({ account }) => account.accountNumber.toString());
				const id = 'rfpUrl';

				rfpGatewayContext.send(RFP.requestRfpServerNames, {
					reqId: id,
					acctIds: accountIDs,
				});
			}
			setAccountStats(appContext.accountStats);
		}
	});

	useEffect(() => {
		if (!dashboardContext.quantityType || !selectedTradingAccount || !rfpGatewayContext) {
			return;
		}

		rfpGatewayContext.updateQuantityType(
			selectedTradingAccount.id,
			dashboardContext.quantityType === 'Lots' ? QuantityType.Lots : QuantityType.Amount
		);
	}, [selectedTradingAccount?.id]);

	useObservable(dashboardContext.getPropertyChangeStream('tradingAccount'), (change) => {
		if (change.newValue.length > change.oldValue.length) {
			let selected = dashboardContext.tradingAccount?.findIndex(
				(acc) => acc.id === dashboardContext.selectedTradingAccount
			); //dashboardContext.selectedTradingAccount;
			if (dashboardContext.ssoTradingAccount) {
				const found = change.newValue.findIndex(
					(account) => account.accountNumber?.toString() === dashboardContext.ssoTradingAccount
				);
				selected = found ?? selected;
			}

			if (selected !== -1) {
				const accountMarketType = dashboardContext.tradingAccount[selected]?.accountMarketType;
				const accountType = dashboardContext.tradingAccount[selected]?.accountType;

				setAccountMarketType({
					isJapanSpread: accountMarketType === AccountMarketType.JapanSpread,
					isJapanSubscription: accountMarketType === AccountMarketType.Japan && accountType === TradingAccountType.LIVE,
					isSpreadBetting: accountMarketType === AccountMarketType.SpreadBetting,
				});
				setSelected(selected);
			}

			change.newValue.forEach((account: any) => {
				if (account.accountType === TradingAccountType.LIVE) {
					setHasLiveAccount(true);
				} else if (account.accountType === TradingAccountType.DEMO) {
					setHasDemoAccount(true);
				}
			});

			if (dashboardContext.selectedTradingAccount === 0) {
				// if the user has an account and we haven't logged in 5 seconds, log in to the first account
				setTimeout(() => {
					if (
						rfpGatewayContext &&
						dashboardContext.selectedTradingAccount === 0 &&
						dashboardContext.tradingAccount.length > 0
					) {
						const tradingAccount = dashboardContext.tradingAccount[0];
						dashboardContext.selectedTradingAccount = tradingAccount.id;
						rfpGatewayContext.send(RFP.tradingAccountLogin, {
							action: 'Login',
							tradingAccountId: tradingAccount.id,
						});
						if (appContext.userPreferences) {
							appContext.userPreferences.user_prefs.platform.lastLoggedAct[tradingMode as keyof LastLoggedAct] =
								tradingAccount.providerAccountId;
						}
					}
				}, 1);
			}
		}
	});

	useEffect(() => {
		// we haven't received the data needed yet - so do nothing
		if (hasLiveAccount === undefined) return;
		// we have loadead the accounts but not the application status;
		// the user has an account but we don't know in which state yet
		if (hasLiveAccount && status === ApplicationStatus.EMPTY) return;
		if (!hasLiveAccount && isFundedTrader) return;

		if (hasLiveAccount && isDemoMode) {
			setPermissions(withLiveAccount_inDemoMode[status as keyof typeof withLiveAccount_inDemoMode]);
		} else if (hasLiveAccount && isLiveMode) {
			setPermissions(withLiveAccount_inLiveMode[status as keyof typeof withLiveAccount_inLiveMode]);
		} else if (!hasLiveAccount && isDemoMode) {
			setPermissions(withoutLiveAccount_inDemoMode[status as keyof typeof withoutLiveAccount_inDemoMode]);
		} else if (!hasLiveAccount && isLiveMode) {
			setPermissions(withoutLiveAccount_inLiveMode[status as keyof typeof withoutLiveAccount_inLiveMode]);
		}

		setInfoMessage(status!);
	}, [status, hasLiveAccount, isDemoMode, isLiveMode, isAuthenticated, setPermissions]);

	useEffect(() => {
		if (!isJapanAccount && isLiveMode) {
			if (status === ApplicationStatus.APPROVED) {
				setShowModal(false);
				appContext.statusModal = false;
			}
		} else {
			setShowModal(true);
			appContext.statusModal = true;
		}
	}, []);

	return null;
};

export default AccountStatusHandler;
