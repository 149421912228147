import React, { useEffect, useState, useContext, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from 'react-loading-skeleton';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'react-loading-skeleton/dist/skeleton.css';

import { useTranslation } from 'react-i18next';

import AppContext from '../../../../../../contexts/AppContext';
import DashboardContext from '../../../../../../contexts/DashboardContext';

import {
	InstrumentLastTrade,
	MarketItem,
	NewPositionRequest,
	PriceQuote,
	RFPDataObjectType,
} from '../../../../../../gateways/RfpGateway/rfp.types';
import { newTradeRequest, OrderConfirmType } from '../../../../../../gateways/RfpGateway/rfpTrades';
import { RFP } from '../../../../../../gateways/RfpGateway/rfpConstants';

import Modal from '../../../../../../shared/Modal/Modal';
import Button from '../../../../../../shared/Button/Button';

import {
	convertAmountToLot,
	convertLotToAmount,
	fillQuantity,
	getAllLotsAtAccount,
	getInstrumentDetails,
} from '../../../../../../utils/functions/calculations';
import { createWatchlistMap } from '../../../../../../utils/functions/WatchlistUtils';
import useForceRerender from '../../../../../../utils/hooks/useForceRerender';
import useObservable from '../../../../../../utils/hooks/useObservable';
import { QUANTITY_TYPE, TradingPositionType } from '../../../../../../utils/functions/enums';
import { forceCloseModal } from '../../../../../../utils/hooks/useForceCloseModal';

import {
	formatNumberWithCommas,
	isCommaSeparator,
	revertToTraditionalNumberFormat,
	localizeDecimalSeparator,
	replaceAllThousandSeparators,
} from '../../../Watchlist/Instrument/formattedQuoteNumber';

import useHandleSubscriptionConstraints from '../../../../../../utils/hooks/useHandleSubscriptionConstraints';

import useHandleOrderConstraint from '../../../../../../utils/hooks/useHandleOrderConstraint';

import RfpGatewayContext from '../../../../../../contexts/RfpGatewayContext';

import useOrderTicketAccess from '../../../../../../utils/hooks/useOrderTicketAccess';

import useSelectedTradingAccount from '../../../../../../utils/hooks/useSelectedTradingAccount';

import authStore, { AuthStore } from '../../../../../../store/authStore';

import useSaveWatchlistToPreferences from '../../../../../../utils/hooks/watchlist/useSaveWatchlistToPreferences';

import tradingAccountStore from '../../../../../../store/tradingAccountStore';
import watchListStore from '../../../../../../store/WatchListStore/watchListStore';
import InstrumentIcon from '../../../../../components/GroupBadge/InstrumentIcon';
import { instrumentGroupProps } from '../../../../../../utils/functions/constants';
import TradingSessionTooltip from '../../../../../components/Shared/TradingSessionTooltip';

import OneClickTradeDropdown from './OneClickTradeDropdown';
import styles from './OneClickTradeTicket.module.scss';
import OneClickTradeButtons from './OneClickTradeButtons';
import { getSavedInstrumentsOCTValue, storeInstrumentsOCTValue } from './utils';
import cn from 'classnames';

enum TradeState {
	Default = 'DEFAULT',
	Loading = 'LOADING',
	Submitted = 'SUBMITTED',
}

interface OneClickTradeTicketProps {
	marketItem: MarketItem;
	instrument?: PriceQuote | null;
	handleInstrumentChange: () => void;
	isDynamicWatchlist: boolean;
}

const OneClickTradeTicket = React.forwardRef(
	({ marketItem, instrument, handleInstrumentChange, isDynamicWatchlist }: OneClickTradeTicketProps, ref) => {
		const appContext = useContext(AppContext);
		const dashboardContext = useContext(DashboardContext);
		const rfpGatewayContext = useContext(RfpGatewayContext);
		const language = appContext.languageSettings;
		const orderTicketAccess = useOrderTicketAccess();

		const forceRerender = useForceRerender();
		const { t } = useTranslation();

		const skeletonBaseColor = 'var(--gray-10)';
		const skeletonHighlightColor = 'var(--gray-5)';

		const isJapanSubscriptionAccount = tradingAccountStore.use.isJapanSubscription();
		const isLiveMode = authStore((store: AuthStore) => store.isLiveMode);
		const requestId = useRef<number>(0);
		const tradeState = useRef<TradeState>(TradeState.Default);
		const lastRequestTime = useRef<number>(NaN);
		const octStoredValue = appContext.octStoredValues;
		const [quantityValues, setQuantityValues] = useState<string[]>();
		const [selectedQuantity, setSelectedQuantity] = useState<string | undefined>(undefined);
		const [wrongQuantityError, setWrongQuantityError] = useState('');
		const [openRemoveModal, setOpenRemoveModal] = useState(false);
		const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
		const storedOCTValue = getSavedInstrumentsOCTValue();
		const handleSubscriptionsConstraints = useHandleSubscriptionConstraints();
		const handleOrderConstraint = useHandleOrderConstraint();

		const activeTradingAccount = useSelectedTradingAccount();
		const selectedTradingAccountId = activeTradingAccount?.id;

		if (octStoredValue && selectedTradingAccountId && !octStoredValue[selectedTradingAccountId]) {
			octStoredValue[selectedTradingAccountId] = {};
		}

		const asyncWait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
		const currentWatchList = watchListStore.use.currentWatchList();
		const subTradeEventIdRef = useRef<string | undefined>(undefined);

		const saveWatchlistToPreferences = useSaveWatchlistToPreferences();
		useObservable(
			dashboardContext.getPropertyChangeStream(
				'detailedInformation',
				'selectedInstrument',
				'oneClickTrading',
				'quantityType',
				'orderModalOpenedFrom',
				'modifyTicket',
				'tradingAccount',
				'selectedPosition',
				'selectedType',
				'applicationStatus',
				'accountValues'
			),
			async () => {
				forceRerender();
			}
		);

		const isSelected = dashboardContext.selectedInstrument
			? dashboardContext.selectedInstrument?.code === marketItem?.code
			: false;

		useEffect(() => {
			setQuantityValues(getQuantityValues());
			// Reset the wrong quantity error if any
			setWrongQuantityError('');
		}, [
			marketItem,
			activeTradingAccount?.tradingInstruments,
			dashboardContext.detailedInformation,
			dashboardContext.quantityType,
		]);

		useEffect(() => {
			if (dashboardContext.quantityType === QUANTITY_TYPE.LOTS) {
				setIsFirstRender(false);
			}
			if (isFirstRender) {
				setIsFirstRender(false);
			} else {
				if (marketItem && selectedTradingAccountId) {
					const selectedInstrument = getInstrumentDetails(
						dashboardContext.detailedInformation,
						activeTradingAccount,
						marketItem.code
					);
					if (selectedInstrument && octStoredValue) {
						const value = octStoredValue[selectedTradingAccountId][marketItem.displayName]?.toString();
						const convertedValue = replaceAllThousandSeparators(value);
						let result = convertedValue;

						if (dashboardContext.quantityType === QUANTITY_TYPE.LOTS) {
							result = convertAmountToLot(+convertedValue, selectedInstrument.rndLot).toString();
						} else {
							result = convertLotToAmount(+convertedValue, selectedInstrument.rndLot).toString();
						}

						octStoredValue[selectedTradingAccountId][marketItem.displayName] = result.toString();
						storeInstrumentsOCTValue(octStoredValue);
					}
				}
			}
		}, [dashboardContext.quantityType]);

		// Listen for order status and compare with the latest requestId. The response is not consistent and we can think for another way to handle trade request feedback...
		useEffect(() => {
			if (rfpGatewayContext) {
				if (subTradeEventIdRef.current) {
					rfpGatewayContext.unsubscribeFor(subTradeEventIdRef.current);
				}

				rfpGatewayContext.subscribeFor(RFPDataObjectType.TradeEvent, (tradeEvent) => {
					// eslint-disable-next-line eqeqeq
					if (tradeEvent.requestId == requestId.current) {
						tradeState.current = TradeState.Default;
						requestId.current = 0;
					}
				});
			}
			return () => {
				if (rfpGatewayContext && subTradeEventIdRef.current) {
					rfpGatewayContext.unsubscribeFor(subTradeEventIdRef.current);
				}
			};
		}, [rfpGatewayContext]);

		useEffect(() => {
			if (tradeState.current === TradeState.Loading) {
				(async () => {
					await asyncWait(2000);
					if (tradeState.current === TradeState.Loading) {
						tradeState.current = TradeState.Submitted;
						await asyncWait(1000);
						tradeState.current = TradeState.Default;
					}
				})();
			}
		}, [tradeState.current]);

		// Account rules (can be refactored a bit later, for example put the logic into a separate hook...)

		const getDefQuantityValue = () => {
			let quantityValue = NaN;
			const selectedInstrument = getInstrumentDetails(
				dashboardContext.detailedInformation,
				activeTradingAccount,
				marketItem.code
			);

			if (selectedInstrument) {
				dashboardContext.instrumentLastTrade.forEach((element: InstrumentLastTrade, index: number) => {
					if (element.hasOwnProperty(selectedInstrument.code)) {
						if (element[selectedInstrument.code].quantity !== null) {
							quantityValue = element[selectedInstrument.code].quantity!;
						}
					}
				});

				if (!isNaN(quantityValue)) {
					if (dashboardContext.quantityType === QUANTITY_TYPE.LOTS) {
						quantityValue = convertAmountToLot(quantityValue, selectedInstrument.rndLot);
					}
				}
			}
			return quantityValue;
		};

		const formatValue = (inputValue: string) => {
			const value = localizeDecimalSeparator(inputValue, language);
			const separator = isCommaSeparator(language) ? ',' : '.';
			const decPrec = value.split(separator)[1]?.length ?? 0;
			const formattedNum = formatNumberWithCommas(inputValue, decPrec, language);

			return formattedNum;
		};

		const getQuantityValues = () => {
			let quantities: number[] = [];
			if (!marketItem) {
				return;
			}

			if (!marketItem.code || !marketItem.decPrec) {
				return;
			}

			switch (dashboardContext.quantityType) {
				case QUANTITY_TYPE.LOTS:
					quantities = getAllLotsAtAccount(marketItem, activeTradingAccount, dashboardContext.detailedInformation);
					break;
				case QUANTITY_TYPE.AMOUNT:
					quantities = fillQuantity(marketItem, activeTradingAccount, dashboardContext.detailedInformation);
					break;
			}

			const defQuantity = getDefQuantityValue();
			if (quantities.length > 0) {
				const defValue = `${isNaN(defQuantity) ? quantities[0] : defQuantity}`;
				if (octStoredValue && selectedTradingAccountId) {
					if (!octStoredValue[selectedTradingAccountId][marketItem.displayName]) {
						octStoredValue[selectedTradingAccountId][marketItem.displayName] = formatValue(defValue);
						setSelectedQuantity(defValue);
						storeInstrumentsOCTValue(octStoredValue);
					} else if (storedOCTValue[selectedTradingAccountId]?.hasOwnProperty(marketItem.displayName)) {
						octStoredValue[selectedTradingAccountId][marketItem.displayName] =
							storedOCTValue[selectedTradingAccountId][marketItem.displayName];
						setSelectedQuantity(storedOCTValue[selectedTradingAccountId][marketItem.displayName]);
					}
				}
			}

			let res: string[] = quantities.map((element) => {
				return formatValue(`${element}`);
			});
			return res;
		};

		const isQuantityValid = (inputValue: string) => {
			const value = revertToTraditionalNumberFormat(inputValue, language);
			const quantity = +value;
			if (isNaN(quantity) || quantity <= 0) {
				return false;
			}
			const selectedInstrumentDetails = getInstrumentDetails(
				dashboardContext.detailedInformation,
				activeTradingAccount,
				marketItem.code
			);
			if (selectedInstrumentDetails) {
				const isLots = dashboardContext.quantityType === QUANTITY_TYPE.LOTS;
				const amount = isLots ? convertLotToAmount(quantity, selectedInstrumentDetails.rndLot) : quantity;
				const minAmount = convertLotToAmount(selectedInstrumentDetails.minL, selectedInstrumentDetails.rndLot);
				const maxAmount = convertLotToAmount(selectedInstrumentDetails.maxL, selectedInstrumentDetails.rndLot);
				const stepAmount = convertLotToAmount(selectedInstrumentDetails.stpL, selectedInstrumentDetails.rndLot);
				// Use Math.round in order to prevent error with Double values,
				// at some point it could give a number as 2.299999999999423 and dividing to stepAmount will always fail
				const isAmountValidByStep = Math.round(amount * 1000000) % Math.round(stepAmount * 1000000) === 0;

				return amount >= minAmount && amount <= maxAmount && isAmountValidByStep;
			}

			return false;
		};

		const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			//for sb instrument we are adding points in the value and it return invalid value; for example "$ 0.11 /point"
			const [input] = e.target.value.match(/[\d,.]+/) ?? '0';

			const value = revertToTraditionalNumberFormat(input, language);
			const isValid = isQuantityValid(value);
			if (octStoredValue && selectedTradingAccountId) {
				octStoredValue[selectedTradingAccountId][marketItem.displayName] = e.target.value;
				setSelectedQuantity(value);
			}
			if (isValid) {
				storeInstrumentsOCTValue(octStoredValue);
			}
			setWrongQuantityError(isValid ? '' : t('en:INVALID_VALUE'));
		};

		const handleQuantityChange = (value: string) => {
			setWrongQuantityError('');
			const quantityValue = revertToTraditionalNumberFormat(value, language);
			if (octStoredValue && selectedTradingAccountId) {
				octStoredValue[selectedTradingAccountId][marketItem.displayName] = value;
				setSelectedQuantity(quantityValue);
			}
			storeInstrumentsOCTValue(octStoredValue);
		};

		const handleRemoveButtonClick = () => {
			setOpenRemoveModal(true);
		};

		const handleCancelRemove = () => {
			setOpenRemoveModal(false);
			forceCloseModal('fade modal');
		};

		const handleRemoveItem = () => {
			setOpenRemoveModal(false);
			const selectedWatchlist = dashboardContext.watchlist;
			let preferenceToUpdate = selectedWatchlist.find((preference) => preference._name === currentWatchList);
			if (preferenceToUpdate) {
				preferenceToUpdate.instrument = preferenceToUpdate.instrument.filter(
					(instrument) => instrument._code !== marketItem.code
				);
			}
			dashboardContext.watchlist = selectedWatchlist;
			dashboardContext.mappedWatchlist = createWatchlistMap(dashboardContext.watchlist);
			saveWatchlistToPreferences(dashboardContext.watchlist);
			forceCloseModal('fade modal');
		};

		const handleItemClick = () => {
			handleInstrumentChange();
		};

		const handleBuySellClick = (orderType: 'BUY' | 'SELL') => {
			if (rfpGatewayContext) {
				if (!orderTicketAccess()) {
					return;
				}

				// Want to one-click-trade? Not so fast!
				// Is this a Japan account with some sort of constraint?
				if (isJapanSubscriptionAccount && isLiveMode) {
					const nonVolumeConstraint = handleSubscriptionsConstraints(marketItem);
					if (nonVolumeConstraint) {
						return;
					}

					const orderSizeConstraint = handleOrderConstraint(parseInt(selectedQuantity!));
					if (orderSizeConstraint) {
						return;
					}
				}

				// Not allow trading requests during processing the trade
				if (tradeState.current !== TradeState.Default) {
					return;
				}

				// Allow only one trade request in 333 ms (business requirement)
				const timeInMillis = Date.now();
				if (!isNaN(lastRequestTime.current) && timeInMillis - lastRequestTime.current <= 333) {
					return;
				}

				lastRequestTime.current = timeInMillis;

				if (dashboardContext.detailedInformation && marketItem && selectedQuantity) {
					if (isQuantityValid(selectedQuantity)) {
						tradeState.current = TradeState.Loading;
						requestId.current = +new Date().getMilliseconds();

						const selectedInstrumentDetails = getInstrumentDetails(
							dashboardContext.detailedInformation,
							activeTradingAccount,
							marketItem.code
						);
						let lotAmountQty;
						let tradeSize;

						if (
							dashboardContext.quantityType &&
							dashboardContext.quantityType === QUANTITY_TYPE.LOTS &&
							selectedInstrumentDetails
						) {
							lotAmountQty = +replaceAllThousandSeparators(selectedQuantity);
							tradeSize = convertLotToAmount(lotAmountQty, selectedInstrumentDetails.rndLot);
						} else {
							tradeSize = +replaceAllThousandSeparators(selectedQuantity);
						}

						const orderRequestObject: OrderConfirmType = {
							orderType: orderType,
							selectedInstrument: marketItem,
							selectedTradingAccount: selectedTradingAccountId,
							lotAmountQty: lotAmountQty,
							tradeSize: tradeSize,
							typeOfOrder: TradingPositionType.Market,
							reqId: requestId.current,
						};
						rfpGatewayContext.send(RFP.newTrade, newTradeRequest(orderRequestObject) as unknown as NewPositionRequest);
					}
				}
			}
		};

		return (
			<>
				<div
					ref={ref}
					className={cn(
						isSelected ? styles.containerSelected : styles.container,
						dashboardContext.mappedWatchlist &&
							dashboardContext.mappedWatchlist[currentWatchList].length === 1 &&
							styles.containerLimited
					)}
					onClick={() => handleItemClick()}
				>
					<div className={styles.wrapper}>
						<div className={styles.iconContainer}>
							<OverlayTrigger
								key="instrumentCategoryInfo"
								delay={{ show: 750, hide: 0 }}
								placement="bottom"
								overlay={
									<Tooltip id="watchlistIconInstrumentHeader" className="my-tooltip">
										{marketItem.grp && t(instrumentGroupProps[marketItem.grp]?.name)}
									</Tooltip>
								}
							>
								<div className={styles.badge}>
									<InstrumentIcon marketItem={marketItem} />
								</div>
							</OverlayTrigger>
						</div>
						<div className={styles.headerContainer}>
							{!isDynamicWatchlist && (
								<div className={styles.removeIconContainer}>
									<FontAwesomeIcon
										icon={['fal', 'times']}
										className={styles.removeIcon}
										onClick={(e: any) => {
											e.stopPropagation();
											handleRemoveButtonClick();
										}}
									/>
								</div>
							)}
							<div className={styles.instrumentHeader}>
								<div className={styles.instrumentCode}>
									{marketItem?.exchangeTicker || marketItem?.code || (
										<Skeleton baseColor={skeletonBaseColor} highlightColor={skeletonHighlightColor} width={100} />
									)}

									{(marketItem?.exchangeTicker || marketItem?.code) && (
										<TradingSessionTooltip marketItem={marketItem} />
									)}
								</div>
							</div>
							<div className={styles.instrumentName}>
								{marketItem?.fullName || (
									<Skeleton baseColor={skeletonBaseColor} highlightColor={skeletonHighlightColor} width={200} />
								)}
							</div>
						</div>
					</div>

					{tradeState.current === TradeState.Default && (
						<OneClickTradeButtons
							marketItem={marketItem}
							instrument={instrument}
							handleBuySellClick={handleBuySellClick}
						/>
					)}
					{tradeState.current === TradeState.Loading && (
						<div className={styles.loadingContainer}>
							<FontAwesomeIcon icon={['fas', 'spinner']} className={styles.loadingIcon} spin />
						</div>
					)}
					{tradeState.current === TradeState.Submitted && (
						<div className={styles.submittedContainer}>
							<div className={styles.submittedText}>{t('wtr:ORDER_SUBMITTED')}</div>
						</div>
					)}

					<OneClickTradeDropdown
						formattedQuantity={
							(octStoredValue &&
								selectedTradingAccountId &&
								octStoredValue[selectedTradingAccountId][marketItem?.displayName]) ||
							0
						}
						quantityValues={quantityValues}
						handleQuantityChange={handleQuantityChange}
						handleInputChange={handleInputChange}
						wrongQuantityError={wrongQuantityError}
					/>
					{wrongQuantityError && <div className={styles.wrongQuantityError}> {wrongQuantityError}</div>}
				</div>

				<Modal show={openRemoveModal} centered dialogClassName={styles.modalRemoveWatchlist}>
					<Modal.Header className={styles.modalTopHeader}>
						<Modal.Title className={styles.modalTitle}>{t('en:REMOVE')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className={styles.removeMessage}>{t('wtr:CONFIRM_REMOVE')}</div>
					</Modal.Body>
					<Modal.Footer className={styles.modifyFooter}>
						<div className={styles.actionButtons}>
							<Button
								variant="secondary"
								size="lg"
								label={t('en:CANCEL')}
								className={styles.cancelButton}
								onClick={handleCancelRemove}
							/>
							<Button
								variant="primary"
								size="lg"
								label={t('en:REMOVE')}
								className={styles.saveButton}
								onClick={handleRemoveItem}
							/>
						</div>
					</Modal.Footer>
				</Modal>
			</>
		);
	}
);

export default OneClickTradeTicket;
