/* eslint import/no-webpack-loader-syntax: off */
import clone from 'clone';
import { ModuleThread, spawn, Thread } from 'threads';
import { Observable, Subject, Subscription, filter, map } from 'observable-fns';
import { default as protobuf } from 'protobufjs';

import { default as WebWorker } from 'worker-loader!../../workers/BinaryRfpClient.worker';

import { default as TypeUtils, ExcludeProperty } from '../../utils/functions/TypeUtils';
import { default as Nullable, Optional } from '../../utils/functions/Nullable';
import { default as PromiseFactory } from '../../utils/functions/PromiseFactory';
import { default as Lazy } from '../../utils/functions/Lazy';
import { BinaryRfpClientWorker, BinaryRfpClientWorkerState } from '../../workers/BinaryRfpClient.worker';
import TTSubscriptionManager from '../../utils/functions/subscriptionManager';
import { Resolver } from '../../utils/functions/Ioc';
import { AppContextProvider } from '../../contexts/AppContext';
import { ChartContextProvider } from '../../contexts/ChartContext';
import uniqueId from '../../utils/functions/uniqueId';

import ChartUtils from '../../chartiq/GridViewCharts/ChartUtils';

import { RfpLangMap, rfpLangMap } from '../../views/features/Dashboard/Settings/settingsOptions';

import { QuantityType } from '../../utils/functions/enums';

import {
	AccountTierRequest,
	AccountTierResponse,
	ClientCommandResult,
	CloseTradeRequest,
	EditOrderRequest,
	EditPositionRequest,
	FeedInfo,
	FeedInfos,
	FeedSubscription,
	HistoryRequestStartDate,
	HistorySize,
	HistorySubscription,
	HistoryTick,
	HistoryTickItems,
	HistoryTimescaleStartDate,
	MarketItem,
	MarketItemInfo,
	MarketItemsInfoRecord,
	MarketItemsRecord,
	MarketWatchCategories,
	MarketWatchCategory,
	MarketWatchDynamicSubscriptionRequest,
	MarketWatchIntervals,
	MarketWatchItem,
	MarketWatchItems,
	MarketWatchSnapshotsRequest,
	MarketWatchSubscriptionRequest,
	MarketWinNewsSubscriptionRequest,
	NewOrderRequest,
	NewPositionRequest,
	NewsSubscriptionRequest,
	OrderExpiration,
	PartialCloseRequest,
	Pong,
	PreviousDayClosePrice,
	PreviousDayClosePriceRequest,
	PriceQuote,
	QuantityTypeUpdate,
	QuoteSubscription,
	RequestTTWatchList,
	RFPConnectPayload,
	RFPConnectResult,
	RFPDataObject,
	RFPDataObjectType,
	RFPNews,
	RFPRequestTTSessionTimes,
	RFPSessionTimes,
	RFPTradingAccountsError,
	SubscriptionAction,
	TfboEndpointResponse,
	TfboLoginResponse,
	TimeScale,
	TradeEvent,
	TradingAccount,
	TradingAccountLogin,
	TradingAccountLoginRequest,
	TradingAccountLogoutRequest,
	TradingInstruments,
	TradingPosition,
	TradingProvider,
	TTWatchList,
	TTWatchListItem,
	RFPConnectionErrorType,
	RequestRfpServerNames,
	RfpServerNames,
	RFPServerItem,
	RequestTTMarginRules,
	TTMarginRules,
	FutureMargin,
	RequestFutureMargin,
	SessionError,
} from './rfp.types';
import {
	default as RfpGateway,
	IDataObjectSubscription,
	IHistoryFeed,
	IHistoryFeedSubscription,
	IPriceFeed,
	IPriceFeedSubscription,
	IPriceQuoteSubscription,
	IRfpGatewayConfig,
	IRfpGatewayEventMap,
} from './RfpGateway';
import { default as RfpBinaryMessagesJson } from './RfpBinaryMessages.json';
import { default as RfpBinaryMessages } from './RfpBinaryMessages';
import { RFP, TRfpDataMessageMap, TRfpMessages, TRfpSendParams } from './rfpConstants';

//#region TODO:

/*
        1. editOrder (commandId: WrapperMessage.Command.REQ_EDIT_ORDER) requests without a non-null, non-empty, non-zero, non-negative stopLevel value result in an error message of "Operation failed. Invalid parameter [Stop Loss], value []" - see WTR-748
        2. editPosition (commandId: WrapperMessage.Command.REQ_EDIT_POS) requests without a non-null, non-empty, non-zero, non-negative limitLevel value result in an error message of "Operation failed. Invalid parameter [Take Profit], value []" - see WTR-748
    */

//#endregion

//#region Config

class BinaryRfpGatewayConfig implements IRfpGatewayConfig {
	private m_config: IRfpGatewayConfig;

	public get defaultFeedId(): string {
		return this.m_config.defaultFeedId || 'VTFeed';
	}

	public get baseUrl(): string {
		return this.m_config.baseUrl;
	}

	public get websocketUrl(): string {
		return `${this.baseUrl}/bin`;
	}

	public get maxRequestsPerSecond(): number {
		return this.m_config.webSocket.maxRequestsPerSecond!;
	}

	public get webSocket(): IRfpGatewayConfig['webSocket'] {
		return this.m_config.webSocket;
	}

	public get debugHandler(): Optional<(...args: any) => void> {
		return this.m_config.debugHandler;
	}

	public constructor(config: IRfpGatewayConfig) {
		this.m_config = clone(config);
		this.m_config.baseUrl = this.m_config.baseUrl.replace(/\/+$/gm, '');
		if (this.m_config.webSocket.autoReconnect !== false) {
			this.m_config.webSocket.autoReconnect.interval = Math.ceil(
				Math.max(0, this.m_config.webSocket.autoReconnect.interval)
			);
		}
		if (this.m_config.webSocket.maxRequestsPerSecond == null || this.m_config.webSocket.maxRequestsPerSecond <= 0) {
			// RFP Server support max 200 requests per second,
			// lets limit it to 100 requests per second on the client side
			// if is need it can be increased
			this.m_config.webSocket.maxRequestsPerSecond = 200;
		}
		this.m_config.webSocket.feedDelay = Math.max(0, this.m_config.webSocket.feedDelay || 0);
	}
}

//#endregion

//#region Messages

export interface IWrapperMessage extends RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.IWrapperMessage {
	commandId: RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.WrapperMessage.Command;
}

interface ISubscriptionMessage<T = any> {
	commandId: RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.WrapperMessage.Command;
	data: T;
	time?: number | null;
}

//#endregion

//#region ProtobuffSchema

interface IRfpMessageMapper {
	send: {
		[K in keyof TRfpMessages['send']]?: {
			map: (message: TRfpMessages['send'][K]) => IWrapperMessage;
		};
	};
	subscribe: {
		[K in keyof TRfpMessages['subscribe']]?: {
			commandId: IWrapperMessage['commandId'] | IWrapperMessage['commandId'][];
			map: (message: IWrapperMessage) => TRfpMessages['subscribe'][K] | IterableIterator<TRfpMessages['subscribe'][K]>;
		};
	};
}

interface IRSHMap {
	rshL: number;
	rshS: number;
	pipPos: number;
}

class ProtobufSchema {
	private static _rshMap: Map<string, IRSHMap>;
	private static readonly _jsonDefinitions = new Lazy(() => RfpBinaryMessagesJson);
	private static readonly _root = new Lazy(() => protobuf.Root.fromJSON(ProtobufSchema._jsonDefinitions.getValue()));
	private static readonly _types = new Lazy(() => {
		const root = ProtobufSchema._root.getValue();
		return {
			ProtoVer: (root.lookupTypeOrEnum('com.thinkmarkets.webtrader.protobuf.v1.ProtoVer') as any)
				.values as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.ProtoVer,
			LoginMode: (root.lookupTypeOrEnum('com.thinkmarkets.webtrader.protobuf.v1.LoginMode') as any)
				.values as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.LoginMode,
			LocalizationLang: (root.lookupTypeOrEnum('com.thinkmarkets.webtrader.protobuf.v1.LocalizationLang') as any)
				.values as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.LocalizationLang,
			LoginRequest: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.LoginRequest'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.LoginRequest,
			AuthTokenLoginRequest: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.AuthTokenLoginRequest'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.AuthTokenLoginRequest,
			RequestTfboLogin: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestTfboLogin'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestTfboLogin,
			RequestTfboEndpoint: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestTfboEndpoint'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestTfboEndpoint,
			Ping: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.Ping'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.Ping,
			Pong: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.Pong'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.Pong,
			SSOLoginRequest: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.SSOLoginRequest'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.SSOLoginRequest,
			LoginResponse: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.LoginResponse'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.LoginResponse,
			TfboLoginResponse: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.TfboLoginResponse'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TfboLoginResponse,
			TfboEndpointResponse: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.TfboEndpointResponse'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TfboEndpointResponse,
			RequestQuotes: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestQuotes'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestQuotes,
			Quote: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.Quote'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.Quote,
			QuoteServiceStatus: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.QuoteServiceStatus'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.QuoteServiceStatus,

			RequestHistory: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestHistory'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestHistory,
			RequestHistoryStartDate: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestHistoryStartDate'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestHistoryStartDate,
			HistoryStartDate: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.HistoryStartDate'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.HistoryStartDate,
			HistoryTick: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.HistoryTick'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.HistoryTick,
			HistoryTickItems: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.HistoryTickItems'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.HistoryTickItems,
			HistorySize: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.HistorySize'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.HistorySize,

			RequestFeeds: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestFeeds'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestFeeds,
			FeedInfo: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.FeedInfo'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.FeedInfo,
			FeedInfos: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.FeedInfos'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.FeedInfos,

			RequestMarketItems: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestMarketItems'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestMarketItems,
			MarketItem: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.MarketItem'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.MarketItem,
			MarketItems: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.MarketItems'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.MarketItems,

			RequestMarketItemsInfo: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestMarketItemsInfo'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestMarketItemsInfo,
			MarketItemInfo: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.MarketItemInfo'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.MarketItemInfo,
			MarketItemsInfo: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.MarketItemsInfo'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.MarketItemsInfo,

			RequestTradingProviders: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestTradingProviders'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestTradingProviders,
			TradingProvider: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.TradingProvider'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TradingProvider,

			RequestTradingAccounts: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestTradingAccounts'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestTradingAccounts,
			RequestRfpServers: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestRfpServers'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestRfpServers,
			RfpServers: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RfpServers'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RfpServers,
			TradingAccountsError: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.TradingAccountsError'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TradingAccountsError,
			TradingAccount: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.TradingAccount'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TradingAccount,
			RequestLoginTradingAccount: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestLoginTradingAccount'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestLoginTradingAccount,
			RequestLogoutTradingAccount: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestLogoutTradingAccount'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestLogoutTradingAccount,
			TradingAccountLoginInfo: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.TradingAccountLoginInfo'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TradingAccountLoginInfo,
			RequestAccountTierInfo: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestAccountTierInfo'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestAccountTierInfo,
			AccountTierInfo: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.AccountTierInfo'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.AccountTierInfo,

			RequestTTWatchList: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestTTWatchList'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestTTWatchList,
			TTWatchList: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.TTWatchList'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TTWatchList,

			RequestTTMarginRules: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestTTMarginRules'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestTTMarginRules,
			TTMarginRules: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.TTMarginRules'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TTMarginRules,

			RequestFutureMargin: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestFutureMargin'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestFutureMargin,
			FutureMargin: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.FutureMargin'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.FutureMargin,

			VTUpdateTradingAccountQuantityType: (
				root.lookupTypeOrEnum('com.thinkmarkets.webtrader.protobuf.v1.VTUpdateTradingAccount.QuantityType') as any
			).values as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.VTUpdateTradingAccount.QuantityType,
			VTUpdateTradingAccount: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.VTUpdateTradingAccount'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.VTUpdateTradingAccount,

			RequestPreviousDayClosePrice: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestPrevDayClosePrice'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestPrevDayClosePrice,

			PreviousDayClosePrice: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.PrevDayClosePrice'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.PrevDayClosePrice,

			TradingInstrument: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.TradingInstrument'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TradingInstrument,
			TradingInstruments: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.TradingInstruments'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TradingInstruments,

			DemoAccountParam: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.DemoAccountParam'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.DemoAccountParam,
			DemoAccountParams: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.DemoAccountParams'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.DemoAccountParams,

			TradingAccountProperty: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.TradingAccountProperty'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TradingAccountProperty,
			TradingAccountProperties: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.TradingAccountProperties'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TradingAccountProperties,

			TradeEventMsgType: (root.lookupTypeOrEnum('com.thinkmarkets.webtrader.protobuf.v1.TradeEventMsgType') as any)
				.values as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TradeEventMsgType,
			TradeEvent: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.TradeEvent'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TradeEvent,
			TradeEventExt: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.TradeEventExt'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TradeEventExt,
			TradeEventEntry: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.TradeEventEntry'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TradeEventEntry,

			Position: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.Position'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.Position,
			Positions: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.Positions'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.Positions,

			RequestNewTrade: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestNewTrade'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestNewTrade,
			RequestNewOrder: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestNewOrder'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestNewOrder,
			RequestEditPosition: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestEditPosition'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestEditPosition,
			RequestEditOrder: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestEditOrder'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestEditOrder,
			RequestCloseTrade: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestCloseTrade'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestCloseTrade,
			RequestCloseTradePartial: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestCloseTradePartial'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestCloseTradePartial,

			RequestMarketWatchIntervals: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestMarketWatchIntervals'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestMarketWatchIntervals,
			MarketWatchIntervals: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.MarketWatchIntervals'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.MarketWatchIntervals,
			RequestMarketWatchCategories: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestMarketWatchCategories'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestMarketWatchCategories,
			MarketWatchCategories: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.MarketWatchCategories'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.MarketWatchCategories,
			RequestMarketWatchSnapshots: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestMarketWatchSnapshots'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestMarketWatchSnapshots,
			RequestMarketWatchSubscription: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestMarketWatchSubscription'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestMarketWatchSubscription,
			RequestMarketWatchDynamicSubscription: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestMarketWatchDynamicSubscription'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestMarketWatchDynamicSubscription,
			MarketWatchItem: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.MarketWatchItem'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.MarketWatchItem,
			MarketWatchItems: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.MarketWatchItems'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.MarketWatchItems,

			TradingAccountNotFound: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.TradingAccountNotFound'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TradingAccountNotFound,
			TradingAccountDeleted: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.TradingAccountDeleted'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TradingAccountDeleted,

			RequestTTSessionTimes: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestTTSessionTimes'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestTTSessionTimes,
			TTSessionTimes: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.TTSessionTimes'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.TTSessionTimes,

			RequestNews: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestNews'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestNews,
			RequestMarketWinNews: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestMarketWinNews'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestMarketWinNews,
			RequestNewsGroups: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestNewsGroups'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestNewsGroups,
			RequestNewsConfig: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.RequestNewsConfig'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestNewsConfig,
			NewsArticle: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.NewsArticle'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.NewsArticle,
			NewsArticles: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.NewsArticles'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.NewsArticles,
			NewsFilterGroup: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.NewsFilterGroup'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.NewsFilterGroup,
			NewsFilterGroups: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.NewsFilterGroups'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.NewsFilterGroups,
			NewsFilterEntry: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.NewsFilterEntry'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.NewsFilterEntry,
			NewsFilterEntries: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.NewsFilterEntries'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.NewsFilterEntries,
			MarketWinNews: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.MarketWinNews'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.MarketWinNews,
			TierUpdatePush: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.UpdateAcctTier'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.UpdateAcctTier,
			ClientCommandResult: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.ClientCommandResult'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.ClientCommandResult,
			SessionError: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.SessionError'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.SessionError,
			WrapperMessage: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.WrapperMessage'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.WrapperMessage,
		} as const;
	});

	private static readonly _messageMapper = new Lazy(() => {
		const messageMapper: IRfpMessageMapper = {
			send: {
				[RFP['ping']]: {
					map: (message: null | undefined) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.PING,
							data: ProtobufSchema.types.Ping.encode({}).finish(),
						});
					},
				},
				// [RFP['pong']]: {
				//     map: (message: null | undefined) => {
				//         return ProtobufSchema.types.WrapperMessage.fromObject({
				//             commandId: ProtobufSchema.types.WrapperMessage.Command.PONG,
				//             data: ProtobufSchema.types.Pong.encode({}).finish()
				//         })
				//     }
				// },
				[RFP['getFeedIds']]: {
					map: (message: null | undefined) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_FEEDS,
							data: ProtobufSchema.types.RequestFeeds.encode({}).finish(),
						});
					},
				},
				[RFP['getMarketItems']]: {
					map: (message: FeedSubscription) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_MKT_ITEMS,
							data: ProtobufSchema.types.RequestMarketItems.encode({ feedId: message.feedId }).finish(),
						});
					},
				},
				[RFP['getMarketItemsInfo']]: {
					map: (message: FeedSubscription) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_MKT_ITEMS_INFO,
							data: ProtobufSchema.types.RequestMarketItemsInfo.encode({ feedId: message.feedId }).finish(),
						});
					},
				},
				[RFP['getTradingAccounts']]: {
					map: (message: number) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_TR_ACCOUNTS,
							data: ProtobufSchema.types.RequestTradingAccounts.encode({ providerId: message }).finish(),
						});
					},
				},
				[RFP['getTradingProviders']]: {
					map: (message: null | undefined) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_TR_PROVIDERS,
							data: ProtobufSchema.types.RequestTradingProviders.encode({}).finish(),
						});
					},
				},
				[RFP['manageHistory']]: {
					map: (message: HistorySubscription) => {
						let data;
						if (message.startTime && message.endTime) {
							data = ProtobufSchema.types.RequestHistory.encode({
								reqId: message.reqId,
								action: ProtobufSchema.types.RequestHistory.Action.SNAPSHOT,
								feedId: message.feedId,
								code: message.code,
								priceType: ProtobufSchema.mapHistoryPriceType(message.priceType),
								timescale: ProtobufSchema.mapHistoryTimescale(message.timescale),
								startTime: message.startTime,
								endTime: message.endTime,
							});
						} else {
							data = ProtobufSchema.types.RequestHistory.encode({
								reqId: message.reqId,
								action:
									message.action === SubscriptionAction.Subscribe
										? ProtobufSchema.types.RequestHistory.Action.SUBSCRIBE
										: ProtobufSchema.types.RequestHistory.Action.UNSUBSCRIBE,
								feedId: message.feedId,
								code: message.code,
								priceType: ProtobufSchema.mapHistoryPriceType(message.priceType),
								timescale: ProtobufSchema.mapHistoryTimescale(message.timescale),
								countBars: message.tickCount || 1024,
							});
						}

						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_HISTORY,
							data: data.finish(),
						});
					},
				},
				[RFP['getTTSessionTimes']]: {
					map: (message: RFPRequestTTSessionTimes) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_TT_SESSION_TIMES,
							data: ProtobufSchema.types.RequestTTSessionTimes.encode({
								code: message.code,
							}).finish(),
						});
					},
				},
				[RFP['manageHistoryDate']]: {
					map: (message: HistoryRequestStartDate) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_HS_START_DATE,
							data: ProtobufSchema.types.RequestHistoryStartDate.encode({
								feedId: message.feedId,
								code: message.code,
								intervalMin: message.timeInterval,
							}).finish(),
						});
					},
				},
				[RFP['manageQuotes']]: {
					map: (message: QuoteSubscription) => {
						const subscribe = message.action === SubscriptionAction.Subscribe;
						let codes: string[] = [];
						message.marketItems.forEach((marketItem) => {
							if (subscribe) {
								if (marketItem.subscriptionCounter === 0) {
									codes.push(marketItem.code);
								}
								marketItem.subscriptionCounter++;
								// console.log(`+ RT code=${marketItem.code} subCount=${marketItem.subscriptionCounter}`);
							} else {
								marketItem.subscriptionCounter = Math.max(0, marketItem.subscriptionCounter - 1);
								if (marketItem.subscriptionCounter === 0) {
									codes.push(marketItem.code);
								}
								// console.log(`- RT code=${marketItem.code} subCount=${marketItem.subscriptionCounter}`);
							}
						});

						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_QUOTES,
							data: ProtobufSchema.types.RequestQuotes.encode({
								action: subscribe
									? ProtobufSchema.types.RequestQuotes.Action.SUBSCRIBE
									: ProtobufSchema.types.RequestQuotes.Action.UNSUBSCRIBE,
								feedId: message.feedId,
								code: codes,
							}).finish(),
						});
					},
				},
				[RFP['manageTradingNews']]: {
					map: (message: NewsSubscriptionRequest) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_NEWS,
							data: ProtobufSchema.types.RequestNews.encode({
								action:
									message.action === SubscriptionAction.Subscribe
										? ProtobufSchema.types.RequestNews.Action.SUBSCRIBE
										: ProtobufSchema.types.RequestNews.Action.UNSUBSCRIBE,
								msgCount: message.msgCount,
							}).finish(),
						});
					},
				},
				[RFP['manageMarketWinNews']]: {
					map: (message: MarketWinNewsSubscriptionRequest) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_MKTWIN_NEWS,
							data: ProtobufSchema.types.RequestMarketWinNews.encode({
								subscribe: message.subscribe,
							}).finish(),
						});
					},
				},
				[RFP['tradingAccountLogin']]: {
					map: (message: TradingAccountLoginRequest) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_LOGIN_TR_ACCOUNT,
							data: ProtobufSchema.types.RequestLoginTradingAccount.encode({
								acctId: message.tradingAccountId,
							}).finish(),
						});
					},
				},
				[RFP['tradingAccountLogout']]: {
					map: (message: TradingAccountLogoutRequest) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_LOGOUT_TR_ACCOUNT,
							data: ProtobufSchema.types.RequestLogoutTradingAccount.encode({
								acctId: message.acct_id,
							}).finish(),
						});
					},
				},
				[RFP['requestTTWatchList']]: {
					map: (message: RequestTTWatchList) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_TT_WATCH_LIST,
							data: ProtobufSchema.types.RequestTTWatchList.encode({
								isoCode: message.isoCode,
							}).finish(),
						});
					},
				},
				[RFP['requestRfpServerNames']]: {
					map: (message: RequestRfpServerNames) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_RFP_SERVERS,
							data: ProtobufSchema.types.RequestRfpServers.encode({
								reqId: message.reqId,
								acctIds: message.acctIds,
							}).finish(),
						});
					},
				},
				[RFP['requestTTMarginRules']]: {
					map: (message: RequestTTMarginRules) => {
						console.info("[RFP['requestTTMarginRules']]", { message });
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_TT_MARGIN_RULES,
							data: ProtobufSchema.types.RequestTTMarginRules.encode({
								accountId: message.accountId,
							}).finish(),
						});
					},
				},
				[RFP['requestFutureMargin']]: {
					map: (message: RequestFutureMargin) => {
						console.info("[RFP['requestFutureMargin']]", { message });

						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_FUTURE_MARGIN,
							data: ProtobufSchema.types.RequestFutureMargin.encode({
								reqId: message.reqId,
								amount: message.amount,
								acctId: message.acctId,
								symbol: message.symbol,
								side: message.side,
							}).finish(),
						});
					},
				},
				[RFP['queueTierInfo']]: {
					map: (message: AccountTierRequest) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_ACCT_TIER_INFO,
							data: ProtobufSchema.types.RequestAccountTierInfo.encode({
								ttAcctId: message.accountId,
							}).finish(),
						});
					},
				},
				[RFP['closeTrade']]: {
					map: (message: CloseTradeRequest) => {
						console.log(`CloseTradeRequest ${message.posId}`);
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_CLOSE_TRADE,
							data: ProtobufSchema.types.RequestCloseTrade.encode({
								acctId: message.trAccountId,
								posId: `${message.posId}`,
							}).finish(),
						});
					},
				},
				[RFP['editOrder']]: {
					map: (message: EditOrderRequest) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_EDIT_ORDER,
							data: ProtobufSchema.types.RequestEditOrder.encode({
								reqId: `${message.reqId}`,
								acctId: message.acctId,
								posId: `${message.posId}`,
								stopLevel: message.stopLevel ? `${message.stopLevel}` : null,
								limitLevel: message.limitLevel ? `${message.limitLevel}` : null,
								priceLevel: message.priceLevel ? `${message.priceLevel}` : null,
								expiration: message.expiration ? ProtobufSchema.mapOrderExpiration(message.expiration) : null,
								trailingStop: message.trailingStop ? `${message.trailingStop}` : null,
							}).finish(),
						});
					},
				},
				[RFP['editPosition']]: {
					map: (message: EditPositionRequest) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_EDIT_POS,
							data: ProtobufSchema.types.RequestEditPosition.encode({
								reqId: `${message.reqId}`,
								acctId: message.acctId,
								posId: `${message.posId}`,
								stopLevel: message.stopLevel ? `${message.stopLevel}` : null,
								limitLevel: message.limitLevel ? `${message.limitLevel}` : null,
								trailingStop: message.trailingStop ? `${message.trailingStop}` : null,
							}).finish(),
						});
					},
				},
				[RFP['newOrder']]: {
					map: (message: NewOrderRequest) => {
						const data = {
							reqNewTrade: {
								reqId: `${message.reqId}`,
								acctId: message.acctId,
								feedId: message.feedId,
								code: message.code,
								tradeSize: `${message.tradeSize}`,
								tradeSide: ProtobufSchema.mapTradeSide(message.tradeSide as any),
								tradeType: ProtobufSchema.mapTradeType(message.tradeType as any),
								stopLevel: message.stopLevel ? `${message.stopLevel}` : null,
								limitLevel: message.limitLevel ? `${message.limitLevel}` : null,
								trailingStop: message.trailingStop ? `${message.trailingStop}` : null,
							},
							priceLevel: message.priceLevel ? `${message.priceLevel}` : null,
							expiration: message.expiration ? ProtobufSchema.mapOrderExpiration(message.expiration) : null,
						};
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_NEW_ORDER,
							data: ProtobufSchema.types.RequestNewOrder.encode(data).finish(),
						});
					},
				},
				[RFP['newTrade']]: {
					map: (message: NewPositionRequest) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_NEW_TRADE,
							data: ProtobufSchema.types.RequestNewTrade.encode({
								reqId: `${message.reqId}`,
								acctId: message.acctId,
								feedId: message.feedId,
								code: message.code,
								tradeSize: `${message.tradeSize}`,
								tradeSide: ProtobufSchema.mapTradeSide(message.tradeSide as any),
								stopLevel: message.stopLevel ? `${message.stopLevel}` : null,
								limitLevel: message.limitLevel ? `${message.limitLevel}` : null,
								trailingStop: message.trailingStop ? `${message.trailingStop}` : null,
							}).finish(),
						});
					},
				},
				[RFP['partialClose']]: {
					map: (message: PartialCloseRequest) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_CLOSE_POS_PART,
							data: ProtobufSchema.types.RequestCloseTradePartial.encode({
								reqCloseTrade: {
									posId: `${message.posId}`,
									acctId: message.trAccountId,
								},
								amount: message.amount,
							}).finish(),
						});
					},
				},
				[RFP['getMarketWatchIntervals']]: {
					map: (message: null | undefined) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_MKTW_INTERVALS,
							data: ProtobufSchema.types.RequestMarketWatchIntervals.encode({}).finish(),
						});
					},
				},
				[RFP['getMarketWatchCategories']]: {
					map: (message: null | undefined) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_MKTW_CATEGORIES,
							data: ProtobufSchema.types.RequestMarketWatchCategories.encode({}).finish(),
						});
					},
				},
				[RFP['getMarketWatchSnapshots']]: {
					map: (message: MarketWatchSnapshotsRequest) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_MKTW_SNAPSHOTS,
							data: ProtobufSchema.types.RequestMarketWatchSnapshots.encode({
								categoryName: message.categoryName,
								intervalLength: message.intervalLength,
							}).finish(),
						});
					},
				},
				[RFP['getMarketWatchSubscription']]: {
					map: (message: MarketWatchSubscriptionRequest) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_MKTW_SUBSCRIPTION,
							data: ProtobufSchema.types.RequestMarketWatchSubscription.encode({
								code: message.code,
								intervalLength: message.intervalLength,
								subscribe: message.subscribe,
							}).finish(),
						});
					},
				},
				[RFP['getMarketWatchDynamicSubscription']]: {
					map: (message: MarketWatchDynamicSubscriptionRequest) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_MKTW_DYNAMIC_SUBSCRIPTION,
							data: ProtobufSchema.types.RequestMarketWatchDynamicSubscription.encode({
								category: message.category,
								intervalLength: message.intervalLength,
								subscribe: message.subscribe,
							}).finish(),
						});
					},
				},
				[RFP['updateQuantityType']]: {
					map: (message: QuantityTypeUpdate) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_UPD_TR_ACCOUNT,
							data: ProtobufSchema.types.VTUpdateTradingAccount.encode({
								acctId: message.accId,
								quantityType: message.quantityType,
							}).finish(),
						});
					},
				},
				[RFP['queuePreviousDayClosePrice']]: {
					map: (message: PreviousDayClosePriceRequest) => {
						return ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_PREV_DAY_CLOSE_PRICE,
							data: ProtobufSchema.types.RequestPreviousDayClosePrice.encode({
								code: message.code,
							}).finish(),
						});
					},
				},
			},
			subscribe: {
				[RFP['tfboLoginResponse']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.TFBO_LOGIN,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as TfboLoginResponse;
					},
				},
				[RFP['TTWatchList']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.TT_WATCH_LIST,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as TTWatchList;
					},
				},
				[RFP['rfpServerNames']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.RFP_SERVERS,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as RfpServerNames;
					},
				},
				[RFP['TTMarginRules']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.TT_MARGIN_RULES,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as TTMarginRules;
					},
				},
				[RFP['futureMargin']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.FUTURE_MARGIN,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as FutureMargin;
					},
				},
				[RFP['tfboEndpointResponse']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.TFBO_ENDPOINT,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as TfboEndpointResponse;
					},
				},
				[RFP['pong']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.PONG,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as Pong;
					},
				},
				[RFP['queueFeedIds']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.FEED_INFO,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as FeedInfos;
					},
				},
				[RFP['queueHistoricalDataUpdates']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.HS_TICK,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as HistoryTickItems;
					},
				},
				[RFP['queueMarketItems']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.MKT_ITEM,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as MarketItemsRecord;
					},
				},
				[RFP['queueMarketItemsInfo']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.MKT_ITEM_INFO,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as MarketItemsInfoRecord;
					},
				},
				[RFP['queueQuotes']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.QUOTE,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as PriceQuote;
					},
				},
				[RFP['queueSessionTimes']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.TT_SESSION_TIMES,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as RFPSessionTimes;
					},
				},
				[RFP['queueTradingAccountsError']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.TR_ACCOUNTS_ERROR,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as RFPTradingAccountsError;
					},
				},
				[RFP['queueTradingAccount']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.TR_ACCOUNT,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as TradingAccount;
					},
				},
				[RFP['queueTradingProvider']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.TR_PROVIDER,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as TradingProvider;
					},
				},
				[RFP['queueTradingAccountLogin']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.TR_ACCOUNT_LOGIN_INFO,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as TradingAccountLogin;
					},
				},
				[RFP['queueTierInfo']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.ACCT_TIER_INFO,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as AccountTierResponse;
					},
				},
				[RFP['queueTradingAccountInstruments']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.TR_ACCOUNT_INSTRUMENTS,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as TradingInstruments;
					},
				},
				[RFP['queueTradingNews']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.NEWS_ARTICLE,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as RFPNews;
					},
				},
				[RFP['queueMarketWinNews']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.MKTWIN_NEWS,
					map: (message: IWrapperMessage): IterableIterator<RFPNews> => {
						return ProtobufSchema.decodeMessage(message) as unknown as IterableIterator<RFPNews>;
					},
				},
				[RFP['queueTradeEvent']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.TRADE_EVENT,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as TradeEvent;
					},
				},
				[RFP['queueHistorySize']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.HS_SIZE,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as HistorySize;
					},
				},
				[RFP['queueHistoryStartDate']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.HS_START_DATE,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as HistoryTimescaleStartDate;
					},
				},
				[RFP['queueTradeEventExt']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.TRADE_EVENT_EXT,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as TradeEvent;
					},
				},
				[RFP['queueTradingPositionUpdates']]: {
					commandId: [
						ProtobufSchema.types.WrapperMessage.Command.POSITION,
						ProtobufSchema.types.WrapperMessage.Command.POSITIONS,
					],
					map: (message: IWrapperMessage) => {
						//return ProtobufSchema.decodeMessage(message) as TradingPosition | IterableIterator<TradingPosition>;
						let positions: RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.IPosition[] = [];
						if (message.commandId === ProtobufSchema.types.WrapperMessage.Command.POSITIONS) {
							positions = ProtobufSchema.types.Positions.decode(message.data!).position;
						} else {
							positions = [ProtobufSchema.types.Position.decode(message.data!)];
						}

						let isJapaneseUser = false;
						const appContextProvider = Resolver.resolve(AppContextProvider);
						if (appContextProvider) {
							isJapaneseUser = appContextProvider.isJapanAccount;
						}

						function floorValueIfNeedIt(val: number | null | undefined) {
							if (val) {
								return isJapaneseUser ? Math.floor(val) : val;
							}
							return val;
						}

						return positions
							.map((value) => {
								let commission = value.commission ? floorValueIfNeedIt(value.commission) : 0;
								let swap = value.swap ? floorValueIfNeedIt(value.swap) : 0;
								let grossProfit = floorValueIfNeedIt(value.grossProfit);
								// Calc net profit
								let netProfit: number | undefined = undefined;
								if (value.state === ProtobufSchema.types.Position.PositionState.CLOSED) {
									netProfit = (grossProfit || 0) + (commission || 0) + (swap || 0);
								}

								return {
									dataObjectType: RFPDataObjectType.TradingPosition,
									aId: value.acctId,
									state: Object.keys(ProtobufSchema.types.Position.PositionState).find(
										(key) =>
											ProtobufSchema.types.Position.PositionState[
												key as keyof typeof ProtobufSchema.types.Position.PositionState
											] == value.state
									) as TradingPosition['state'],
									side: Object.keys(ProtobufSchema.types.Position.PositionSide).find(
										(key) =>
											ProtobufSchema.types.Position.PositionSide[
												key as keyof typeof ProtobufSchema.types.Position.PositionSide
											] == value.side
									) as TradingPosition['side'],
									code: value.code || '',
									f: value.feedId || '',
									type: Object.keys(ProtobufSchema.types.Position.PositionType).find(
										(key) =>
											ProtobufSchema.types.Position.PositionType[
												key as keyof typeof ProtobufSchema.types.Position.PositionType
											] == value.type
									) as TradingPosition['type'],
									oP: value.openPrice,
									cP: value.closePrice,
									prc: value.price,
									comm: commission,
									swap: swap,
									qty: value.quantity,
									oT: value.openTime != null ? value.openTime.valueOf() : null,
									cT: value.closeTime != null ? value.closeTime.valueOf() : null,
									eT: value.expirationTime != null ? value.expirationTime.valueOf() : null,
									sl: value.stopLoss,
									tp: value.takeProfit,
									posId: value.id != null ? Number.parseFloat(value.id) : null,
									dec: value.decPrec,
									grossProfit: grossProfit,
									// Net profit will be calc here if the position is closed
									netProfit: floorValueIfNeedIt(netProfit),
									dividend: value.dividend,
									additionalSubscriptionPair: new Set<String>(),
									comment: value.comment,
									openAskPrice: value.openAskPrice,
									openBidPrice: value.openBidPrice,
									closeAskPrice: value.closeAskPrice,
									closeBidPrice: value.closeBidPrice,
									trailingStop: value.trailingStop,
								} as TradingPosition;
							})
							.values();
					},
				},
				[RFP['queueInfos']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.CMD_INFO,
					map: (message: IWrapperMessage) => {
						const data = ProtobufSchema.types.ClientCommandResult.decode(message.data!);
						return data;
					},
				},
				[RFP['queueErrors']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.ERROR,
					map: (message: IWrapperMessage) => {
						const data = ProtobufSchema.types.SessionError.decode(message.data!);
						return data;
					},
				},
				[RFP['queueMarketWatchIntervals']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.MKTW_INTERVALS,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as MarketWatchIntervals;
					},
				},
				[RFP['queueMarketWatchCategories']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.MKTW_CATEGORIES,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as MarketWatchCategories;
					},
				},
				[RFP['queueMarketWatchItem']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.MKTW_ITEM,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as MarketWatchItem;
					},
				},
				[RFP['queueMarketWatchItems']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.MKTW_ITEMS,
					map: (message: IWrapperMessage) => {
						return ProtobufSchema.decodeMessage(message) as MarketWatchItems;
					},
				},
				[RFP['queuePreviousDayClosePrice']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.PREV_DAY_CLOSE_PRICE,
					map: (message: IWrapperMessage) => {
						const data = ProtobufSchema.types.PreviousDayClosePrice.decode(message.data!);
						return {
							feedId: data.feedId,
							item: data.item,
						};
					},
				},
				[RFP['queueTierUpdateInfo']]: {
					commandId: ProtobufSchema.types.WrapperMessage.Command.UPDATE_ACCT_TIER,
					map: (message: IWrapperMessage) => {
						const data = ProtobufSchema.types.TierUpdatePush.decode(message.data!);
						//this is only received when the account is logged in
						return {
							accountId: data.acctId as number,
							tierNum: data.tier,
						};
					},
				},
			},
		};
		return messageMapper;
	});

	public static decodeMessage(message: IWrapperMessage) {
		if (message.data) {
			switch (message.commandId) {
				case ProtobufSchema.types.WrapperMessage.Command.TT_WATCH_LIST: {
					const data = ProtobufSchema.types.TTWatchList.decode(message.data);

					return {
						isoCode: data.isoCode,
						items: data.item.map((item) => {
							return {
								title: item.title,
								order: item.order,
								codes_csv: item.codesCsv,
							};
						}),
					} as unknown as TTWatchList;
				}
				case ProtobufSchema.types.WrapperMessage.Command.RFP_SERVERS: {
					const data = ProtobufSchema.types.RfpServers.decode(message.data);
					return {
						reqId: data.reqId,
						rfpServerNames: data.rfpServer.map((item) => {
							return {
								rfpDnsName: item.rfpDnsName,
								rfpServerName: item.rfpServerName,
								ttAccountId: item.ttAccountId,
							};
						}),
					} as unknown as RfpServerNames;
				}
				case ProtobufSchema.types.WrapperMessage.Command.TT_MARGIN_RULES: {
					const data = ProtobufSchema.types.TTMarginRules.decode(message.data);

					return {
						accountId: data.accountId,
						items: data.marginRules.map((marginRule) => {
							const {
								id,
								name,
								instruments,
								tierValues,
								tierRequirements,
								scheduleEnabled,
								dayIndexesSundayFirst,
								dayValues,
							} = marginRule;

							return {
								id,
								name,
								instruments,
								tierValues,
								tierRequirements,
								scheduleEnabled,
								dayIndexesSundayFirst,
								dayValues,
							};
						}),
					} as unknown as TTMarginRules;
				}
				case ProtobufSchema.types.WrapperMessage.Command.FUTURE_MARGIN: {
					const data = ProtobufSchema.types.FutureMargin.decode(message.data);
					console.info('FUTURE_MARGIN -> ', { data });
					return {
						margin: data.margin ?? 0,
						reqId: data.reqId,
					} as unknown as FutureMargin;
				}
				case ProtobufSchema.types.WrapperMessage.Command.TT_SESSION_TIMES: {
					const data = ProtobufSchema.types.TTSessionTimes.decode(message.data);
					return {
						dataObjectType: RFPDataObjectType.TTSessionTimes,
						serverTimeZone: data.serverTimeZone,
						symbol: data.symbol,
						trading: data.trading,
					} as RFPSessionTimes;
				}
				case ProtobufSchema.types.WrapperMessage.Command.HS_START_DATE: {
					const data = ProtobufSchema.types.HistoryStartDate.decode(message.data);
					console.debug(`HS_START_DATE: ${data.time}`);

					return {
						dataObjectType: RFPDataObjectType.HistoryTimescaleStartDate,
						feedId: data.feedId,
						code: data.code,
						timeInterval: data.intervalMin,
						time: data.time,
					} as HistoryTimescaleStartDate;
				}
				case ProtobufSchema.types.WrapperMessage.Command.TFBO_LOGIN: {
					const data = ProtobufSchema.types.TfboLoginResponse.decode(message.data);
					return {
						dataObjectType: RFPDataObjectType.TfboLoginResponse,
						success: data.success,
						error: data.error,
						tfbo_session_id: data.tfboSessionId,
						tfbo_token: data.tfboToken,
					} as TfboLoginResponse;
				}
				case ProtobufSchema.types.WrapperMessage.Command.TFBO_ENDPOINT: {
					const data = ProtobufSchema.types.TfboEndpointResponse.decode(message.data);
					return {
						dataObjectType: RFPDataObjectType.TfboEndpointResponse,
						restApiURL: data.restApiURL,
						uploadDocsURL: data.uploadDocsURL,
						exchangeRateURL: data.exchangeRateURL,
					} as TfboEndpointResponse;
				}
				case ProtobufSchema.types.WrapperMessage.Command.REQ_QUOTES: {
					const data = ProtobufSchema.types.RequestQuotes.decode(message.data);
					return {
						action:
							data.action === ProtobufSchema.types.RequestQuotes.Action.SUBSCRIBE
								? ProtobufSchema.types.RequestQuotes.Action.SUBSCRIBE
								: ProtobufSchema.types.RequestQuotes.Action.UNSUBSCRIBE,
						feedId: data.feedId,
						code: data.code,
					};
				}
				case ProtobufSchema.types.WrapperMessage.Command.PONG: {
					return {
						dataObjectType: RFPDataObjectType.Pong,
					} as Pong;
				}
				case ProtobufSchema.types.WrapperMessage.Command.FEED_INFO: {
					const data = ProtobufSchema.types.FeedInfos.decode(message.data);
					let feeds = data.feedInfo.map((value) => {
						return {
							dataObjectType: RFPDataObjectType.FeedInfos,
							feedID: value.feedId || '',
							initialGroup: value.initialGroup as unknown as null,
							name: value.name || '',
							variant: value.variant as unknown as null,
						} as FeedInfo;
					});

					const feedInfos = {
						dataObjectType: RFPDataObjectType.FeedInfos,
						feedIds: feeds,
					} as FeedInfos;

					return feedInfos;
				}
				case ProtobufSchema.types.WrapperMessage.Command.HS_TICK: {
					const data = ProtobufSchema.types.HistoryTickItems.decode(message.data);
					const firstItem = data.historyTick[0];
					const reqId = firstItem.reqId || '';
					const feedId = firstItem.feedId || '';
					const code = firstItem.code || '';
					const timescale = firstItem.timescale as HistoryTick['timescale'];

					let historyTicks = data.historyTick.map((value) => {
						return {
							dataObjectType: RFPDataObjectType.HistoryTick,
							reqId: value.reqId || '',
							feedId: value.feedId || '',
							code: value.code || '',
							continuousFlag: value.contFlag || 0,
							timescale: value.timescale as HistoryTick['timescale'],
							open: value.open || 0,
							high: value.high || 0,
							low: value.low || 0,
							close: value.close || 0,
							closeTime: (value.time || 0) * 1000,
							volume: (value.volume || 0) as number,
						} as HistoryTick;
					});

					// Calc open time
					historyTicks.forEach((item) => {
						item.openTime = ChartUtils.getOpenTime(item, ChartUtils.getRFPTimingInterval(item.timescale));
					});

					const historyTickItems = {
						reqId: reqId,
						feedId: feedId,
						code: code,
						timeScale: timescale,
						dataObjectType: RFPDataObjectType.HistoryTickItems,
						historyTicks: historyTicks,
					} as HistoryTickItems;

					return historyTickItems;
				}
				case ProtobufSchema.types.WrapperMessage.Command.MKT_ITEM: {
					const data = ProtobufSchema.types.MarketItems.decode(message.data);

					let marketItems = data.marketItem.map((value) => {
						const displayName = value.exchangeTicker ? value.exchangeTicker : value.code;

						return {
							bCcy: value.baseCcy || '',
							ccy: value.ccy || '',
							code: value.code || '',
							decPrec: value.decimalPrecision || 0,
							displayName: displayName,
							fullName: value.name || '',
							feedId: value.feedId || '',
							flags: value.flags || '',
							grp: value.groupCode || '',
							isin: value.isin || '',
							marginType: value.marginType || '',
							pipSizeH: value.pipSizeH ? `${value.pipSizeH}` : '',
							qCcy: value.ccy || '',
							tickSize: value.tickSize || 0,
							tradable: value.tradable,
							exchangeCountryCode: value.exchangeCountryCode,
							sector: value.sector,
							exchangeTicker: value.exchangeTicker,
							minTier: value.minTier || 0,
							watchlistRank: value.wlRank || 0,

							subscriptionCounter: 0,
							accountMarketType: value.accountType,
						} as MarketItem;
					});

					const marketItemsRecord = {
						dataObjectType: RFPDataObjectType.MarketItemsRecord,
						marketItems: marketItems,
					} as MarketItemsRecord;

					return marketItemsRecord;
				}
				case ProtobufSchema.types.WrapperMessage.Command.MKT_ITEM_INFO: {
					const data = ProtobufSchema.types.MarketItemsInfo.decode(message.data);

					let marketItemsInfo = data.marketItem.map((value) => {
						return {
							dataObjectType: RFPDataObjectType.MarketItemInfo,
							code: value.code || '',
							decimalPrecision: value.decimalPrecision || '',
							feedId: value.feedId || '',
							formatLength: value.formatLength || '',
							formatPosition: value.formatPosition || '',
							pipSize: value.pipSize || '',
							tickSize: value.tickSize || '',
						} as MarketItemInfo;
					});

					return {
						dataObjectType: RFPDataObjectType.MarketItemsInfoRecord,
						marketItemsInfo: marketItemsInfo,
					} as MarketItemsInfoRecord;
				}
				case ProtobufSchema.types.WrapperMessage.Command.QUOTE: {
					const data = ProtobufSchema.types.Quote.decode(message.data);
					return {
						dataObjectType: RFPDataObjectType.PriceQuote,
						a: data.ask,
						b: data.bid,
						c: data.code,
						f: data.feedId,
						h: data.high,
						l: data.low,
						t: data.time * 1000,
					} as PriceQuote;
				}
				case ProtobufSchema.types.WrapperMessage.Command.TR_ACCOUNTS_ERROR: {
					const data = ProtobufSchema.types.TradingAccountsError.decode(message.data);
					return {
						dataObjectType: RFPDataObjectType.TradingAccountsError,
						providerId: data.providerId,
						errorMessage: data.errorMessage,
					} as RFPTradingAccountsError;
				}
				case ProtobufSchema.types.WrapperMessage.Command.TR_ACCOUNT: {
					const data = ProtobufSchema.types.TradingAccount.decode(message.data);

					// We can think replace this and use the accountMarketType value that comes with the account data to know if is Japan account (Japan or JapanSpread)
					// but, at beginning some messages are received without any accountMarketType information, maybe because the account is not logged in yet
					// const isJapanAccount = [AccountMarketType.Japan, AccountMarketType.JapanSpread].includes(data.accountMarketType);

					let isJapanAccount = false;
					// Get app context provider
					const appContextProvider = Resolver.resolve(AppContextProvider);
					if (appContextProvider) {
						isJapanAccount = appContextProvider.isJapanAccount;
					}

					// this is a workaround because PROD backend is still sending the accountMarketType for Japan Subscription as 0 instead of 3
					// we will replace temporarily the accountMarketType for Japan Accounts
					const accountMarketType = isJapanAccount && data.accountMarketType === 0 ? 3 : data.accountMarketType;

					return {
						dataObjectType: RFPDataObjectType.TradingAccount,
						accountType: Object.keys(ProtobufSchema.types.TradingAccount.AccountType).find(
							(key) =>
								ProtobufSchema.types.TradingAccount.AccountType[
									key as keyof typeof ProtobufSchema.types.TradingAccount.AccountType
								] === data.accountType
						) as TradingAccount['accountType'],
						balance: data.balance,
						baseCurrency: data.baseCcy,
						credit: data.credit,
						equity: data.equity,
						freeMargin: data.freeMargin,
						id: data.id.valueOf(),
						leverage: data.leverage,
						marginLevel: data.marginLevel,
						preferredFeed: data.preferredFeed,
						providerAccountId: data.providerAccountId,
						accountNumber: +data.providerAccountId,
						providerId: data.providerId.valueOf(),
						// if this is Japanese user, must ceil the usedMargin
						usedMargin: isJapanAccount ? Math.ceil(data.usedMargin) : data.usedMargin,
						marginCalculationType: data.marginCalculationType,
						activePositions: {},
						closedPositions: {},
						grossProfit: 0,
						netProfit: 0,
						isJapanAccount: isJapanAccount,
						enabled: data.enabled,
						accountMarketType, // This value is working well now, is received from WSS properly.
					} as TradingAccount;
				}
				case ProtobufSchema.types.WrapperMessage.Command.TR_PROVIDER: {
					const data = ProtobufSchema.types.TradingProvider.decode(message.data);
					return {
						dataObjectType: RFPDataObjectType.TradingProvider,
						description: data.description,
						displayName: data.displayName,
						feedID: data.feedId,
						id: data.id.valueOf(),
						uniqueName: data.uniqueName,
					} as TradingProvider;
				}
				case ProtobufSchema.types.WrapperMessage.Command.TR_ACCOUNT_LOGIN_INFO: {
					const data = ProtobufSchema.types.TradingAccountLoginInfo.decode(message.data);
					return {
						dataObjectType: RFPDataObjectType.TradingAccountLogin,
						accountId: data.acctId.valueOf(),
						error: data.errorMessage,
						loggedOn: data.loggedOn,
					} as TradingAccountLogin;
				}
				case ProtobufSchema.types.WrapperMessage.Command.ACCT_TIER_INFO: {
					const data = ProtobufSchema.types.AccountTierInfo.decode(message.data);
					return {
						accountId: data.ttAcctId.valueOf(),
						error: data.error,
						enabled: data.enabled,
						tier: data.tier,
						usedUnits: data.usedUnits,
						availableUnits: data.availableUnits,
						maxUnits: data.maxUnits,
					} as AccountTierResponse;
				}
				case ProtobufSchema.types.WrapperMessage.Command.TR_ACCOUNT_INSTRUMENTS: {
					const data = ProtobufSchema.types.TradingInstruments.decode(message.data);

					// TODO: Check why we are receiving this data 2 times in the next 2 millisecond
					const tradingInstruments: TradingInstruments = {
						dataObjectType: RFPDataObjectType.TradingInstruments,
						account: data.acctId.valueOf() as number,
						instruments: {},
					};

					ProtobufSchema._rshMap = new Map();

					data.tradingInstrument
						.filter((value) => value.code !== null)
						.forEach((value) => {
							const minLot = value.minLot || 0;
							const maxLot = value.maxLot || 0;
							const roundLot = value.roundLot || 0;
							const stepLot = value.stepLot || 0;
							const maxAmount = maxLot * roundLot;
							const minAmount = minLot * roundLot;
							const stepAmount = stepLot * roundLot;

							//const contractSize = value.roundLot != null && value.roundLot > 0 ? value.roundLot : 1
							tradingInstruments.instruments[value.code!] = {
								amtDPrec: value.amountDecPrec || 0,
								ccy: value.ccy || '',
								code: value.code!,
								grp: value.group || '',
								lotDPrec: value.lotDecPrec || 0,
								maxAmount: maxAmount,
								minAmount: minAmount,
								stepAmount: stepAmount,
								maxL: maxLot,
								minL: minLot,
								pipPos: value.pipPos || 0,
								rndLot: roundLot,
								rshL: value.rshLong || 0,
								rshS: value.rshShort || 0,
								sDig: value.symbolDigits || 0,
								stpL: stepLot,
								swpL: value.swapLong || 0,
								swpS: value.swapShort || 0,
								trPeriod: value.tradingPeriod || '',
								marginReq: value.marginRequirement || 0,
								threeDaySwap: value.threeDaySwap || '',
								mtReqMx: value.mtReqMx || 0,
								mtValOne: value.mtValOne || 0,
								mtReqOne: value.mtReqOne || 0,
								mtValTwo: value.mtValTwo || 0,
								mtReqTwo: value.mtReqTwo || 0,
								mtValThree: value.mtValThree || 0,
								mtReqThree: value.mtReqThree || 0,
								mtValFour: value.mtValFour || 0,
								mtReqFour: value.mtReqFour || 0,
								mtValFive: value.mtValFive || 0,
								mtReqFive: value.mtReqFive || 0,
								mtValSix: value.mtValSix || 0,
								mtReqSix: value.mtReqSix || 0,
							};
							ProtobufSchema._rshMap.set(value.code!, {
								rshL: value.rshLong || 0,
								rshS: value.rshShort || 0,
								pipPos: value.pipPos || 0,
							});
						});
					return tradingInstruments;
				}
				case ProtobufSchema.types.WrapperMessage.Command.NEWS_ARTICLE: {
					const data = ProtobufSchema.types.NewsArticle.decode(message.data);
					return {
						dataObjectType: RFPDataObjectType.RFPNews,
						body: data.body,
						country: data.country,
						currencies: data.newsCurrency.map((value) => value.currency),
						headline: data.headline,
						id: data.id,
						newsCategoryId: data.newsCategoryId,
						newsTypeId: data.newsTypeId,
						revision: data.revision,
						time: data.time,
						source: data.source,
					} as RFPNews;
				}
				case ProtobufSchema.types.WrapperMessage.Command.MKTWIN_NEWS: {
					const data = ProtobufSchema.types.MarketWinNews.decode(message.data);

					return data.entry.map(({ id, source, timestamp, title, codeId, priority, type, content }) => {
						// The 'Japanese' news message format is then 'cast'/'mapped' on our FE
						// in order to fit the general RFP news structure expected by the UI
						return {
							body: content,
							headline: title,
							id: Number(id),
							// timestamp comes in seconds instead of the standard milliseconds
							// so we need to multiply by 1000 in order to show a valid date on UI
							time: (Number(timestamp) * 1000 || 0) as unknown as Long,
							source: source,
						} as unknown as RFPNews;
					}) as unknown as IterableIterator<RFPNews>;
				}
				case ProtobufSchema.types.WrapperMessage.Command.TRADE_EVENT: {
					const data = ProtobufSchema.types.TradeEvent.decode(message.data);
					const messageType = Object.keys(ProtobufSchema.types.TradeEventMsgType).find(
						(key) =>
							ProtobufSchema.types.TradeEventMsgType[key as keyof typeof ProtobufSchema.types.TradeEventMsgType] ==
							data.msgType
					) as TradeEvent['messageType'];
					const tradeEvent = {
						dataObjectType: RFPDataObjectType.TradeEvent,
						acctId: data.acctId,
						time: data.time.valueOf(),
						eventType: data.eventType,
						message: data.message,
						messageType: messageType,
					} as TradeEvent;
					tradeEvent.events =
						data.eventType === ProtobufSchema.types.TradeEvent.EventType.EVT_TYPE_ERROR
							? [{ message: data.message, error: data.message, messageType: messageType }]
							: [{ message: data.message, messageType: messageType }];
					return tradeEvent;
				}
				case ProtobufSchema.types.WrapperMessage.Command.HS_SIZE: {
					const data = ProtobufSchema.types.HistorySize.decode(message.data);
					const historySize: HistorySize = {
						dataObjectType: RFPDataObjectType.HistorySize,
						reqId: data.reqId || '',
						feedId: data.feedId || '',
						code: data.code || '',
						rfpTimingInterval: data.rfpTimingInterval as number,
						duration: data.duration as number,
						size: data.size as number,
					};
					return historySize;
				}
				case ProtobufSchema.types.WrapperMessage.Command.TRADE_EVENT_EXT: {
					const data = ProtobufSchema.types.TradeEventExt.decode(message.data);
					const tradeEvent = {
						dataObjectType: RFPDataObjectType.TradeEvent,
						acctId: data.acctId,
						orderId: data.orderId,
						requestId: data.requestId,
						events: [],
					} as TradeEvent;
					data.eventEntry.forEach((value) => {
						console.debug(`TradeEvent ${value.message}`);
						const messageType = Object.keys(ProtobufSchema.types.TradeEventMsgType).find(
							(key) =>
								ProtobufSchema.types.TradeEventMsgType[key as keyof typeof ProtobufSchema.types.TradeEventMsgType] ==
								value.msgType
						) as TradeEvent['messageType'];
						tradeEvent.events!.push(
							value.eventType === ProtobufSchema.types.TradeEventEntry.EventType.EVT_TYPE_ERROR
								? { message: value.message, error: value.message, messageType: messageType }
								: { message: value.message, messageType: messageType }
						);
					});
					return tradeEvent;
				}
				case (ProtobufSchema.types.WrapperMessage.Command.POSITION,
				ProtobufSchema.types.WrapperMessage.Command.POSITIONS): {
					let positions: RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.IPosition[] = [];
					if (message.commandId === ProtobufSchema.types.WrapperMessage.Command.POSITIONS) {
						positions = ProtobufSchema.types.Positions.decode(message.data).position;
					} else {
						positions = [ProtobufSchema.types.Position.decode(message.data)];
					}

					let isJapaneseUser = false;
					const appContextProvider = Resolver.resolve(AppContextProvider);
					if (appContextProvider) {
						isJapaneseUser = appContextProvider.isJapanAccount;
					}

					return positions
						.map((value) => {
							function floorValueIfNeedIt(val: number | null | undefined) {
								if (val) {
									return isJapaneseUser ? Math.floor(val) : val;
								}
								return val;
							}

							let commission = value.commission ? floorValueIfNeedIt(value.commission) : 0;
							let swap = value.swap ? floorValueIfNeedIt(value.swap) : 0;
							let grossProfit = floorValueIfNeedIt(value.grossProfit);
							// Calc net profit
							let netProfit: number | undefined = undefined;
							if (value.state === ProtobufSchema.types.Position.PositionState.CLOSED) {
								netProfit = (grossProfit || 0) + (commission || 0) + (swap || 0);
							}

							return {
								dataObjectType: RFPDataObjectType.TradingPosition,
								aId: value.acctId,
								state: Object.keys(ProtobufSchema.types.Position.PositionState).find(
									(key) =>
										ProtobufSchema.types.Position.PositionState[
											key as keyof typeof ProtobufSchema.types.Position.PositionState
										] == value.state
								) as TradingPosition['state'],
								side: Object.keys(ProtobufSchema.types.Position.PositionSide).find(
									(key) =>
										ProtobufSchema.types.Position.PositionSide[
											key as keyof typeof ProtobufSchema.types.Position.PositionSide
										] == value.side
								) as TradingPosition['side'],
								code: value.code || '',
								f: value.feedId || '',
								type: Object.keys(ProtobufSchema.types.Position.PositionType).find(
									(key) =>
										ProtobufSchema.types.Position.PositionType[
											key as keyof typeof ProtobufSchema.types.Position.PositionType
										] == value.type
								) as TradingPosition['type'],
								oP: value.openPrice,
								cP: value.closePrice,
								prc: value.price,
								comm: commission,
								swap: swap,
								qty: value.quantity,
								oT: value.openTime != null ? value.openTime.valueOf() : null,
								cT: value.closeTime != null ? value.closeTime.valueOf() : null,
								eT: value.expirationTime != null ? value.expirationTime.valueOf() : null,
								sl: value.stopLoss,
								tp: value.takeProfit,
								posId: value.id != null ? Number.parseFloat(value.id) : null,
								dec: value.decPrec,
								grossProfit: grossProfit,
								// Net profit will be calc here if the position is closed
								netProfit: floorValueIfNeedIt(netProfit),
								dividend: value.dividend,
								additionalSubscriptionPair: new Set<String>(),
								comment: value.comment,
								openAskPrice: value.openAskPrice,
								openBidPrice: value.openBidPrice,
								closeAskPrice: value.closeAskPrice,
								closeBidPrice: value.closeBidPrice,
								trailingStop: value.trailingStop,
							} as TradingPosition;
						})
						.values();
				}
				case ProtobufSchema.types.WrapperMessage.Command.CMD_INFO: {
					const data = ProtobufSchema.types.ClientCommandResult.decode(message.data);
					const cmdResult: ClientCommandResult = {
						dataObjectType: RFPDataObjectType.ClientCommandResult,
						reqId: data.clReqId,
						feedId: data.feedId,
						code: data.code,
						msg: data.msg,
						objId: data.objId,
						topic: data.topic.valueOf(),
						result: data.result.valueOf(),
						subType: data.subType.valueOf(),
					};
					return cmdResult;
				}
				case ProtobufSchema.types.WrapperMessage.Command.ERROR: {
					const data = ProtobufSchema.types.SessionError.decode(message.data);
					const sessionError: SessionError = {
						dataObjectType: RFPDataObjectType.SessionError,
						error: data.error,
					};
					return sessionError;
				}
				case ProtobufSchema.types.WrapperMessage.Command.MKTW_INTERVALS: {
					const data = ProtobufSchema.types.MarketWatchIntervals.decode(message.data);
					const marketWatchIntervals: MarketWatchIntervals = {
						dataObjectType: RFPDataObjectType.MarketWatchIntervals,
						feedId: data.feedId,
						intervalLength: [],
					};

					if (data.intervalLength && Array.isArray(data.intervalLength)) {
						data.intervalLength.forEach((value) => {
							marketWatchIntervals.intervalLength.push(value);
						});
					}
					return marketWatchIntervals;
				}
				case ProtobufSchema.types.WrapperMessage.Command.MKTW_CATEGORIES: {
					const data = ProtobufSchema.types.MarketWatchCategories.decode(message.data);
					const marketWatchCategories: MarketWatchCategories = {
						dataObjectType: RFPDataObjectType.MarketWatchCategories,
						feedId: data.feedId,
						categories: [] as MarketWatchCategory[],
					};

					if (data.category && Array.isArray(data.category)) {
						data.category.forEach((value) => {
							marketWatchCategories.categories.push({
								name: value.name,
								intervalLength: value.intervalLength,
								itemsCount: value.itemsCount,
								dynamic: value.dynamic,
							});
						});
					}
					return marketWatchCategories;
				}
				case ProtobufSchema.types.WrapperMessage.Command.MKTW_ITEM: {
					const data = ProtobufSchema.types.MarketWatchItem.decode(message.data);
					const marketWatchItem: MarketWatchItem = {
						dataObjectType: RFPDataObjectType.MarketWatchItem,
						feedId: data.feedId,
						code: data.code,
						intervalLength: data.intervalLength,
						volatility: data.volatility,
						percentChange: data.percentChange,
						high: data.high,
						low: data.low,
						bid: data.bid,
						ask: data.ask,
					};
					return marketWatchItem;
				}
				case ProtobufSchema.types.WrapperMessage.Command.MKTW_ITEMS: {
					const data = ProtobufSchema.types.MarketWatchItems.decode(message.data);
					const marketWatchItems: MarketWatchItems = {
						dataObjectType: RFPDataObjectType.MarketWatchItems,
						feedId: data.feedId,
						dynamic_category: data.dynamicCategory,
						category: data.category,
						totNumMsgs: data.totNumMsgs,
						msgNum: data.msgNum,
						items: [] as MarketWatchItem[],
					};

					if (data.item && Array.isArray(data.item)) {
						data.item.forEach((value) => {
							marketWatchItems.items.push({
								dataObjectType: RFPDataObjectType.MarketWatchItem,
								feedId: value.feedId,
								code: value.code,
								intervalLength: value.intervalLength,
								volatility: value.volatility,
								percentChange: value.percentChange,
								high: value.high,
								low: value.low,
								bid: value.bid,
								ask: value.ask,
							});
						});
					}
					return marketWatchItems;
				}
				case ProtobufSchema.types.WrapperMessage.Command.PREV_DAY_CLOSE_PRICE: {
					const data = ProtobufSchema.types.PreviousDayClosePrice.decode(message.data);
					return {
						feedId: data.feedId,
						item: data.item,
					} as PreviousDayClosePrice;
				}
			}
		}
		console.debug(`Message decoding should be added commandId=${message.commandId}`);
		return undefined;
	}

	public static get jsonDefinitions(): any {
		return this._jsonDefinitions;
	}

	public static get root(): ReturnType<(typeof ProtobufSchema)['_root']['getValue']> {
		return this._root.getValue();
	}

	public static get types(): ReturnType<(typeof ProtobufSchema)['_types']['getValue']> {
		return this._types.getValue();
	}

	public static get rshMap(): Map<string, IRSHMap> {
		return this._rshMap;
	}

	public static get messageMapper(): ReturnType<(typeof ProtobufSchema)['_messageMapper']['getValue']> {
		return this._messageMapper.getValue();
	}

	private constructor() {}

	private static mapHistoryTimescale(
		timescale: TimeScale
	): RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestHistory.Timescale {
		const value = this.types.RequestHistory.Timescale[timescale];
		return value != null ? value : this.types.RequestHistory.Timescale.TS_UNKNOWN;
	}

	private static mapHistoryPriceType(
		priceType: 'Bid' | 'Ask' | 'Average'
	): RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestHistory.PriceType {
		const value = this.types.RequestHistory.PriceType[priceType];
		return value != null ? value : this.types.RequestHistory.PriceType.RHPT_UNKNOWN;
	}

	private static mapOrderExpiration(expiration: OrderExpiration): number | null {
		if (expiration == null) {
			return null;
		}
		const expDt = new Date();
		switch (expiration) {
			case 'EXP_DAY': {
				expDt.setHours(23, 59, 59, 999);
				break;
			}
			case 'EXP_WEEK': {
				expDt.setDate(expDt.getDate() + 7);
				break;
			}
			case 'EXP_MONTH': {
				expDt.setMonth(expDt.getMonth() + 1);
				break;
			}
			case 'EXP_GTC':
			default: {
				return typeof expiration === 'number' && isFinite(expiration) ? expiration : null;
			}
		}
		return expDt.getTime();
	}

	private static mapTradeSide(
		tradeSide: keyof typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestNewTrade.TradeSide
	): RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestNewTrade.TradeSide | null {
		const value = this.types.RequestNewTrade.TradeSide[tradeSide];
		return value != null ? value : this.types.RequestNewTrade.TradeSide.TSD_UNKNOWN;
	}

	private static mapTradeType(
		tradeType: keyof typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestNewTrade.TradeType
	): RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.RequestNewTrade.TradeType | null {
		const value = this.types.RequestNewTrade.TradeType[tradeType];
		return value != null ? value : this.types.RequestNewTrade.TradeType.TTP_UNKNOWN;
	}

	public static RfpDataMessageMapId = (objType: RFPDataObjectType) => {
		switch (objType) {
			case RFPDataObjectType.HistoryTickItems:
				return ProtobufSchema.types.WrapperMessage.Command.HS_TICK;
		}
		return ProtobufSchema.types.WrapperMessage.Command.CMD_UNKNOWN;
	};
}

//#endregion

class PriceQuoteSubscription implements IPriceQuoteSubscription {
	private readonly m_gateway: RfpGateway;
	private readonly m_subscriptions: Map<string, [Function, string, string[]]> = new Map<
		string,
		[Function, string, string[]]
	>();

	public constructor(gateway: RfpGateway) {
		this.m_gateway = gateway;
	}

	private sendPriceQuoteRequest(feedId: string, codes: string[], subscribe: boolean) {
		if (this.m_gateway.connected) {
			const action = subscribe ? SubscriptionAction.Subscribe : SubscriptionAction.Unsubscribe;
			const mapMarketItems = this.m_gateway.mapMarketItems;
			let marketItems: MarketItem[] = [];
			codes.forEach((code) => {
				const marketItem = mapMarketItems.get(`${feedId}-${code}`);
				if (marketItem) {
					marketItems.push(marketItem);

					// If the subscription counter is 0 and the session times are not set, request the session times
					if (subscribe && marketItem.subscriptionCounter === 0 && !marketItem.tradingSessions) {
						this.m_gateway.send(RFP.getTTSessionTimes, { code: code });
					}
				}
			});

			this.m_gateway.send(RFP.manageQuotes, { action: action, feedId: feedId, marketItems: marketItems });
		}
	}

	subscribePriceQuote(feedId: string, codes: string[], callback: (data: PriceQuote) => any): string {
		const subId = uniqueId();
		if (!this.m_subscriptions.has(subId)) {
			this.m_subscriptions.set(subId, [callback, feedId, codes]);
		}

		// Set the snapshot update
		codes.forEach((code) => {
			const priceQuote = this.m_gateway.getQuotePrices(feedId, code);
			if (priceQuote) {
				callback(priceQuote);
			}
		});

		// Send request
		this.sendPriceQuoteRequest(feedId, codes, true);

		return subId;
	}

	unsubscribePriceQuote(subId: any): void {
		const subscription = this.m_subscriptions.get(subId);
		if (subscription) {
			this.sendPriceQuoteRequest(subscription[1], subscription[2], false);
			this.m_subscriptions.delete(subId);
		}
	}

	onPriceQuote(priceQuote: PriceQuote) {
		this.m_subscriptions.forEach((element) => {
			const [func, feedId, codes] = element;
			if (priceQuote.f === feedId) {
				codes.forEach((code) => {
					if (priceQuote.c === code) {
						func(priceQuote);
					}
				});
			}
		});
	}

	dispose() {
		Array.from(this.m_subscriptions.keys()).forEach((element) => {
			this.unsubscribePriceQuote(element);
		});
		this.m_subscriptions.clear();
	}
}

//#region DataObjectSubscription
class DataObjectSubscription implements IDataObjectSubscription {
	private readonly m_subscriptions: Map<string, [Function, RFPDataObjectType]> = new Map<
		string,
		[Function, RFPDataObjectType]
	>();

	subscribe<T extends keyof TRfpDataMessageMap>(
		destination: T,
		callback: (data: TRfpDataMessageMap[T]) => any
	): string {
		const subId = uniqueId();
		if (!this.m_subscriptions.has(subId)) {
			this.m_subscriptions.set(subId, [callback, destination]);
		}

		return subId;
	}

	unsubscribe(subId: any): void {
		if (this.m_subscriptions.has(subId)) {
			this.m_subscriptions.delete(subId);
		}
	}

	onDataObject(data: RFPDataObject) {
		this.m_subscriptions.forEach((element) => {
			const [func, rfpDataType] = element;
			if (rfpDataType === RFPDataObjectType.All || rfpDataType === data.dataObjectType) {
				func(data);
			}
		});
	}

	dispose() {
		this.m_subscriptions.clear();
	}
}

//#endregion

//#region PriceFeed

type PriceFeedSubscriptionDelegate = (
	priceFeedId: string,
	subscription: IPriceFeedSubscription | IPriceFeedSubscription[],
	action: QuoteSubscription['action']
) => Promise<void>;

type PriceFeedConfig = {
	readonly id: string;
	readonly subscriptionDelegate: PriceFeedSubscriptionDelegate;
	readonly subscriptionStreamDelegate: () => Observable<PriceQuote>;
};

class PriceFeed implements IPriceFeed {
	private readonly m_id: string;
	private readonly m_subscriptionDelegate: PriceFeedSubscriptionDelegate;
	private readonly m_stream: Observable<PriceQuote>;
	private readonly m_subscriptions: IPriceFeedSubscription[] = [];

	public get id(): string {
		return this.m_id;
	}

	public get subscriptions(): ReadonlyArray<IPriceFeedSubscription> {
		return this.m_subscriptions;
	}

	public get stream(): Observable<PriceQuote> {
		return this.m_stream;
	}

	public constructor(config: PriceFeedConfig) {
		this.m_id = config.id;
		this.m_subscriptionDelegate = config.subscriptionDelegate;
		this.m_stream = config.subscriptionStreamDelegate().pipe(
			filter((priceQuote: PriceQuote) => {
				return (
					priceQuote != null &&
					this.subscriptions.some((value) => value.feedId === priceQuote.f && value.code === priceQuote.c)
				);
			})
		);
	}

	public subscribe(subscription: IPriceFeedSubscription): Promise<void>;
	public subscribe(subscriptions: IPriceFeedSubscription[]): Promise<void>;
	public subscribe(subscription: IPriceFeedSubscription | IPriceFeedSubscription[]): Promise<void> {
		return Nullable.of(subscription)
			.map((subscription) => {
				//normalize "subscription" argument into an array
				return Array.isArray(subscription) ? subscription : [subscription];
			})
			.map((subscriptions) => {
				//get new subscriptions based on existing subscriptions
				const newSubscriptions: IPriceFeedSubscription[] = [];
				subscriptions.forEach((subscription) => {
					if (
						!this.m_subscriptions.some(
							(element) => element.code === subscription.code && element.feedId === subscription.feedId
						)
					) {
						this.m_subscriptions.push(subscription);
						newSubscriptions.push(subscription);
					}
				});
				//invoke subscription delegate if needed; otherwise, return resolved promise
				return newSubscriptions?.length > 0
					? this.m_subscriptionDelegate(this.id, newSubscriptions, SubscriptionAction.Subscribe)
					: Promise.resolve();
			})
			.orElseGet(() => Promise.resolve());
	}

	public unsubscribe(subscription: IPriceFeedSubscription): Promise<void>;
	public unsubscribe(subscriptions: IPriceFeedSubscription[]): Promise<void>;
	public unsubscribe(predicate: (subscription: IPriceFeedSubscription) => boolean): Promise<void>;
	public unsubscribe(
		subscription:
			| IPriceFeedSubscription
			| IPriceFeedSubscription[]
			| ((subscription: IPriceFeedSubscription) => boolean)
	): Promise<void> {
		return Nullable.of(subscription)
			.map((subscription) => {
				//normalize "subscription" argument into an array
				return typeof subscription === 'function'
					? this.m_subscriptions.filter(subscription)
					: Array.isArray(subscription)
					? subscription
					: [subscription];
			})
			.map((subscriptions) => {
				//get existing subscriptions
				const existingSubscriptions: IPriceFeedSubscription[] = [];
				subscriptions.forEach((subscription) => {
					const index = this.m_subscriptions.findIndex(
						(element) => element.code === subscription.code && element.feedId === subscription.feedId
					);
					if (index >= 0) {
						this.m_subscriptions.splice(index, 1);
						existingSubscriptions.push(subscription);
					}
				});
				//invoke subscription delegate if needed; otherwise, return resolved promise
				return existingSubscriptions?.length > 0
					? this.m_subscriptionDelegate(this.id, existingSubscriptions, SubscriptionAction.Unsubscribe)
					: Promise.resolve();
			})
			.orElseGet(() => Promise.resolve());
	}
}

//#endregion

//#region HistoryFeed

type HistoryFeedSubscriptionDelegate = (
	historyFeedId: string,
	historySubscription: HistorySubscription
) => Promise<void>;

type HistoryFeedConfig = {
	readonly id: string;
	readonly subscriptionDelegate: HistoryFeedSubscriptionDelegate;
	readonly fetchTicksDelegate: (
		historyFeedSubscription: IHistoryFeedSubscription,
		timeoutDelay?: number
	) => Promise<HistoryTick[]>;
	readonly subscriptionStreamDelegate: () => Observable<HistoryTickItems>;
};

class HistoryFeed implements IHistoryFeed {
	private readonly m_id: string;
	private readonly m_subscriptionDelegate: HistoryFeedSubscriptionDelegate;
	private readonly m_fetchTicksDelegate: (
		historyFeedSubscription: IHistoryFeedSubscription,
		timeoutDelay?: number,
		historyFeed?: IHistoryFeed
	) => Promise<HistoryTick[]>;
	private readonly m_stream: Observable<HistoryTick[]>;
	private readonly m_subscriptions: IHistoryFeedSubscription[] = [];

	public get id(): string {
		return this.m_id;
	}

	public get subscriptions(): ReadonlyArray<IHistoryFeedSubscription> {
		return this.m_subscriptions;
	}

	public get stream(): Observable<HistoryTick[]> {
		return this.m_stream;
	}

	public constructor(config: HistoryFeedConfig) {
		this.m_id = config.id;
		this.m_subscriptionDelegate = config.subscriptionDelegate;
		this.m_fetchTicksDelegate = config.fetchTicksDelegate;

		this.m_stream = config.subscriptionStreamDelegate().pipe(
			map((historyTickItems: HistoryTickItems) => {
				return historyTickItems.historyTicks.filter((tick) =>
					this.subscriptions.some(
						(value) => value.feedId === tick.feedId && value.code === tick.code && value.timescale === tick.timescale
					)
				);
			}),
			filter((ticks: HistoryTick[]) => ticks != null && ticks?.length > 0)
		);
	}

	public fetchTicks(historyFeedSubscription: IHistoryFeedSubscription, timeoutDelay?: number): Promise<HistoryTick[]> {
		return this.m_fetchTicksDelegate(historyFeedSubscription, timeoutDelay, this);
	}

	public subscribe(subscription: IHistoryFeedSubscription): Promise<void> {
		const index = this.m_subscriptions.findIndex(
			(element) =>
				element.code === subscription.code &&
				element.feedId === subscription.feedId &&
				element.timescale === subscription.timescale &&
				element.priceType === subscription.priceType
		);
		if (index >= 0) {
			return Promise.resolve();
		}
		this.m_subscriptions.push(subscription);
		subscription.reqId = TTSubscriptionManager.instance.getHistoryRequestId(
			subscription.code,
			subscription.timescale,
			subscription.priceType
		);
		//console.log("HS - subscribe: " + subscription.reqId)
		const historySubscription = {
			...subscription,
			...{ action: SubscriptionAction.Subscribe, priceType: subscription.priceType, reqId: subscription.reqId },
		};
		return this.m_subscriptionDelegate(this.id, historySubscription as HistorySubscription);
	}

	public unsubscribe(subscription: IHistoryFeedSubscription): Promise<void>;
	public unsubscribe(predicate: (subscription: IHistoryFeedSubscription) => boolean): Promise<void>;
	public unsubscribe(
		subscription: IHistoryFeedSubscription | ((subscription: IHistoryFeedSubscription) => boolean)
	): Promise<void> {
		const subscriptions =
			typeof subscription === 'function' ? this.m_subscriptions.filter(subscription) : [subscription];
		const promises = subscriptions.map((subscription) => {
			const index = this.m_subscriptions.findIndex(
				(element) =>
					element.code === subscription.code &&
					element.feedId === subscription.feedId &&
					element.timescale === subscription.timescale &&
					element.priceType === subscription.priceType
			);
			if (index < 0) {
				return Promise.resolve();
			}
			this.m_subscriptions.splice(index, 1);
			const hsReqId = TTSubscriptionManager.instance.getHistoryRequestId(
				subscription.code,
				subscription.timescale,
				subscription.priceType
			);
			//console.log("HS - unsubscribe: " + hsReqId)
			const historySubscription = {
				...subscription,
				...{ action: SubscriptionAction.Unsubscribe, priceType: subscription.priceType, reqId: hsReqId },
			};
			return this.m_subscriptionDelegate(this.id, historySubscription as HistorySubscription);
		});
		return Promise.all(promises) as unknown as Promise<void>;
	}
}

//#endregion

//#region RfpClients

interface IRfpClient {
	readonly connected: boolean;
	setAppInBackgroundMode(value: boolean): void;
	debugHandler: Optional<(...args: any) => void>;
	connect(url: string, errorCallback?: (error: Error) => void): Promise<void>;
	send(message: IWrapperMessage): Promise<void>;
	subscribe(command: IWrapperMessage['commandId'], callback: (message: IWrapperMessage) => any): Promise<void>;
	unsubscribe(command: IWrapperMessage['commandId']): Promise<void>;
	hasSubscription(command: IWrapperMessage['commandId']): boolean;

	subscribeForData(callback: (message: IWrapperMessage) => any): Promise<void>;
	unsubscribeForData(): Promise<void>;
	disconnect(): Promise<void>;
}

class WorkerRfpClient implements IRfpClient {
	private readonly m_promiseFactory = new PromiseFactory();
	private readonly m_subscriptionCommandIds: Set<IWrapperMessage['commandId']>;
	private m_workerState: BinaryRfpClientWorkerState;
	private m_worker: Optional<ModuleThread<BinaryRfpClientWorker>> = null;
	private m_workerDebugSubscription: Optional<Subscription<any[]>> = null;
	private m_workerStateSubscription: Optional<Subscription<BinaryRfpClientWorkerState>> = null;
	private m_debugHandler: IRfpClient['debugHandler'] = null;

	public get connected(): boolean {
		return this.m_worker != null && this.m_workerState === BinaryRfpClientWorkerState.connected;
	}

	public get worker(): Optional<ModuleThread<BinaryRfpClientWorker>> {
		return this.m_worker;
	}

	public get debugHandler(): IRfpClient['debugHandler'] {
		return this.m_debugHandler;
	}

	public set debugHandler(value: IRfpClient['debugHandler']) {
		this.m_debugHandler = value;
	}

	public constructor() {
		this.m_workerState = BinaryRfpClientWorkerState.disconnected;
		this.m_subscriptionCommandIds = new Set<IWrapperMessage['commandId']>();
	}

	public setAppInBackgroundMode(value: boolean): void {
		if (this.m_worker) {
			this.m_worker.setIsInBackgroundMode(value);
		}
	}

	public connect(url: string, errorCallback?: (error: Error) => void): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				await this.startWorker();
			} catch (err: any & Error) {
				let reason = err.name && err.message ? `(${err.name}) ${err.message}` : `(${err})`;
				reject(`Error starting worker: ${reason}`);
			}

			if (this.m_workerDebugSubscription) {
				this.m_workerDebugSubscription.unsubscribe();
			}
			if (this.m_workerStateSubscription) {
				this.m_workerStateSubscription.unsubscribe();
			}
			if (this.m_worker) {
				this.m_workerDebugSubscription = this.m_worker.debugStream().subscribe((values: any[]) => {
					this.debug(...values);
				});
				this.m_workerStateSubscription = this.m_worker.stateStream().subscribe((value: BinaryRfpClientWorkerState) => {
					this.m_workerState = value;
					if (value === BinaryRfpClientWorkerState.error && errorCallback) {
						errorCallback(new Error('WebSocket error'));
						errorCallback = undefined;
					}
					if (value === BinaryRfpClientWorkerState.connectionTimeout && errorCallback) {
						// Get app context provider
						const appContextProvider = Resolver.resolve(AppContextProvider);
						appContextProvider.reconnectModal = true;
					}
				});
				try {
					await this.m_worker.connect(url);
					resolve();
				} catch (err: any & Error) {
					if (this.connected) {
						errorCallback && errorCallback(err);
					} else {
						reject(`WebSocket connection error: (${err})`);
					}
				}
			} else {
				reject('Error starting worker');
			}
		});
	}

	public send(message: IWrapperMessage): Promise<void> {
		return new Promise((resolve, reject) => {
			try {
				this.requireConnectedClient((client) => {
					message.time = message.time || Math.floor(Date.now() / 1000);

					if (message.commandId === 36 || message.commandId === 37) {
						console.info('client.send(message)', { message });
					}
					client.send(message);
					resolve();
				});
			} catch (err: any & Error) {
				reject(`Error sending message: (${err})`);
			}
		});
	}

	public subscribeForData(callback: (message: IWrapperMessage) => any): Promise<void> {
		return new Promise((resolve, reject) => {
			try {
				this.requireConnectedClient(async (client) => {
					const observableSubscription = client.subscribeForData();
					observableSubscription.subscribe(callback);
					resolve();
				});
			} catch (err: any & Error) {
				reject(`Error subscribing: (${err})`);
			}
		});
	}

	public unsubscribeForData(): Promise<void> {
		return new Promise((resolve, reject) => {
			try {
				this.requireConnectedClient(
					(client) => {
						client.unsubscribeForData();
						resolve();
					},
					() => {
						resolve();
					}
				);
			} catch (err: any & Error) {
				reject(`Error unsubscribing: (${err})`);
			}
		});
	}

	public subscribe(command: IWrapperMessage['commandId'], callback: (message: IWrapperMessage) => any): Promise<void> {
		return new Promise((resolve, reject) => {
			try {
				this.requireConnectedClient(async (client) => {
					const observableSubscription = client.subscribe(command);
					this.m_subscriptionCommandIds.add(command);
					observableSubscription.subscribe(callback);
					resolve();
				});
			} catch (err: any & Error) {
				reject(`Error subscribing: (${err})`);
			}
		});
	}

	public unsubscribe(command: IWrapperMessage['commandId']): Promise<void> {
		return new Promise((resolve, reject) => {
			try {
				this.requireConnectedClient(
					(client) => {
						client.unsubscribe(command);
						this.m_subscriptionCommandIds.delete(command);
						resolve();
					},
					() => {
						resolve();
					}
				);
			} catch (err: any & Error) {
				reject(`Error unsubscribing: (${err})`);
			}
		});
	}

	public hasSubscription(command: IWrapperMessage['commandId']): boolean {
		return this.m_subscriptionCommandIds.has(command);
	}

	public disconnect(): Promise<void> {
		return new Promise((resolve, reject) => {
			try {
				this.requireConnectedClient(
					async (client) => {
						await client.disconnect();
						this.m_subscriptionCommandIds.clear();
						if (this.m_workerDebugSubscription) {
							this.m_workerDebugSubscription.unsubscribe();
						}
						if (this.m_workerStateSubscription) {
							this.m_workerStateSubscription.unsubscribe();
						}
						await this.stopWorker();
						this.m_workerState = BinaryRfpClientWorkerState.disconnected;
						resolve();
					},
					() => {
						resolve();
					}
				);
			} catch (err: any & Error) {
				reject(`Error disconnecting: (${err})`);
			}
		});
	}

	private requireConnectedClient(
		connectedAction: (worker: ModuleThread<BinaryRfpClientWorker>) => any,
		disconnectedAction?: (() => any) | Error | string
	): void {
		if (this.m_worker && this.connected) {
			connectedAction(this.m_worker);
		} else {
			disconnectedAction = disconnectedAction || 'Client socket is not connected or worker is not running';
			if (typeof disconnectedAction === 'string') {
				throw new Error(disconnectedAction);
			} else if (typeof disconnectedAction === 'function') {
				disconnectedAction();
			} else if (
				TypeUtils.is<Error>(disconnectedAction, (subject) => subject.name != null && subject.message != null)
			) {
				throw disconnectedAction;
			}
		}
	}

	private debug(...args: any[]): void {
		if (this.debugHandler) {
			try {
				this.debugHandler(...args);
			} catch (err) {}
		}
	}

	private async startWorker(): Promise<void> {
		await this.m_promiseFactory.singleton('workerLifecyle', async (resolve, reject) => {
			try {
				if (this.m_worker) {
					resolve();
				} else {
					const worker = await spawn<BinaryRfpClientWorker>(new WebWorker());
					this.m_worker = worker;
					resolve();
				}
			} catch (err: any & Error) {
				this.debug('Error creating worker: ', err);
				reject(`Error creating worker: (${err})`);
			}
		});
		return this.m_worker != null ? Promise.resolve() : this.startWorker();
	}

	private async stopWorker(): Promise<void> {
		await this.m_promiseFactory.singleton('workerLifecycle', async (resolve, reject) => {
			try {
				if (this.m_worker == null) {
					resolve();
				} else {
					await Thread.terminate(this.m_worker!);
					this.m_worker = null;
					resolve();
				}
			} catch (err: any & Error) {
				this.debug('Error terminating worker: ', err);
				reject(`Error terminating worker: (${err})`);
			}
		});
		return this.m_worker == null ? Promise.resolve() : this.stopWorker();
	}
}

//#endregion

//#region RfpGateway

export default class BinaryRfpGateway extends RfpGateway {
	private readonly m_subscriptionMessageStream: Lazy<Subject<ISubscriptionMessage>> = new Lazy(() => new Subject());
	private readonly m_priceStream: Lazy<Observable<PriceQuote>>;
	private readonly m_historyStream: Lazy<Observable<HistoryTickItems>>;
	private readonly m_subscriptions: Map<string, IWrapperMessage['commandId'][]> = new Map();
	private readonly m_priceFeedConfigTemplate: Lazy<ExcludeProperty<PriceFeedConfig, 'id'>>;
	private readonly m_historyFeedConfigTemplate: Lazy<ExcludeProperty<HistoryFeedConfig, 'id'>>;
	private readonly m_socketClient: IRfpClient;
	private readonly m_currentPrices: Map<string, PriceQuote> = new Map();
	private readonly m_historyData: Map<string, HistoryTick[]> = new Map();
	private readonly m_promiseFactory = new PromiseFactory();
	private readonly m_priceFeeds: IPriceFeed[] = [];
	private readonly m_historyFeeds: IHistoryFeed[] = [];
	private readonly m_feedIds: FeedInfo[] = [];
	private readonly m_maxRequestFrequency: number;
	private readonly m_dataObjectSubscriptions: DataObjectSubscription = new DataObjectSubscription();
	private readonly m_priceQuoteSubscriptions: PriceQuoteSubscription = new PriceQuoteSubscription(this);
	private readonly m_mapMarketItems: Map<string, MarketItem> = new Map<string, MarketItem>();

	private m_marketItemsRecord: MarketItemsRecord | undefined;

	private m_isAppInBackgroundMode: boolean = false;
	private m_lastRequestTime: number = 0;
	private m_reconnectHandler: Optional<IRfpGatewayEventMap['socketError']> = null;
	private m_quotesInterval: any = null;

	private get promiseFactory(): PromiseFactory {
		return this.m_promiseFactory;
	}

	public get dataObjectSubscriptions(): DataObjectSubscription {
		return this.m_dataObjectSubscriptions;
	}

	private get priceFeedConfigTemplate(): ExcludeProperty<PriceFeedConfig, 'id'> {
		return this.m_priceFeedConfigTemplate.getValue();
	}

	private get historyFeedConfigTemplate(): ExcludeProperty<HistoryFeedConfig, 'id'> {
		return this.m_historyFeedConfigTemplate.getValue();
	}

	private get subscriptionMessageStream(): Subject<ISubscriptionMessage> {
		return this.m_subscriptionMessageStream.getValue();
	}

	public get connected(): boolean {
		return this.m_socketClient?.connected || false;
	}

	public get config(): Readonly<BinaryRfpGatewayConfig> {
		return this.m_config as BinaryRfpGatewayConfig;
	}

	public get mapQuotesPrices(): ReadonlyMap<string, PriceQuote> {
		return this.m_currentPrices;
	}

	public get mapHistoryData(): ReadonlyMap<string, HistoryTick[]> {
		return this.m_historyData;
	}

	public set setupHistoryData(reqId: string) {
		this.m_historyData.set(reqId, []);
	}

	public set deleteMapHistoryData(reqId: string) {
		this.m_historyData.delete(reqId);
	}

	public get mapMarketItems(): ReadonlyMap<string, MarketItem> {
		return this.m_mapMarketItems;
	}

	public get subscriptionStream(): Observable<ISubscriptionMessage> {
		return Observable.from(this.subscriptionMessageStream);
	}

	public get priceStream(): Observable<PriceQuote> {
		return this.m_priceStream.getValue();
	}

	public get historyStream(): Observable<HistoryTickItems> {
		return this.m_historyStream.getValue();
	}

	public get priceFeeds(): ReadonlyArray<IPriceFeed> {
		return this.m_priceFeeds;
	}

	public get historyFeeds(): ReadonlyArray<IHistoryFeed> {
		return this.m_historyFeeds;
	}

	public get isAppInBackgroundMode(): boolean {
		return this.m_isAppInBackgroundMode;
	}

	public set setAppInBackgroundMode(value: boolean) {
		this.m_isAppInBackgroundMode = value;
		this.m_socketClient.setAppInBackgroundMode(value);
	}

	public constructor(config: IRfpGatewayConfig) {
		super(new BinaryRfpGatewayConfig(config));

		this.m_maxRequestFrequency = Math.ceil(1000 / this.config.maxRequestsPerSecond);
		this.m_socketClient = new WorkerRfpClient();
		this.m_socketClient.debugHandler = (...args: any[]) => {
			this.debug(...args);
		};

		//create price stream
		this.m_priceStream = new Lazy(() => {
			const observable: Observable<PriceQuote> = this.subscriptionStream.pipe(
				filter((message) => message.commandId === ProtobufSchema.types.WrapperMessage.Command.QUOTE),
				map((message: ISubscriptionMessage<PriceQuote>) => message.data),
				filter((priceQuote: PriceQuote) => priceQuote != null && priceQuote.a !== 0 && priceQuote.b !== 0)
			);
			observable.subscribe((priceQuote) => {
				this.m_currentPrices.set(`${priceQuote.f}-${priceQuote.c}`, priceQuote);
			});
			return observable;
		});

		//create history stream
		this.m_historyStream = new Lazy(() => {
			return this.subscriptionStream.pipe(
				filter((message) => message.commandId === ProtobufSchema.types.WrapperMessage.Command.HS_TICK),
				map((message: ISubscriptionMessage<HistoryTickItems>) => message.data),
				filter((data: HistoryTickItems) => data.historyTicks != null && data.historyTicks?.length > 0)
			);
		});

		//create price feed config template
		this.m_priceFeedConfigTemplate = new Lazy(() => {
			return {
				subscriptionDelegate: (
					priceFeedId: string,
					subscription: IPriceFeedSubscription | IPriceFeedSubscription[],
					action: QuoteSubscription['action']
				) => {
					return Nullable.of(subscription)
						.map((subscription) => {
							//normalize "subscription" argument into an array
							return Array.isArray(subscription) ? subscription : [subscription];
						})
						.map((subscriptions) => {
							//create map of subscriptions by feedId
							return subscriptions
								.filter((subscription) => {
									const { code, feedId } = subscription;
									return (
										(this.connected || action === SubscriptionAction.Subscribe) &&
										!this.m_priceFeeds.some(
											(priceFeed) =>
												priceFeed.id !== priceFeedId &&
												priceFeed.subscriptions.some(
													(subscription) => subscription.feedId === feedId && subscription.code === code
												)
										)
									);
								})
								.reduce((map, subscription) => {
									if (!map.has(subscription.feedId)) {
										map.set(subscription.feedId, []);
									}
									map.get(subscription.feedId)!.push(subscription);
									return map;
								}, new Map<string, IPriceFeedSubscription[]>());
						})
						.filter((subscriptionsByFeedId) => subscriptionsByFeedId.size > 0)
						.map((subscriptionsByFeedId) => {
							//queue quotes (if subscribing) and send quote subscription/unsubscription requests
							const promise = (
								action === SubscriptionAction.Subscribe ? this.subscribe(RFP.queueQuotes) : Promise.resolve()
							) as Promise<any>;
							return promise.then(() => {
								const promises: Promise<void>[] = [];
								subscriptionsByFeedId.forEach((subscriptions, feedId) => {
									let marketItems: MarketItem[] = [];
									subscriptions.forEach((value) => {
										const marketItem = this.mapMarketItems.get(`${value.feedId}-${value.code}`);
										if (marketItem) {
											marketItems.push(marketItem);

											// If the subscription counter is 0 and the session times are not set, request the session times
											if (
												action === SubscriptionAction.Subscribe &&
												marketItem.subscriptionCounter === 0 &&
												!marketItem.tradingSessions
											) {
												console.debug(`Requesting session times for ${marketItem.code}`);
												this.send(RFP.getTTSessionTimes, { code: marketItem.code });
											}
										}
									});

									const promise = this.send(RFP.manageQuotes, {
										action: action,
										feedId: feedId,
										marketItems: marketItems,
									});
									promises.push(promise);
								});
							});
						})
						.orElseGet(() => Promise.resolve());
				},
				subscriptionStreamDelegate: () => {
					return this.priceStream;
				},
			};
		});

		//create history feed config template
		this.m_historyFeedConfigTemplate = new Lazy(() => {
			return {
				subscriptionDelegate: (historyFeedId: string, historySubscription: HistorySubscription) => {
					return (!this.connected && historySubscription.action === SubscriptionAction.Unsubscribe) ||
						this.m_historyFeeds.some(
							(historyFeed) =>
								historyFeed.id !== historyFeedId &&
								historyFeed.subscriptions.some(
									(subscription) =>
										subscription.feedId === historySubscription.feedId &&
										subscription.code === historySubscription.code &&
										subscription.timescale === historySubscription.timescale &&
										subscription.priceType === historySubscription.priceType
								)
						)
						? Promise.resolve()
						: this.subscribe(RFP.queueHistoricalDataUpdates).then(() =>
								this.send(RFP.manageHistory, historySubscription)
						  );
				},
				fetchTicksDelegate: (
					historyFeedSubscription: IHistoryFeedSubscription,
					timeoutDelay?: number,
					historyFeed?: IHistoryFeed
				) => {
					return this.fetchHistoryTicks(historyFeedSubscription, timeoutDelay, historyFeed);
				},
				subscriptionStreamDelegate: () => {
					return this.historyStream;
				},
			};
		});
	}

	public connect(
		connectPayloadProvider: () => RFPConnectPayload | Promise<RFPConnectPayload>
	): Promise<RFPConnectResult> {
		return this.connected
			? Promise.resolve({
					success: true,
					status: 200,
					email: this.connectionInfo?.username,
					tfboSessionId: this.connectionInfo?.tfboSessionId,
					tfboToken: this.connectionInfo?.tfboToken,
			  })
			: this.promiseFactory.singleton('connect', async (resolve, reject) => {
					try {
						//get connect payload
						let connectPayload = connectPayloadProvider();

						//unsubscribe previously defined reconnection handler
						Nullable.of(this.m_reconnectHandler).run((handler) => this.off('socketError', handler));

						//define reconnection handler
						Nullable.of(this.config.webSocket.autoReconnect)
							.map((autoReconnect) => (autoReconnect !== false ? autoReconnect.interval : null))
							.run((reconnectInterval) => {
								let interval: any = null;
								this.m_reconnectHandler = () => {
									interval =
										interval ||
										setInterval(() => {
											this.promiseFactory.singleton('reconnect', async (resolve, reject) => {
												try {
													this.debug('Reconnecting to server...');
													await this.wsDisconnect();
													await this.connect(connectPayloadProvider).then((result) => {
														if (!result.success) {
															this.debug('Error reconnecting to server', result.error);
														}
													});
													clearInterval(interval);
													interval = null;
												} catch (err) {
													this.debug('Error reconnecting to server', err);
												}
												resolve();
											});
										}, reconnectInterval);
								};
							});

						//attach reconnect handler to socketError event
						Nullable.of(this.m_reconnectHandler).run((handler) => this.once('socketError', handler));

						//if connectPayload is a promise, await resolution
						if (
							TypeUtils.is<Promise<RFPConnectPayload>>(connectPayload, (subject) => typeof subject.then === 'function')
						) {
							connectPayload = await connectPayload;
						}

						//if SSO is enabled, then check to make sure all params are included
						if (
							connectPayload.enableSSO &&
							(connectPayload.username === null ||
								connectPayload.sso_token === null ||
								connectPayload.tradingMode === null)
						) {
							resolve({
								success: false,
								error: 'Invalid or missing SSO credentials',
								status: 401,
							});
						}
						//ensure credentials have been provided
						if (
							!connectPayload.enableSSO &&
							(connectPayload.username === null ||
								connectPayload.password === null ||
								connectPayload.tradingMode === null)
						) {
							resolve({
								success: false,
								error: 'Error: Invalid or missing credentials.',
								status: 401,
							});
							return;
						}

						//connect websocket
						await this.wsConnect((error) => {
							this.emit('socketError', error);
						});

						if (!this.connected) {
							resolve({
								success: false,
								error: 'Error: Unable to establish RFP WSS connection.',
								status: 500,
							});
							return;
						}

						// subscribe for data message
						this.m_socketClient.subscribeForData((message) => {
							const decodedMessage = ProtobufSchema.decodeMessage(message);

							function instanceOfRFPDataObject(object: any | undefined): object is RFPDataObject {
								if (object) {
									return 'dataObjectType' in object;
								}
								return false;
							}

							if (instanceOfRFPDataObject(decodedMessage)) {
								const message = decodedMessage as RFPDataObject;
								if (message.dataObjectType === RFPDataObjectType.PriceQuote) {
									const rshData = ProtobufSchema.rshMap?.get((message as PriceQuote).c);

									if (rshData) {
										const adjustedPips = Math.pow(10, rshData.pipPos); // shiftInPrice = shift in pips / 10^instrument pip position

										if (rshData.rshL) {
											(message as PriceQuote).a = (message as PriceQuote).a + rshData.rshL / adjustedPips;
										}
										if (rshData.rshS) {
											(message as PriceQuote).b = (message as PriceQuote).b + rshData.rshS / adjustedPips;
										}
									}
								}

								this.m_dataObjectSubscriptions.onDataObject(message);
								switch (message.dataObjectType) {
									case RFPDataObjectType.PriceQuote: {
										this.m_priceQuoteSubscriptions.onPriceQuote(message as PriceQuote);
										break;
									}
									case RFPDataObjectType.HistoryTickItems: {
										const items = message as HistoryTickItems;

										if (items.reqId.startsWith('hs_')) {
											const data = this.m_historyData.get(items.reqId);
											if (data) {
												if (data.length > 0) {
													if (items.historyTicks.length === 1) {
														const last = data[data.length - 1];
														if (last.closeTime === items.historyTicks[0].closeTime) {
															data.pop();
														}
														data.push(items.historyTicks[0]);
													} else {
														data.push(...items.historyTicks);
													}
												} else {
													this.m_historyData.set(items.reqId, items.historyTicks);
												}
											}
										}
										break;
									}
									case RFPDataObjectType.HistoryTick: {
										const historyTick = message as HistoryTick;
										if (historyTick.reqId.startsWith('hs_')) {
											const data = this.m_historyData.get(historyTick.reqId);
											if (data) {
												if (data.length > 0) {
													const last = data[data.length - 1];
													if (last.closeTime === historyTick.closeTime) {
														data.pop();
													}
													data.push(historyTick);
												} else {
													this.m_historyData.set(historyTick.reqId, [historyTick]);
												}
											}
										}
										break;
									}
									default:
										break;
								}
							}
						});

						//authenticate
						const connectResult: RFPConnectResult = { success: true, status: 200, email: connectPayload.username };
						await this.promiseFactory.promise((resolve, reject) => {
							const timeout = this.promiseFactory.delay(this.config.webSocket.connectTimeout);
							let isResolved = false;
							if (
								!(
									!TypeUtils.is<Promise<RFPConnectPayload>>(
										connectPayload,
										(subject) => typeof subject.then === 'function'
									) && connectPayload.enableSSO
								)
							) {
								//If invoking standard login via WTR
								// Subscribe for ERROR message - e.g. wrong password
								this.wsSubscribe(ProtobufSchema.types.WrapperMessage.Command.ERROR, (message) => {
									let errorMessage = '';
									if (message.data) {
										const data = ProtobufSchema.decodeMessage(message) as SessionError;
										if (data) {
											errorMessage = data.error ?? 'unknown';
											console.debug(`Command.ERROR message: ${errorMessage}`);
										}
									}

									if (!isResolved) {
										isResolved = true;
										timeout.cancel();
										this.wsUnsubscribe(message.commandId);
										connectResult.success = false;
										connectResult.status = 401;
										connectResult.error = RFPConnectionErrorType.AuthenticationFailed;
										resolve();
									} else {
										// Command.ERROR message: Connection to server closed
										// The reconnect logic should be triggered in this case
										if (errorMessage === RFPConnectionErrorType.ConnectionToServerClosed) {
											// Get app context provider
											this.disconnect();
											const appContextProvider = Resolver.resolve(AppContextProvider);
											if (appContextProvider) {
												appContextProvider.reconnectModal = true;
											}
										}
									}
								});
							}

							// Subscribe for LOGIN message - e.g. success / wrong email
							this.wsSubscribe(ProtobufSchema.types.WrapperMessage.Command.LOGIN, (message) => {
								if (!isResolved) {
									isResolved = true;
									timeout.cancel();
									const data = ProtobufSchema.types.LoginResponse.decode(message.data!);
									this.wsUnsubscribe(message.commandId);
									if (data.success) {
										connectResult.success = true;
										connectResult.status = 200;
										connectResult.tfboSessionId = data.tfboSessionId;
										connectResult.tfboToken = data.tfboToken;
										connectResult.prop = data.prop;
										connectResult.rfpUrl = data.rfpUrl;
										connectResult.login_mode = data.loginMode === ProtobufSchema.types.LoginMode.LIVE ? 'LIVE' : 'DEMO';
										connectResult.fundedTrader = data.fundedTrader ?? false;
									} else {
										connectResult.success = false;
										connectResult.status = 401;
										connectResult.error = data.error;
									}
									resolve();
								}
							});

							timeout.then(() => {
								if (!isResolved) {
									isResolved = true;
									connectResult.success = false;
									connectResult.status = 500;
									connectResult.error = RFPConnectionErrorType.AuthenticationTimeout;
									resolve();
								}
							});

							let language = rfpLangMap[(connectPayload as RFPConnectPayload).lang as RfpLangMap];

							const loginRequest = ProtobufSchema.types.WrapperMessage.fromObject({
								commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_LOGIN_AUTH_TOKEN,
								data: ProtobufSchema.types.AuthTokenLoginRequest.encode({
									username: (connectPayload as RFPConnectPayload).username,
									authToken: (connectPayload as RFPConnectPayload).authToken,
									loginMode:
										(connectPayload as RFPConnectPayload).tradingMode === 'DEMO'
											? ProtobufSchema.types.LoginMode.DEMO
											: ProtobufSchema.types.LoginMode.LIVE,
									protoVer: ProtobufSchema.types.ProtoVer.ONE,
									lang: ProtobufSchema.types.LocalizationLang[
										language as keyof typeof ProtobufSchema.types.LocalizationLang
									],
									rfpUrl: (connectPayload as RFPConnectPayload).rfpUrl,
								}).finish(),
							});
							this.wsSend(loginRequest);
						});

						//if result is unsuccessful, no need to proceed further
						if (!connectResult.success) {
							try {
								this.wsDisconnect();
							} catch (err) {}
							resolve(connectResult);
							return;
						}

						//get feedIDs (to ensure client session on RFP WSS has been initialized)
						await this.promiseFactory.promise(async (resolve, reject) => {
							const timeout = this.promiseFactory.delay(this.config.webSocket.connectTimeout);
							let isResolved = false;
							const feedIdsSubscription = this.subscriptionMessageStream.subscribe((message) => {
								if (message.commandId === ProtobufSchema.types.WrapperMessage.Command.FEED_INFO) {
									const data: FeedInfos = message.data;
									data.feedIds.forEach((feed) => {
										if (!this.m_feedIds.some((value) => value.feedID === feed.feedID)) {
											this.m_feedIds.push(feed);
										}
									});

									if (!isResolved) {
										isResolved = true;
										timeout.cancel();
										setTimeout(() => {
											feedIdsSubscription.unsubscribe();
											this.unsubscribe(RFP.queueFeedIds);
										}, 500);
										Nullable.of(this.config.webSocket.feedDelay)
											.filter((delay) => delay > 0)
											.run((delay) => setTimeout(() => resolve(), delay))
											.orElseRun(() => resolve());
									}
								}
							});
							timeout.then(() => {
								if (!isResolved) {
									isResolved = true;
									feedIdsSubscription.unsubscribe();
									connectResult.success = false;
									connectResult.status = 500;
									connectResult.error = RFPConnectionErrorType.RequestFeedIdsTimeout;
									resolve();
								}
							});
							await this.subscribe(RFP.queueFeedIds);
							this.send(RFP.getFeedIds);
						});
						resolve(connectResult);

						//Response from RFP whether it is SSO or standard login will be the same
						if (connectResult.success) {
							this.m_connectionInfo = {
								username: (connectPayload as RFPConnectPayload).username,
								password: (connectPayload as RFPConnectPayload).password || '',
								tfboSessionId: connectResult.tfboSessionId!,
								tfboToken: connectResult.tfboToken!,
							};
							this.emit('connected');
						}

						//simulate quotes if needed
						if (this.config.webSocket.simulateQuotes) {
							clearInterval(this.m_quotesInterval);
							this.m_quotesInterval = setInterval(() => {
								Nullable.of(this.m_subscriptions.get(RFP.queueQuotes))
									.filter((subscriptionId) => this.connected)
									.run((subscriptionId) => {
										const symbols = ['EURUSD', 'AUDUSD', 'CADJPY', 'USDJPY', 'AUDJPY', 'AUDNZD', 'AUDCAD'];
										const symbol = symbols[Math.floor(Math.random() * symbols?.length)];
										const bid = parseFloat((Math.random() * (1.9 - 0.1) + 0.1).toFixed(5));
										const ask = parseFloat((Math.random() * (1.9 - bid) + bid).toFixed(5));
										const priceQuote: ISubscriptionMessage = {
											commandId: ProtobufSchema.types.WrapperMessage.Command.QUOTE,
											time: Math.floor(Date.now() / 1000),
											data: {
												dataObjectType: RFPDataObjectType.PriceQuote,
												a: ask,
												b: bid,
												c: symbol,
												f: this.config.defaultFeedId || 'VTFeed',
												h: ask,
												l: bid,
												t: Date.now() / 1000,
											} as PriceQuote,
										};

										this.m_dataObjectSubscriptions.onDataObject(priceQuote.data);
										if ('dataObjectType' in priceQuote.data) {
											const message = priceQuote.data as RFPDataObject;
											this.m_priceQuoteSubscriptions.onPriceQuote(message as PriceQuote);
										}
										this.subscriptionMessageStream.next(priceQuote);
									});
							}, 100);
						}
					} catch (err: any & Error) {
						try {
							this.wsDisconnect();
						} catch (discError) {
							console.error(`Disconnect error: ${discError}`);
						}
						reject(`Error connecting to RFP WSS: (${err})`);
					}
			  });
	}

	public getMarketItem(code: string, feedId?: string): MarketItem | undefined {
		let feedIdentifier = feedId ?? 'VTFeed';
		return this.m_mapMarketItems.get(`${feedIdentifier}-${code}`);
	}

	public createPriceFeed(id: string = 'default'): IPriceFeed {
		let priceFeed = this.m_priceFeeds.find((value) => value.id === id);
		if (priceFeed == null) {
			priceFeed = new PriceFeed({ ...this.priceFeedConfigTemplate, id: id });
			this.m_priceFeeds.push(priceFeed);
		}
		return priceFeed;
	}

	public requestTfboLogin(): this {
		const tfboLoginRequest = ProtobufSchema.types.WrapperMessage.fromObject({
			commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_TFBO_LOGIN,
			data: ProtobufSchema.types.RequestTfboLogin.encode({}).finish(),
		});
		this.wsSend(tfboLoginRequest);
		return this;
	}

	public requestTfboEndpoint(): this {
		const requestTfboEndpoint = ProtobufSchema.types.WrapperMessage.fromObject({
			commandId: ProtobufSchema.types.WrapperMessage.Command.REQ_TFBO_ENDPOINT,
			data: ProtobufSchema.types.RequestTfboEndpoint.encode({}).finish(),
		});
		this.wsSend(requestTfboEndpoint);
		return this;
	}

	public deletePriceFeed(id: string): this;
	public deletePriceFeed(priceFeed: IPriceFeed): this;
	public deletePriceFeed(predicate: (priceFeed: IPriceFeed) => boolean): this;
	public deletePriceFeed(id: string | IPriceFeed | ((priceFeed: IPriceFeed) => boolean)): this {
		if (typeof id === 'function') {
			this.m_priceFeeds.filter(id).forEach((priceFeed) => {
				priceFeed.unsubscribe((subscription) => subscription != null);
				const index = this.m_priceFeeds.indexOf(priceFeed);
				index >= 0 && this.m_priceFeeds.splice(index, 1);
			});
		} else {
			id = typeof id === 'string' ? id : id.id;
			const index = this.m_priceFeeds.findIndex((value) => value.id == id);
			index >= 0 &&
				this.m_priceFeeds
					.splice(index, 1)
					.forEach((priceFeed) => priceFeed.unsubscribe((subscription) => subscription != null));
		}
		return this;
	}

	public createHistoryFeed(id: string = 'default'): IHistoryFeed {
		let historyFeed = this.m_historyFeeds.find((value) => value.id == id);
		if (historyFeed == null) {
			historyFeed = new HistoryFeed({ ...this.historyFeedConfigTemplate, id: id });
			this.m_historyFeeds.push(historyFeed);
		}
		return historyFeed;
	}

	public deleteHistoryFeed(id: string): this;
	public deleteHistoryFeed(historyFeed: IHistoryFeed): this;
	public deleteHistoryFeed(predicate: (historyFeed: IHistoryFeed) => boolean): this;
	public deleteHistoryFeed(id: string | IHistoryFeed | ((historyFeed: IHistoryFeed) => boolean)): this {
		if (typeof id === 'function') {
			this.m_historyFeeds.filter(id).forEach((historyFeed) => {
				historyFeed.unsubscribe((subscription) => subscription != null);
				const index = this.m_historyFeeds.indexOf(historyFeed);
				index >= 0 && this.m_historyFeeds.splice(index, 1);
			});
		} else {
			id = typeof id === 'string' ? id : id.id;
			const index = this.m_historyFeeds.findIndex((value) => value.id == id);
			index >= 0 &&
				this.m_historyFeeds
					.splice(index, 1)
					.forEach((historyFeed) => historyFeed.unsubscribe((subscription) => subscription != null));
		}
		return this;
	}

	public fetchHistoryTicks(
		historyFeedSubscription: IHistoryFeedSubscription,
		timeoutDelay?: number,
		historyFeed?: IHistoryFeed
	): Promise<HistoryTick[]> {
		const { feedId, code, timescale, tickCount, priceType, reqId } = historyFeedSubscription;
		return this.promiseFactory.singleton(
			`chartData-${feedId}-${code}-${timescale}-${priceType}`,
			async (resolve, reject) => {
				try {
					const isHistoryFeedOwned = historyFeed == null;

					//create new history feed if one wasn't supplied
					historyFeed =
						historyFeed == null
							? this.createHistoryFeed(`${feedId}-${code}-${timescale}-${Math.random()}`)
							: historyFeed;
					timeoutDelay = Math.ceil(Math.max(0, timeoutDelay || 2500));

					// create history subscription in order to get historical data, then unsubscribe once historical data is received
					const minHistoryTicks = tickCount || (timescale.endsWith('WEEK') || timescale.endsWith('MONTH') ? 100 : 999);
					const historyTicks: HistoryTick[] = [];
					let isResolved: boolean = false;
					let subscription: Subscription<HistoryTickItems>;
					//let subscriptionHistorySize: Subscription<HistorySize>;

					//create timeout promise
					const timeoutPromise = this.promiseFactory.delay(timeoutDelay);
					timeoutPromise.then(() => {
						if (!isResolved) {
							isResolved = true;
							Nullable.of(subscription).run((subscription) => {
								if (typeof subscription.unsubscribe === 'function') {
									subscription.unsubscribe();
								} else {
									this.debug('value of subscription: ', subscription);
								}
							});
							if (isHistoryFeedOwned) {
								this.deleteHistoryFeed(historyFeed!);
							}
							resolve(historyTicks.sort((item1, item2) => item1.closeTime - item2.closeTime));
						}
					});

					//unsubscribe existing history feed subscriptions
					const existingSubscriptions = this.historyFeeds
						.filter((feed) => !(feed.id === historyFeed!.id && isHistoryFeedOwned))
						.map((feed) => {
							return {
								feed: feed,
								subscripions: feed.subscriptions.filter(
									(value) =>
										value.code === code &&
										value.feedId === feedId &&
										value.timescale === timescale &&
										value.priceType === priceType
								),
							};
						});
					await Promise.all(
						existingSubscriptions.flatMap((value) =>
							value.subscripions.map((subscription) => value.feed.unsubscribe(subscription))
						)
					);

					//subscribe to historical data
					this.subscribe(RFP.queueHistoricalDataUpdates).then((observable) => {
						subscription = observable.subscribe((historyTickItems) => {
							historyTickItems.historyTicks
								.filter((tick) => {
									return tick != null && tick.reqId === reqId;
								})
								.forEach((tick) => {
									if (!historyTicks.some((historyTick) => historyTick.closeTime === tick.closeTime)) {
										historyTicks.push(tick);
									}
								});

							if (
								!isResolved &&
								historyTicks?.length >= minHistoryTicks &&
								historyTickItems.historyTicks?.length == 1
							) {
								isResolved = true;
								timeoutPromise.cancel();
								if (subscription != null && typeof subscription.unsubscribe === 'function') {
									subscription.unsubscribe();
								}
								if (isHistoryFeedOwned) {
									this.deleteHistoryFeed(historyFeed!);
								}

								//const reqId = TTSubscriptionManager.instance.getHistoryRequestId(code, timescale, priceType)
								const chartContextProvider =
									Resolver.isSet && Resolver.isRegistered(ChartContextProvider)
										? Resolver.resolve(ChartContextProvider)
										: ChartContextProvider.instance;
								const historySize = chartContextProvider.getHistorySizeRecord(reqId);
								if (historySize) {
									historyTicks.forEach((tick) => {
										tick.openTime = tick.closeTime - historySize.duration;
									});
								} else {
									console.log('missing historySize symbol=' + code + ' timeScale=' + timescale);
								}

								resolve(historyTicks.sort((item1, item2) => item1.closeTime - item2.closeTime));
							}
						});
					});
					await historyFeed!.subscribe({
						reqId: reqId,
						feedId: feedId,
						code: code,
						timescale: timescale,
						tickCount: tickCount,
						priceType: priceType,
					});

					//re-subscribe existing subscriptions
					existingSubscriptions.flatMap((value) =>
						value.subscripions.map((subscription) => value.feed.subscribe(subscription))
					);
				} catch (err: any & Error) {
					reject(`Error fetching history ticks: (${err})`);
				}
			}
		);
	}

	public updateQuantityType(accountId: number, quantityType: QuantityType) {
		return this.send(RFP.updateQuantityType, {
			accId: accountId,
			quantityType,
		} as QuantityTypeUpdate);
	}

	public send<T extends keyof TRfpMessages['send']>(destination: T, ...args: TRfpSendParams<T>): Promise<void> {
		//if not connected, return a promise and send message once connected
		if (!this.connected) {
			return this.promiseFactory.promise((resolve, reject) => {
				this.once('connected', () => {
					this.send(destination, ...args)
						.then((result) => resolve(result))
						.catch((err) => reject(err));
				});
			});
		}

		//if requesting feed IDs, simulate response, as feedIds are only sent once
		if (destination == RFP.getFeedIds && this.hasSubscription(RFP.queueFeedIds) && this.m_feedIds?.length > 0) {
			return this.m_promiseFactory.delay(0).then(() => {
				let feedInfos = {
					dataObjectType: RFPDataObjectType.FeedInfos,
					feedIds: this.m_feedIds,
				} as FeedInfos;
				this.subscriptionMessageStream.next({
					commandId: ProtobufSchema.types.WrapperMessage.Command.FEED_INFO,
					data: feedInfos,
					time: Math.floor(Date.now() / 1000),
				});
			});
		}

		//if market items, simulate response to avoid "cartesian product bug" in RFP WSS
		if (
			destination == RFP.getMarketItems &&
			this.hasSubscription(RFP.queueMarketItems) &&
			this.m_marketItemsRecord !== undefined
		) {
			return this.m_promiseFactory.delay(0).then(() => {
				if (this.m_marketItemsRecord) {
					this.subscriptionMessageStream.next({
						commandId: ProtobufSchema.types.WrapperMessage.Command.MKT_ITEM,
						data: this.m_marketItemsRecord,
						time: Math.floor(Date.now() / 1000),
					});
				}
			});
		}

		const [body] = args;

		//get wrapper message
		const wrapperMessage = Nullable.of(ProtobufSchema.messageMapper.send[destination])
			.map((mapper) => {
				return typeof mapper.map === 'function' ? (mapper.map as (message: any) => IWrapperMessage)(body) : null;
			})
			.getOrThrow(() => new Error(`Mapping not found for send destination '${destination}'`));

		this.debug(`>>>SEND ${destination}`, body || {});

		//send message
		return this.wsSend(wrapperMessage);
	}

	public hasSubscription<T extends keyof TRfpMessages['subscribe']>(destination: T): boolean {
		return this.m_subscriptions.get(destination) != null;
	}

	public subscribeFor<T extends keyof TRfpDataMessageMap>(
		destination: T,
		callback: (data: TRfpDataMessageMap[T]) => any
	): string {
		return this.m_dataObjectSubscriptions.subscribe(destination, callback);
	}

	public unsubscribeFor(subId: any): void {
		// console.log(`unsubscribeFor subId=${subId}`);
		if (this.m_historyData.has(subId)) {
			this.m_historyData.delete(subId);
		}
		this.m_dataObjectSubscriptions.unsubscribe(subId);
	}

	// This will request and subscribe price quites
	public subscribePriceQuote(feedId: string, codes: string[], callback: (priceQuote: PriceQuote) => any): string {
		return this.m_priceQuoteSubscriptions.subscribePriceQuote(feedId, codes, callback);
	}

	public unsubscribePriceQuote(subId: any): void {
		this.m_priceQuoteSubscriptions.unsubscribePriceQuote(subId);
	}

	public subscribe<T extends keyof TRfpMessages['subscribe']>(
		destination: T
	): Promise<Observable<TRfpMessages['subscribe'][T]>>;
	public subscribe<T extends keyof TRfpMessages['subscribe']>(
		destination: T,
		callback: (message: TRfpMessages['subscribe'][T]) => any
	): Promise<Subscription<TRfpMessages['subscribe'][T]>>;
	public subscribe<T extends keyof TRfpMessages['subscribe']>(
		destination: T,
		callback?: (message: TRfpMessages['subscribe'][T]) => any
	): Promise<Subscription<TRfpMessages['subscribe'][T]> | Observable<TRfpMessages['subscribe'][T]>> {
		return this.connected
			? this.promiseFactory.singleton(`subscribe-${destination}`, async (resolve, reject) => {
					try {
						//get commandId and mapping function
						const { commandIds, mapperFnc } = Nullable.of(ProtobufSchema.messageMapper.subscribe[destination])
							.map((mapper) => {
								return {
									commandIds: Array.isArray(mapper.commandId) ? mapper.commandId : [mapper.commandId],
									mapperFnc: typeof mapper.map === 'function' ? mapper.map.bind(map) : null,
								};
							})
							.get();

						if (commandIds == null || commandIds?.length < 1) {
							throw new Error(`CommandId mapping not found for subscription destination '${destination}'`);
						}
						if (mapperFnc == null) {
							throw new Error(
								`Subscription message mapping function not found for subscription destination '${destination}'`
							);
						}

						//get subscribed command Ids and subscribe to any commands not already subscribed
						let subscribedCommandIds = this.m_subscriptions.get(destination) || [];
						commandIds
							.filter((commandId) => !(subscribedCommandIds.includes(commandId) && this.wsHasSubscription(commandId)))
							.forEach((commandId) => {
								this.wsSubscribe(commandId, (message) => {
									let body = mapperFnc(message);
									// console.log(`<<<RECEIVE ${destination}`, body);
									this.debug(`<<<RECEIVE ${destination}`, body);

									//capture market items for later requests to avoid "cartesian product bug" in RFP WSS that results from subsequent market items requests
									if (message.commandId === ProtobufSchema.types.WrapperMessage.Command.MKT_ITEM) {
										this.m_marketItemsRecord = body as MarketItemsRecord;

										// Create a map of marketItems in order to quick filter the instruments
										if (this.m_marketItemsRecord) {
											this.m_marketItemsRecord.marketItems.forEach((element) =>
												this.m_mapMarketItems.set(`${element.feedId}-${element.code}`, element)
											);
										}
									}

									// Set all defined MarketItemInfo to the related market item
									if (message.commandId === ProtobufSchema.types.WrapperMessage.Command.MKT_ITEM_INFO) {
										let marketItemInfos = body as MarketItemsInfoRecord;
										marketItemInfos.marketItemsInfo.forEach((element) => {
											if (this.m_marketItemsRecord) {
												let mi = this.getMarketItem(element.code, element.feedId);
												if (mi) {
													mi.marketItemInfo = element;
												}
											}
										});
									}

									if (TypeUtils.is<IterableIterator<any>>(body, (subject) => typeof subject.next === 'function')) {
										let data = body.next().value;
										while (data) {
											this.subscriptionMessageStream.next({
												commandId: message.commandId,
												data: data,
												time: message.time || Math.floor(Date.now() / 1000),
											});
											data = body.next().value;
										}
									} else {
										this.subscriptionMessageStream.next({
											commandId: message.commandId,
											data: body,
											time: message.time || Math.floor(Date.now() / 1000),
										});
									}
								});
								if (!subscribedCommandIds.includes(commandId)) {
									subscribedCommandIds.push(commandId);
								}
							});
						this.m_subscriptions.set(destination, subscribedCommandIds);

						const observable: Observable<TRfpMessages['subscribe'][T]> = this.subscriptionMessageStream.pipe(
							filter((message) => subscribedCommandIds.includes(message.commandId)),
							map((message: ISubscriptionMessage) => message.data as TRfpMessages['subscribe'][T])
						);
						resolve(callback ? observable.subscribe(callback) : observable);
					} catch (err: any & Error) {
						reject(`Error subscribing: (${err})`);
					}
			  })
			: this.promiseFactory.promise((resolve, reject) => {
					this.once('connected', () => {
						this.subscribe(destination, callback as any)
							.then((result) => resolve(result))
							.catch((err) => reject(err));
					});
			  });
	}

	public unsubscribe(destination: string): Promise<void> {
		return this.promiseFactory.singleton(`unsubscribe-${destination}`, async (resolve, reject) => {
			try {
				const subscribedCommandIds = this.m_subscriptions.get(destination);
				if (subscribedCommandIds) {
					await Promise.all(subscribedCommandIds.map((commandId) => this.wsUnsubscribe(commandId)));
				}
				this.m_subscriptions.delete(destination);
				resolve();
			} catch (err: any & Error) {
				reject(`Error unsubscribing: (${err})`);
			}
		});
	}

	public disconnect(): Promise<void> {
		return !this.connected
			? Promise.resolve()
			: this.promiseFactory.singleton('disconnect', async (resolve, reject) => {
					// unsubscribe for data message
					try {
						await this.m_socketClient.unsubscribeForData();
					} catch (err) {
						this.debug('Error disconnecting client socket', err);
					}

					try {
						await this.wsDisconnect();
					} catch (err) {
						this.debug('Error disconnecting client socket', err);
					}

					this.m_connectionInfo = null;
					this.m_lastRequestTime = 0;
					this.m_subscriptions.clear();
					if (this.m_priceFeeds?.length > 0) {
						this.m_priceFeeds.splice(0);
					}
					if (this.m_historyFeeds?.length > 0) {
						this.m_historyFeeds.splice(0);
					}
					if (this.m_feedIds?.length > 0) {
						this.m_feedIds.splice(0);
					}

					if (this.m_marketItemsRecord) {
						this.m_marketItemsRecord.marketItems.splice(0);
						this.m_marketItemsRecord = undefined;
					}

					this.m_mapMarketItems.clear();

					if (this.dataObjectSubscriptions) {
						this.dataObjectSubscriptions.dispose();
					}
					this.m_priceQuoteSubscriptions.dispose();

					resolve();
					this.emit('disconnected');
			  });
	}

	public getQuotePrices(feedId: string, code: string): PriceQuote | undefined {
		return this.m_currentPrices.get(`${feedId}-${code}`);
	}

	public setPriceQuote(priceQuote: PriceQuote): void {
		this.m_currentPrices.set(`${priceQuote.f}-${priceQuote.c}`, priceQuote);
	}

	private wsConnect(errorCallback?: (error: Error) => void): Promise<void> {
		return this.m_socketClient.connect(this.config.websocketUrl, errorCallback);
	}

	private nextCommandId = -1;

	private async wsSend(message: IWrapperMessage): Promise<void> {
		//const commandName = ProtobufSchema.types.WrapperMessage.Command[message.commandId];

		// count message per second
		// const now = Date.now();
		// // get seconds
		// const seconds = Math.floor(now / 1000);
		// this.m_mapRequestTime.set(seconds, (this.m_mapRequestTime.get(seconds) || 0) + 1);
		// console.log(`Requests at second: ${seconds} - Count: ${this.m_mapRequestTime.get(seconds)}`);

		return this.m_socketClient.send(message);
		/*
		const now = Date.now();
		const nextRequestTime = this.m_lastRequestTime + this.m_maxRequestFrequency;

		const shouldWait = this.nextCommandId >= 0 && this.nextCommandId !== message.commandId;
		if (shouldWait) {
			//const firstCommandName = ProtobufSchema.types.WrapperMessage.Command[this.nextCommandId];
			//console.debug(`${now} - ${nextRequestTime} Should Wait for: ${firstCommandName} - Next command: ${commandName}`)
			await this.promiseFactory.delay(this.m_maxRequestFrequency);
			return this.wsSend(message);
		}

		if (now >= nextRequestTime) {
			this.nextCommandId = -1;
			//console.debug(`${now} Send command=${commandName}`)
			this.m_lastRequestTime = now;
			return this.m_socketClient.send(message);
		} else {
			this.nextCommandId = message.commandId;
			//console.debug(`${now} - ${nextRequestTime} Re-send: ${commandName}`)
			await this.promiseFactory.delay(nextRequestTime - now);
			return this.wsSend(message);
		}
			*/
	}

	private wsSubscribe(
		command: IWrapperMessage['commandId'],
		callback: (message: IWrapperMessage) => any
	): Promise<void> {
		return this.m_socketClient.subscribe(command, callback);
	}

	private wsUnsubscribe(command: IWrapperMessage['commandId']): Promise<void> {
		return this.m_socketClient.unsubscribe(command);
	}

	private wsHasSubscription(command: IWrapperMessage['commandId']): boolean {
		return this.m_socketClient.hasSubscription(command);
	}

	private wsDisconnect(): Promise<void> {
		return this.m_socketClient.disconnect();
	}
}

//#endregion
