import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';

import { Trans, useTranslation } from 'react-i18next';

import Button from '../../../../shared/Button/Button';
import CloseButton from '../../../components/CloseButton/CloseButton';

import { RFP } from '../../../../gateways/RfpGateway/rfpConstants';
import { default as Nullable, Optional } from '../../../../utils/functions/Nullable';
import { default as RfpGatewayContext } from '../../../../contexts/RfpGatewayContext';
import NumberInput from '../TradeTicket/NumberInput';
import {
	convertAmountToLot,
	convertLotToAmount,
	fillQuantity,
	formatNumberAsMoney,
	preCalcPL,
} from '../../../../utils/functions/calculations';
import {
	CloseTradeRequest,
	PartialCloseRequest,
	PriceQuote,
	TradingAccount,
	TradingPosition,
	TradingPositionState,
} from '../../../../gateways/RfpGateway/rfp.types';

import InstrumentHeader from '../../../components/InstrumentSummary/InstrumentHeader';

import useShortTranslation from '../../../../utils/hooks/useShortTranslation';

import AppContext from '../../../../contexts/AppContext';
import DashboardContext from '../../../../contexts/DashboardContext';
import useForceRerender from '../../../../utils/hooks/useForceRerender';
import useObservable from '../../../../utils/hooks/useObservable';
import usePromiseFactory from '../../../../utils/hooks/usePromiseFactory';
import { formatNumberWithCommas } from '../Watchlist/Instrument/formattedQuoteNumber';
import DetachedModal from '../ChartPanel/NewOrderModals/DetachedModal/DetachedModal';
import { QUANTITY_TYPE, TicketLayout } from '../../../../utils/functions/enums';
import { addToastNotification } from '../../../../utils/functions/toastNotificationUtils';
import { TradersGymContext, TradersGymContextType } from '../../../../pages/TradersGym/TradersGymContext';
import { GymTradingManager } from '../../../../pages/TradersGym/Accounts/GymTradingManager';
import { GymTradingAccount } from '../../../../pages/TradersGym/Accounts/GymTradingAccount';
import { GymTradingPosition } from '../../../../pages/TradersGym/Positions/GymTradingPosition';
import useSelectedTradingAccount from '../../../../utils/hooks/useSelectedTradingAccount';
import tradingAccountStore from '../../../../store/tradingAccountStore';

import styles from './ClosePositionTicket.module.scss';
import tradingViewStore from '../../../../store/tradingViewStore';

const ClosePositionTicket = () => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const rfpGatewayContext = useContext(RfpGatewayContext);
	const gymContext = useContext(TradersGymContext) as TradersGymContextType;
	const { tradersGymContext } = gymContext;

	const realTradingAccount = useSelectedTradingAccount();
	const gymTradingAccount = tradersGymContext.gymTradingAccount;

	const [quantity, setQuantity] = useState<number | undefined>(undefined);
	const [qtyMin, setQtyMin] = useState<number | undefined>(undefined);
	const [qtyMax, setQtyMax] = useState<number | undefined>(undefined);
	const [qtyStep, setQtyStep] = useState<number | undefined>(undefined);
	const [qtyRndLot, setQtyRndLot] = useState<number | undefined>(undefined);
	const [qtyError, setQtyError] = useState<string | undefined>(undefined);
	const [selectedTradingAccount, setSelectedTradingAccount] = useState<TradingAccount>(realTradingAccount);

	const forceRerender = useForceRerender();
	const promiseFactory = usePromiseFactory();

	const languageSettings = appContext.languageSettings;

	const selectedPosition = dashboardContext.selectedPosition;
	const selectedMarketItem = dashboardContext.selectedInstrument;
	const showCloseTicket = dashboardContext.showCloseTicket;
	const quantityType = dashboardContext.quantityType;
	const inputValidation = dashboardContext.inputValidation;
	const headerSelected = dashboardContext.headerSelected;
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();

	const allTradingPositions = tradersGymContext.isActive
		? tradersGymContext.gymTradingAccount?.activePositions ?? []
		: dashboardContext.getTradingPositions();

	useEffect(() => {
		if (tradersGymContext.isActive && gymTradingAccount) {
			setSelectedTradingAccount(gymTradingAccount);
		} else {
			setSelectedTradingAccount(realTradingAccount);
		}
	}, [realTradingAccount, gymTradingAccount]);

	const subIdRef = useRef<string | undefined>(undefined);

	const { t } = useTranslation();
	const tt = useShortTranslation('en:');

	const [price, setPrice] = useState<number | string>(0);
	const [currentPriceQuote, setCurrentPriceQuote] = useState<Optional<PriceQuote>>();
	const [quantityValues, setQuantityValues] = useState<number[]>([]);
	const [pL, setPL] = useState<number | string>(0);
	const [avgPrice, setAvgPrice] = useState('');
	const [posSide, setPosSide] = useState('');
	const [summaryPrice, setSummaryPrice] = useState('');
	const [summaryPL, setSummaryPL] = useState<number | string | undefined>(undefined);
	const [summaryAmount, setSummaryAmount] = useState('0');
	const [preFormattedPL, setPreFormattedPL] = useState(0);
	const [isDisableButton, setIsDisableButton] = useState<boolean>(false);
	const ticketLayout = tradingViewStore.use.ticketLayout();
	const setTicketLayout = tradingViewStore.use.setTicketLayout();

	useObservable(appContext.getPropertyChangeStream('appTheme'), () => forceRerender());

	useObservable(
		dashboardContext.getPropertyChangeStream(
			'selectedPosition',
			'selectedInstrument',
			'showCloseTicket',
			'quantityType',
			'detailedInformation',
			'marketItems',
			'tradingAccount',
			'inputValidation',
			'headerSelected',
			'tradingPositions'
		),
		() => {
			promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
				forceRerender();
			});
		}
	);

	const formatNumber = (value: number) =>
		selectedTradingAccount
			? formatNumberAsMoney(
					selectedTradingAccount.baseCurrency,
					value,
					languageSettings,
					selectedTradingAccount.isJapanAccount
			  )
			: '';

	const clickedInstrument = () => {
		if (selectedPosition && selectedPosition[0] && selectedPosition[0].marketItem) {
			return selectedPosition[0].marketItem;
		}
		return selectedMarketItem;
	};

	const hasAmountError = () => {
		return qtyError !== undefined;
	};

	const isIndividualPosition = selectedPosition && selectedPosition[0] && selectedPosition.length === 1;
	const allPositionsLength = allTradingPositions.filter(
		(pos: TradingPosition) => pos.state === TradingPositionState.open && pos.aId === selectedTradingAccount?.id
	)?.length;
	const isAllPositions =
		selectedPosition && selectedPosition[0]
			? selectedPosition.length === allPositionsLength && allPositionsLength > 1 && headerSelected
			: false;

	const isSummaryPosition = !isIndividualPosition && !isAllPositions;

	if (isIndividualPosition) {
		dashboardContext.selectedInstrument = rfpGatewayContext?.getMarketItem(selectedPosition[0].code);
	}

	const undockTicketLayout = () => {
		setTicketLayout(TicketLayout.Undock);
		dashboardContext.showCloseTicket = false;
		dashboardContext.modalToggle = { closePosition: true, closeOrder: false };
	};

	const dockTicketLayout = () => {
		setTicketLayout(TicketLayout.Dock);
		dashboardContext.showCloseTicket = true;
		dashboardContext.modalToggle = { closePosition: false, closeOrder: false };
	};

	const accountBaseCurrency = useMemo(() => selectedTradingAccount?.baseCurrency ?? 'USD', [selectedTradingAccount]);

	const calculateSide = (buyAmount: number, sellAmount: number) => {
		if (buyAmount > sellAmount) {
			setPosSide('BUY');
		} else if (buyAmount < sellAmount) {
			setPosSide('SELL');
		} else {
			setPosSide('SQUARE');
		}
	};

	const setInitialQuantityGroup = () => {
		if (selectedMarketItem && selectedTradingAccount) {
			setQuantityValues(fillQuantity(selectedMarketItem, selectedTradingAccount));
		}
	};

	const calcPotentialProfitLoss = (pos: any, priceValue: number) => {
		return preCalcPL(
			pos.marketItem,
			pos.qty,
			pos.oP!,
			priceValue,
			accountBaseCurrency,
			pos.side,
			selectedTradingAccount,
			rfpGatewayContext
		);
	};

	//Initialization
	useEffect(() => {
		if (selectedPosition && selectedPosition[0]) {
			setQuantity(selectedPosition[0].qty);
		}
	}, [quantityType, selectedPosition]);

	useEffect(() => {
		if (selectedPosition) {
			const clickedInst = clickedInstrument();
			if (selectedPosition.length > 0 && clickedInst && selectedMarketItem) {
				const selectedInstrumentDetails = selectedTradingAccount?.tradingInstruments?.instruments[clickedInst.code];
				const instrumentRndLot = (selectedInstrumentDetails && selectedInstrumentDetails.rndLot) || 0;
				const qty = selectedPosition[0].qty;
				setQtyMin(selectedInstrumentDetails ? convertLotToAmount(selectedInstrumentDetails.minL, instrumentRndLot) : 0);
				setQtyStep(
					selectedInstrumentDetails ? convertLotToAmount(selectedInstrumentDetails.stpL, instrumentRndLot) : 0
				);
				setQtyRndLot(instrumentRndLot);
				setQtyMax(qty);
				setQuantity(qty);
			}
		}
		dashboardContext.inputValidation.closeQuantity = false;
		let sellAmount = 0;
		let buyAmount = 0;
		let quantity = 0;
		let summaryPrice = 0;
		let decimalPec = 0;
		if (isSummaryPosition && selectedPosition && selectedPosition.length > 1) {
			selectedPosition.forEach((pos: TradingPosition) => {
				let openPrice = 0;
				if (pos && pos.qty && pos.oP) {
					if (pos.side === 'BUY') {
						buyAmount = buyAmount + pos.qty;
					} else {
						sellAmount = sellAmount + pos.qty;
					}
					openPrice = pos.oP * pos.qty;
					summaryPrice = summaryPrice + openPrice;
					quantity = quantity + pos.qty;
					decimalPec = pos.dec!;
				}
			});
		}

		const calAmount = buyAmount > 0 ? buyAmount - sellAmount : sellAmount;
		setAvgPrice(formatNumberWithCommas(summaryPrice / quantity, decimalPec, languageSettings));
		calculateSide(buyAmount, sellAmount);
		const clickedInst = clickedInstrument();

		if (clickedInst) {
			const summaryAmountLot = calAmount < 0 ? calAmount * -1 : calAmount;
			const selectedInstrumentDetails = selectedTradingAccount?.tradingInstruments?.instruments[clickedInst.code];
			const instrumentRndLot = (selectedInstrumentDetails && selectedInstrumentDetails.rndLot) || 0;
			setSummaryAmount(
				quantityType === QUANTITY_TYPE.LOTS
					? formatNumberWithCommas(
							convertAmountToLot(summaryAmountLot, instrumentRndLot),
							selectedInstrumentDetails?.lotDPrec ?? 2,
							languageSettings
					  )
					: formatNumberWithCommas(summaryAmountLot, selectedInstrumentDetails?.amtDPrec ?? 0, languageSettings)
			);
		}
	}, [selectedPosition, quantityType]);

	useEffect(() => {
		if (rfpGatewayContext && selectedMarketItem) {
			setInitialQuantityGroup();

			// unsubscribe previous quotes
			if (subIdRef.current) {
				rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
			}

			// subscribe for price quote
			subIdRef.current = rfpGatewayContext.subscribePriceQuote(
				selectedMarketItem.feedId,
				[selectedMarketItem.code],
				(priceQuote) => {
					if (!tradersGymContext.isActive) {
						setCurrentPriceQuote(priceQuote);
					}
				}
			);
		}

		// unsubscribe price quote on unmount
		return () => {
			if (rfpGatewayContext && subIdRef.current) {
				rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
				subIdRef.current = undefined;
			}
		};
	}, [selectedPosition, selectedMarketItem, rfpGatewayContext]);

	useEffect(() => {
		if (currentPriceQuote && selectedMarketItem && selectedPosition && selectedPosition[0]) {
			const pos = selectedPosition[0];
			const priceValue = pos.side === 'SELL' ? currentPriceQuote.a : currentPriceQuote.b;
			if (priceValue) {
				setPrice(formatNumberWithCommas(priceValue, selectedMarketItem.decPrec, languageSettings));
				if (tradersGymContext.isActive) {
					setPL(parseFloat(pos.grossProfit ?? 0).toFixed(selectedMarketItem.decPrec));
				} else {
					const pLValue: any = calcPotentialProfitLoss(pos, priceValue);
					setPL(parseFloat(pLValue).toFixed(selectedMarketItem.decPrec));
				}
				if (isSummaryPosition) {
					let sellPL = 0;
					let buyPL = 0;
					const summaryPriceValue = posSide === 'SELL' ? currentPriceQuote.a : currentPriceQuote.b;
					setSummaryPrice(formatNumberWithCommas(summaryPriceValue, selectedMarketItem.decPrec, languageSettings));
					selectedPosition.forEach((pos: TradingPosition) => {
						if (tradersGymContext.isActive) {
							if (pos.side === 'SELL') {
								sellPL += pos.grossProfit ?? 0;
							} else {
								buyPL += pos.grossProfit ?? 0;
							}
						} else {
							if (pos.side === 'SELL') {
								const pl = +calcPotentialProfitLoss(pos, currentPriceQuote.a);
								sellPL = sellPL + pl;
							} else {
								const pl = +calcPotentialProfitLoss(pos, currentPriceQuote.b);
								buyPL = buyPL + pl;
							}
						}
					});
					let val = sellPL + buyPL;
					setPreFormattedPL(val);
					setSummaryPL(val.toFixed(2));
				}
			}
		}
	}, [currentPriceQuote]);

	useEffect(() => {
		if (tradersGymContext.priceQuote) {
			setCurrentPriceQuote(tradersGymContext.priceQuote);
		}
	}, [tradersGymContext.priceQuote]);

	const hideClosePosition = () => {
		if (ticketLayout === TicketLayout.Dock) {
			dashboardContext.showCloseTicket = false;
		} else {
			dashboardContext.modalToggle = { closePosition: false, closeOrder: false };
		}
	};

	const showInvalidNotification = () => {
		addToastNotification('danger', t('wtr:INVALID_DATA'), t('wtr:CLOSE_FAIL'));
	};

	const handleClosePosition = () => {
		const selectedAccount = selectedTradingAccount;
		if (selectedAccount && selectedPosition) {
			dashboardContext.orderConfirm.edit = false;
			const clickedInst = clickedInstrument();
			if (clickedInst && quantity) {
				if (selectedPosition.length === 1 && quantity === 0) {
					showInvalidNotification();
				} else {
					if (selectedPosition.length === 1) {
						const pos = selectedPosition[0];
						const positionAmount: number = pos.qty;
						if (quantity < positionAmount) {
							if (tradersGymContext.isActive) {
								if (tradersGymContext.lastHistoryTick) {
									const priceQuote = gymContext.convertHistoryTickToPriceQuote(tradersGymContext.lastHistoryTick);
									GymTradingManager.sharedInstance().partialClosePosition(
										selectedAccount as GymTradingAccount,
										pos,
										quantity,
										tradersGymContext.lastHistoryTick,
										priceQuote
									);
									dashboardContext.orderConfirm.close = true;

									if (ticketLayout === TicketLayout.Undock) {
										dashboardContext.modalToggle = { closePosition: false, closeOrder: false };
									} else {
										dashboardContext.showCloseTicket = false;
									}
									return;
								}
							} else {
								const partialParams: PartialCloseRequest = {
									trAccountId: selectedAccount.id,
									posId: selectedPosition[0].posId,
									amount: quantity,
								};

								Nullable.of(rfpGatewayContext).run((rfpGateway) => {
									rfpGateway.send(RFP.partialClose, partialParams);
								});
							}
							dashboardContext.orderConfirm.close = true;
							setIsDisableButton(true);
						} else if (quantity === positionAmount) {
							if (tradersGymContext.isActive) {
								GymTradingManager.sharedInstance().closePosition(selectedAccount as GymTradingAccount, pos);
							} else {
								closePosition(selectedAccount, pos);
							}
							dashboardContext.orderConfirm.close = true;
						} else if (quantity > positionAmount) {
							showInvalidNotification();
						}
					} else if (selectedPosition.length > 1) {
						if (tradersGymContext.isActive) {
							selectedPosition.forEach((element: TradingPosition) => {
								closeGymPosition(selectedAccount as GymTradingAccount, element as GymTradingPosition);
							});
							dashboardContext.orderConfirm.close = true;
						} else {
							// 100 millis delay for each interaction
							selectedPosition.forEach((element: TradingPosition, index: number) => {
								setTimeout(() => {
									closePosition(selectedAccount, element);
								}, index * 100);
							});
						}
					}
				}
			}
		}

		if (ticketLayout === TicketLayout.Undock) {
			dashboardContext.modalToggle = { closePosition: false, closeOrder: false };
		} else {
			dashboardContext.showCloseTicket = false;
		}
	};

	const closeGymPosition = (selectedAccount: GymTradingAccount, position: GymTradingPosition) => {
		if (tradersGymContext.isActive) {
			GymTradingManager.sharedInstance().closePosition(selectedAccount, position);
		}
	};

	const closePosition = (selectedAccount: TradingAccount, position: TradingPosition) => {
		if (rfpGatewayContext && position.posId) {
			if (position.cancelRequestSent === undefined) {
				const summaryParams: CloseTradeRequest = {
					posId: position.posId,
					trAccountId: selectedAccount.id,
				};
				rfpGatewayContext.send(RFP.closeTrade, summaryParams);
				//position.cancelRequestSent = true;
			}
			dashboardContext.orderConfirm.close = true;
		} else {
			console.log('position id is missing');
		}
	};

	const closeTicketButton = () => {
		if (qtyMax && Array.isArray(selectedPosition)) {
			if (quantity && quantity < qtyMax) {
				return tt('TRADES_PARTIAL_CLOSE');
			} else if (selectedPosition.length > 1) {
				return t('wtr:CLOSE_POSITIONS');
			} else if (selectedPosition.length === 1) {
				return t('en:TRADES_CLOSE_POSITION');
			}
		}
		return '';
	};

	const validateQuantity = (amount: number) => {
		if (amount && qtyStep && qtyMin && qtyMax) {
			const isAmountValidByStep = Math.round(amount * 1000000) % Math.round(qtyStep * 1000000) === 0;
			return amount >= qtyMin && amount <= qtyMax && isAmountValidByStep;
		}

		return false;
	};

	const handleQuantityChange = (value: number) => {
		const error = validateQuantity(value) ? undefined : t('en:INVALID_VALUE');

		setQuantity(value);
		setQtyError(error);
	};

	const closeTicket = (
		<>
			<div className={ticketLayout === TicketLayout.Dock ? styles.ticketInfo : styles.ticketInfoModal}>
				<div
					className={ticketLayout === TicketLayout.Dock ? styles.orderQuantityLabels : styles.modalOrderQuantityLabels}
				>
					{/* setError(quantityType === QUANTITY_TYPE.AMOUNT ? t('wtr:CANT_INCREASE') : t('wtr:CANT_INCREASE_LOTS')); */}
					<div className={styles.text}>
						{tt(isSpreadBettingAccount ? 'POUND_PER_POINT' : `QT_${quantityType.toUpperCase()}`)}
					</div>
					<div className={styles.input}>
						<NumberInput
							lotAware
							incrementor={{
								quantityValues: quantityValues,
								min: qtyMin,
								max: qtyMax,
								step: qtyStep,
							}}
							roundLot={qtyRndLot}
							marketItem={selectedMarketItem ?? undefined}
							value={quantity}
							onChange={handleQuantityChange}
							amountErrorClass={!!qtyError}
						/>
						<span className={styles.errorValidation}>{qtyError}</span>
					</div>
				</div>
				<div className={ticketLayout === TicketLayout.Dock ? styles.closeContainer : styles.closeContainerModal}>
					<div className={styles.closeFields}>
						<div className={styles.text}>{tt('OPEN_AT')}</div>
						<div className={styles.text}>
							{selectedPosition &&
								selectedPosition[0] &&
								formatNumberWithCommas(selectedPosition[0].oP, selectedPosition[0].dec, languageSettings)}
						</div>
					</div>
					<div className={styles.closeFields}>
						<div className={styles.text}>{tt('CURRENT_PRICE')}</div>
						<div className={styles.text}>{price}</div>
					</div>
					<div className={styles.closeFields}>
						<div className={styles.text}>{t('wtr:ESTIMATED_PL')}</div>
						<div className={+pL > 0 ? styles.positive : styles.negative}>{formatNumber(+pL)}</div>
					</div>
				</div>
			</div>
			{ticketLayout === TicketLayout.Dock && (
				<div className={styles.orderModalFooter}>
					<div
						className={ticketLayout === TicketLayout.Dock ? styles.modalPartialButtons : styles.undockedPartialButtons}
					>
						<Button
							variant="secondary"
							size="fluid"
							label={tt('CANCEL')}
							className={styles.ticketButton}
							onClick={hideClosePosition}
						/>

						<Button
							variant="primary"
							size="fluid"
							label={closeTicketButton()}
							className={cn(styles.ticketButtonClose, hasAmountError() ? styles.disableButton : ' ')}
							onClick={handleClosePosition}
							disabled={hasAmountError() || isDisableButton}
						/>
					</div>
				</div>
			)}
		</>
	);

	const summaryTicket = (
		<>
			<div className={ticketLayout === TicketLayout.Dock ? styles.closeContainerSummary : styles.modalContainerSummary}>
				{!isAllPositions && (
					<>
						<div className={styles.closeFields}>
							<div>{tt('SIDE')}</div>
							<div>{posSide === 'BUY' ? t('en:BUY') : posSide === 'SQUARE' ? t('wtr:SQUARE') : t('en:SELL')}</div>
						</div>
						<div className={styles.closeFields}>
							<div>{tt(isSpreadBettingAccount ? 'POUND_PER_POINT' : `QT_${quantityType.toUpperCase()}`)}</div>
							{/* Think about to use change decimal precision in localizeNumber from 0 to 0.00 instead using === '0' */}
							<div>{summaryAmount === '0' ? '0.00' : summaryAmount}</div>
						</div>
						<div className={styles.closeFields}>
							<div>{t('wtr:AVG_PRICE')}</div>
							<div>{avgPrice}</div>
						</div>
						<div className={styles.closeFields}>
							<div>{tt('CURRENT_PRICE')}</div>
							<div>{summaryPrice}</div>
						</div>
						<div className={styles.closeFields}>
							<div>{t('wtr:ESTIMATED_PL')}</div>
							{summaryPL && (
								<div className={preFormattedPL > 0 ? styles.positive : styles.negative}>{formatNumber(+summaryPL)}</div>
							)}
						</div>
					</>
				)}
			</div>
			{ticketLayout === TicketLayout.Dock && (
				<div className={styles.orderModalFooter}>
					<div className={styles.modalPartialButtons}>
						<Button variant="secondary" label={tt('CANCEL')} size="fluid" onClick={hideClosePosition} />
						<Button
							variant="primary"
							className={inputValidation.highAmount ? styles.disableButton : ''}
							label={t('wtr:CLOSE_POSITIONS')}
							size="fluid"
							onClick={handleClosePosition || isDisableButton}
						/>
					</div>
				</div>
			)}
		</>
	);

	let header;
	let withPadding = true;
	let dockAction = false;
	if (isAllPositions) {
		header = <Trans i18nKey="wtr:CLOSE_ALL_POSITIONS" values={{ length: allPositionsLength }} />;
		dockAction = true;
	} else if (isSummaryPosition) {
		header = (
			<Trans
				i18nKey="wtr:CLOSE_ALL_POSITIONS_FOR_INSTRUMENT"
				values={{ numPositions: selectedPosition?.length, code: selectedPosition ? selectedPosition[0]?.code : '' }}
			/>
		);
		dockAction = true;
	} else {
		header = (
			<InstrumentHeader
				showButtons={false}
				resizeHeader={true}
				headerActions={false}
				undock={ticketLayout === TicketLayout.Undock ? dockTicketLayout : undockTicketLayout}
				onCloseButtonCLick={hideClosePosition}
			/>
		);
		withPadding = false;
	}

	return (
		<>
			{ticketLayout === TicketLayout.Undock && (
				<DetachedModal
					title={header}
					body={isAllPositions ? undefined : isSummaryPosition ? summaryTicket : closeTicket}
					hideAction={hideClosePosition}
					successTitle={closeTicketButton()}
					successAction={!isDisableButton && handleClosePosition}
					show={true}
					buttonDisabledClass={qtyError ? styles.disableButton : ''}
					withPadding={withPadding}
					dockAction={dockAction && dockTicketLayout}
				/>
			)}

			{ticketLayout === TicketLayout.Dock && showCloseTicket && (
				<>
					{isIndividualPosition && (
						<div className={styles.modalInfoPosition}>
							<InstrumentHeader resizeHeader={true} undock={undockTicketLayout} />
						</div>
					)}
					<div className={styles.closeButtonLocation}>
						<CloseButton onClick={() => hideClosePosition()} />
					</div>
					{isSummaryPosition && !isAllPositions && (
						<div className={styles.header}>
							<Trans
								i18nKey="wtr:CLOSE_ALL_POSITIONS_FOR_INSTRUMENT"
								values={{ numPositions: selectedPosition?.length, code: selectedPosition[0]?.code }}
							/>
						</div>
					)}
					{isAllPositions && (
						<div className={styles.header}>
							<Trans i18nKey="wtr:CLOSE_ALL_POSITIONS" values={{ length: allPositionsLength }} />
						</div>
					)}
					{isSummaryPosition && summaryTicket}
					{isAllPositions && summaryTicket}
					{isIndividualPosition && closeTicket}
				</>
			)}
		</>
	);
};

export default ClosePositionTicket;
